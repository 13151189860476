// Components
import { authorizationEvent } from "./segments/authorization";
import { registrationEvent } from "./segments/registration";
import { searchEvent } from "./segments/search";
import { selectorCarEvent } from "./segments/selector-car";
import { catalogEvent } from "./segments/catalog";
import { showPriceEvent } from "./segments/show-price";
import { addToCartEvent } from "./segments/add-to-cart";
import { beginCheckoutEvent } from "./segments/begin-checkout";
import { purchaseEvent } from "./segments/purchase";
import { catalogAddTabEvent } from "./segments/catalog-add-tab";
import { catalogCloseTabEvent } from "./segments/catalog-close-tab";
import { catalogTabClickEvent } from "./segments/catalog-tab-click";
import { consentOptionEvent } from "./segments/consent-option";
import { consentDefEvent } from "./segments/consent-def";

// Definitions
import { GTMTypesEnum, GTMConfigType } from "./gtm.types";

const eventsMap: GTMConfigType["eventsMap"] = new Map();
eventsMap.set(GTMTypesEnum.authorization, authorizationEvent);
eventsMap.set(GTMTypesEnum.registration, registrationEvent);
eventsMap.set(GTMTypesEnum.search, searchEvent);
eventsMap.set(GTMTypesEnum.selector_car, selectorCarEvent);
eventsMap.set(GTMTypesEnum.catalog, catalogEvent);
eventsMap.set(GTMTypesEnum.show_price, showPriceEvent);
eventsMap.set(GTMTypesEnum.add_to_cart, addToCartEvent);
eventsMap.set(GTMTypesEnum.begin_checkout, beginCheckoutEvent);
eventsMap.set(GTMTypesEnum.purchase, purchaseEvent);
eventsMap.set(GTMTypesEnum.add_tab, catalogAddTabEvent);
eventsMap.set(GTMTypesEnum.close_tab, catalogCloseTabEvent);
eventsMap.set(GTMTypesEnum.tab_click, catalogTabClickEvent);
eventsMap.set(GTMTypesEnum.consent_mode_option, consentOptionEvent);
eventsMap.set(GTMTypesEnum.consent_mode_def, consentDefEvent);

export { eventsMap };
