// Core
import { BaseSyntheticEvent, FC, SyntheticEvent, ReactNode, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "next-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRouter } from "next/router";

// Components
import { Form } from "antd";
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Button } from "components/ui/Button";
import { FormBlock } from "components/ui/FormFields/FormBlock";
import { FormActions } from "components/ui/FormFields/FormActions";
import { InputForm } from "components/ui/FormFields/InputForm";
import { InputBase } from "components/ui/FormFields/InputBase";
import { Icon } from "components/ui/Icon";
import { WrappedFormAutocomplete } from "components/common/WrappedFormAutocomplete";

// Definitions
import { SearchTypesEnum } from "bus/search/models";
import type { FormSubmitFn } from "models/Forms";
import type { SearchBarType } from "bus/search/models";

// Utils
import { book } from "init/book";
import { inputConfig, SearchBarFormEnum, searchBarForm } from "./config";

export type SearchBarOptionType = {
  id: number;
  label?: ReactNode;
  value: string;
  keyword?: string;
  entity?: string;
  type?: SearchTypesEnum;
};

type SearchBarFormType = {
  loadingSearch?: boolean;
  initialValues?: SearchBarType | null;
  onSubmit?: FormSubmitFn<SearchBarType>;
  loadingAutocomplete?: boolean;
  options?: SearchBarOptionType[];
  onSearch?: (keyword: string) => void;
  onShowModal?: () => void;
};

export const SearchBarForm: FC<SearchBarFormType> = (props) => {
  const {
    loadingSearch = false,
    initialValues = null,
    loadingAutocomplete = false,
    options = [],
    onSubmit,
    onSearch,
  } = props;
  const { t } = useTranslation();
  const router = useRouter();

  const formProps = useForm<SearchBarType>({
    defaultValues: { ...searchBarForm.shape, ...initialValues },
    resolver: zodResolver(searchBarForm.schema()),
    mode: "all",
    reValidateMode: "onSubmit",
  });

  const { control, handleSubmit, setError, reset, setValue, watch } = formProps;

  const searchValue = watch(SearchBarFormEnum.search);

  useEffect(() => {
    return () => {
      if (router.route === book.catalog) {
        reset();
      }
    };
  }, [router.route]);

  useEffect(() => {
    setValue("type", null);
    setValue("entity", "");
    setValue("keyword", "");
  }, [searchValue]);

  const handleSubmitForm = handleSubmit((values: SearchBarType) => {
    if (!values[SearchBarFormEnum.search] && !values[SearchBarFormEnum.entity]) return;
    onSubmit?.({ values, acts: { setError, reset } });
  });

  const onSubmitForm = (event?: SyntheticEvent) => {
    void (async () => {
      await handleSubmitForm(event);
    })();
  };

  const handleSelect = (_: void, option: SearchBarOptionType) => {
    option.type && setValue("type", option.type);
    option.entity && setValue("entity", option.entity);
    option.keyword && setValue("keyword", option.keyword);
    onSubmitForm();
  };
  const handleFocus = () => {
    searchValue && !options.length && onSearch?.(searchValue);
  };

  const handleSearch = (keyword: string) => {
    if (!inputConfig[SearchBarFormEnum.search].pattern.test(keyword)) {
      return;
    }
    onSearch?.(keyword);
  };

  const handlePressEnter = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    !!e.target.value && onSubmitForm();
  };

  return (
    <Form layout="vertical" onFinish={onSubmitForm}>
      <Row gutter={Row.gutterType.xsm.horizontal}>
        <Col span={19}>
          <FormBlock>
            <InputForm
              type="search-bar"
              id={SearchBarFormEnum.search}
              name={SearchBarFormEnum.search}
            >
              <WrappedFormAutocomplete
                loading={loadingAutocomplete}
                name={SearchBarFormEnum.search}
                control={control}
                options={options}
                onSearch={handleSearch}
                onSelect={handleSelect}
                onFocus={handleFocus}
              >
                <InputBase
                  placeholder={t(inputConfig[SearchBarFormEnum.search].placeholder)}
                  onPressEnter={handlePressEnter}
                />
              </WrappedFormAutocomplete>
            </InputForm>
          </FormBlock>
        </Col>
        <Col span={5}>
          <FormActions center>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              block
              loading={loadingSearch}
              btnType="search-bar"
              icon={<Icon name={"SearchOutlined"} />}
            >
              {t("buttons:search")}
            </Button>
          </FormActions>
        </Col>
      </Row>
    </Form>
  );
};
