// Core
import "reflect-metadata";
import { appWithTranslation } from "next-i18next";
import { QueryClientProvider } from "react-query";
import { ApolloProvider } from "@apollo/client";
import { ErrorBoundary } from "react-error-boundary";

// Definitions
import type { AppProps as NextAppProps } from "next/app";
import type { SSRConfig } from "next-i18next";
import type { NextPageCustom, NextPageSSRCustomProps } from "models/Next";
import type { AuthSessionType } from "bus/auth/models";
import type { UserGeoType } from "bus/user/models";
import type { ProfileType } from "bus/profile/models";

// Components
import { ConfigProvider as AntdConfigProvider } from "antd";
import { AuthSessionProvider } from "bus/auth/context";

// Hooks
import { ModalContextProvider } from "hooks/useModalContext";
import { ReturnNewStoreProvider } from "client/core/return-new/components/return-new-store-provider/return-new-store-provider";
import { GeoContextProvider } from "hooks/useGeoContext";
import { ProfileContextProvider } from "hooks/useProfileContext";
import { useKmtxVariables } from "hooks/useKmtxVariables";

// Contexts
import { PermissionContext } from "contexts/usePermissionContext";

// Utils
import "theme/init.less";
import "theme/tmp-disable.css";
import "client/utils/gtm";

import { queryClient } from "init/queryClient";
import { antdLocales } from "utils/locales";
import NextI18nConfig from "nextI18nConfig";
import { Permission } from "utils/permission";
import { useApollo } from "init/apollo-store";
import { AppError } from "components/common/Errors";

type AppStoreData = SSRConfig & {
  session?: AuthSessionType | null;
  geo?: UserGeoType;
  profile?: ProfileType;
  initialApolloState?: Record<string, unknown> | null;
};
type NextPageSSRProps = AppStoreData & NextPageSSRCustomProps;
type CustomAppProps = NextAppProps<NextPageSSRProps> & {
  Component: NextPageCustom;
};

const MyApp = (props: CustomAppProps) => {
  const { Component, pageProps } = props;
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _nextI18Next,
    session,
    initialApolloState,
    geo,
    profile = null,
    ...otherPageProps
  } = pageProps;
  const apolloClient = useApollo(initialApolloState);
  const permissionFeat = profile?.permission.feature;
  const ability = new Permission().createAbility(permissionFeat);

  const activeLang = String(_nextI18Next?.initialLocale);
  const getLayout = Component.getLayout || ((page) => page);

  useKmtxVariables({ geo, profile });

  return (
    <ApolloProvider client={apolloClient}>
      <QueryClientProvider client={queryClient}>
        <AntdConfigProvider locale={antdLocales(activeLang)}>
          <ErrorBoundary fallback={<AppError />}>
            <GeoContextProvider geo={geo}>
              <ProfileContextProvider profile={profile}>
                <ModalContextProvider>
                  <ReturnNewStoreProvider>
                    <PermissionContext.Provider value={ability}>
                      <>{getLayout(<Component {...otherPageProps} />, !!session)}</>
                    </PermissionContext.Provider>
                  </ReturnNewStoreProvider>
                </ModalContextProvider>
              </ProfileContextProvider>
            </GeoContextProvider>
          </ErrorBoundary>
        </AntdConfigProvider>
      </QueryClientProvider>
    </ApolloProvider>
  );
};

const AppWithI18n = appWithTranslation(MyApp, NextI18nConfig);

type AppAuthProps = NextAppProps<NextPageSSRProps>;
const AppWithAuth = (props: AppAuthProps) => {
  return (
    <AuthSessionProvider session={props.pageProps.session}>
      <AppWithI18n {...props} />
    </AuthSessionProvider>
  );
};
export default AppWithAuth;
