// Components
import { ROW_GUTTER_TYPE } from "../components/ui/Row/utils";

// Definitions
import { NotificationMessage } from "../components/ui/Notification/Notification.types";
import type { OrdersCheckoutConfigType } from "../bus/orders/models";
import { UserCountryCodesEnum, UserCountryCodesKeys } from "../bus/user/models";

// Utils
import { verifyBuild } from "./verify-build";

export const rowGutters = ROW_GUTTER_TYPE.md.horizontal;
export const rowGuttersGallery = ROW_GUTTER_TYPE.md.all;
export const SURVEY_LAST_SHOW_DATE_KEY = "survey_last_show_date";
export const SURVEY_AMOUNT_SHOW_DAYS_KEY = "survey_amount_show_days";
export const HEADER_COUNTRY = "x-country-code";
export const KTMX_SYNC_KEY = "kmtx_sync";
export const HEADER_KMTX_CS = "kmtx-cs";
export const LOCALE_COOKIE_NAME = "NEXT_LOCALE";

export const AUTH_COOKIE = "atd-pro-portal-session";
export const AUTH_COOKIE_ERROR = "atd-pro-portal-session-err";
export const rowGuttersCustomerVehicles = ROW_GUTTER_TYPE.sm.vertical;

export const EMPTY_STR = " ";
export const COOKIE_MAX_AGE = 30 * 24 * 60 * 60; // 30 days
export const PROJECT_LABEL_HREF = "www.autodoc.pro";

export const defaultDate = "1970-01-01";
export const dateFormat = "DD/MM/YYYY";
export const dateFormatFilterStatic = "DD-MM-YYYY";
export const dateFormatTable = "DD/MM/YY";
export const dateFormatDelivery = "DD MMMM YYYY";
export const dateFormatTotalDeliveryDate = "MMMM D";
export const dateFormatRepairEstimate = "MMMM D";
export const dateFormatNotification = "MMM D, YYYY";
export const dateFormatNotifications = "DD.MM.YYYY";
export const getManagerContactPhoneNumber = (
  countyCode: UserCountryCodesEnum = UserCountryCodesEnum.en,
) => {
  const phoneNumbers: Record<UserCountryCodesKeys, string> = {
    en: "+330187210210",
    fr: "+330187210210",
    de: "+493022027777",
    at: "+4314240054",
    nl: "+31203231000",
  };
  return phoneNumbers[countyCode] || phoneNumbers.en;
};
export const workingHoursMinuteStep = 30;
export const workingHoursDefaultTime = {
  start: "08:00",
  end: "17:00",
  lunchBreakStart: "13:00",
  lunchBreakEnd: "14:00",
};
export const workingHoursTimeFormat = "HH:mm";
export const workingHoursDateFormat = "YYYY-MM-DD HH:mm";
export const pickerTimeFormat = "HH:mm";
export const pickerTimeMinuteStep = 5;
export const priceRangeStep = 0.01;
export const DRAWER_WIDTH = 992;
export const NOTIFICATION_DURATION = 2;
export const NOTIFICATION_MAX_COUNT = 5;
export const imagePlaceholderDefault =
  // eslint-disable-next-line max-len
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAwIiBoZWlnaHQ9IjMwMCIgdmlld0JveD0iMCAwIDMwMCAzMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3Qgd2lkdGg9IjMwMCIgaGVpZ2h0PSIzMDAiIGZpbGw9IiNFM0UzRTMiLz48L3N2Zz4=";

export const carPlaceholderDefault = "/assets/images/car-placeholder.png";

export const validHashLength = 32;

export const GET_PARAMS = {
  id: "id",
  popup: "popup",
  sortId: "sort[id]",
};

export const GET_ENUMS = {
  popup: {
    signinError: "signin-error",
    signup: "signup",
    signupSuccess: "signup-success",
    signupNoVat: "signup-no-vat",
    resetPasswordEmail: "reset-password-email",
    vehicleAttrsTabsClose: "vehicle-attrs-tabs-close",
    vehicleRegNumTabsClose: "vehicle-reg-num-tabs-close",
    vehicleKbaTabsClose: "vehicle-kba-tabs-close",
    notice: "notice",
    customerCreate: "customer-create",
    customerDeleteConfirm: "customer-delete-confirm",
    selectorKbaKeyPreview: "selector-kba-key-preview",
    repairEstimateDeleteConfirm: "repair-estimate-delete-confirm",
    repairEstimateCheckoutConfirm: "repair-estimate-checkout-confirm",
    repairEstimateClientCreateVehicle: "repair-estimate-client-create-vehicle",
    repairEstimateClientChangeVehicle: "repair-estimate-client-change-vehicle",
    repairEstimateStatusChangeConfirm: "repair-estimate-status-change-confirm",
    cartRepairEstimateCreate: "cart-repair-estimate-create",
    savedBasketRepairEstimateCreate: "saved-basket-repair-estimate-create",
    savedBasketDelete: "saved-basket-delete",
    savedBasketCreate: "saved-basket-create",
    savedBasketClientExist: "saved-basket-client-exist",
    savedBasketCheckoutConfirm: "saved-basket-checkout-confirm",
    mandateReset: "mandate-reset",
    returnConfirmCreate: "return-confirm-create",
    selectVehicle: "select-vehicle",
    callBack: "call-back",
    userConsent: "user-consent",
    callBackPersonalManager: "call-back-personal-manager",
    callBackLimit: "call-back-limit",
    personalCallBackLimit: "personal-call-back-limit",
    leadRegistered: "lead-already-registered",
    customerBlocked: "customer-blocked",
    deleteCarFromCart: "cart-car-delete",
    passwordCreate: "password-create",
    trackParcel: "track-parcel",
    changePasswordSuccess: "change-password-success",
    customerVehicleModal: "customer-vehicle-modal",
    vinIdentification: "vin-identification",
    catalogGraphicCategoriesLoadingFailed: "catalog-graphic-categoris-loading-failed",
    ordersCheckoutPaymentCanceled: "orders-checkout-payment-canceled",
    ordersCheckoutPaymentRejected: "orders-checkout-payment-rejected",
    static: {
      surveyNps: "survey-nps",
      customerVehicleCreate: "customer-vehicle-create",
      customerVehicleUpdate: "customer-vehicle-update",
      customerVehicleRemove: "customer-vehicle-remove",
      userSignOut: "user-signout",
      serviceTimeout: "service-timeout",
      repairEstimateStatusChangeConfirm: "repair-estimate-status-change-confirm",
      ordersCheckoutServiceTimeout: "orders-checkout-service-timeout",
      ordersCheckoutCompanyInvalid: "orders-checkout-company-invalid",
      verificationServiceTimeout: "verification-service-timeout",
    },
  },
};

export const CART_CONFIG: OrdersCheckoutConfigType = {
  freeDeliveryStatuses: ["gold", "platinum"],
  defaultFreeDeliveryThreshold: 120,
};

export const noImage = {
  src: "/assets/images/no-image.svg",
  alt: "No Image",
  blurDataURL: imagePlaceholderDefault,
};

export const initialAddress = {
  companyName: "",
  country: null,
  index: "",
  city: "",
  street: "",
  houseNumber: "",
  additionalDetails: "",
};

const AssertError = {
  type: "ValidationAPI Error",
  message: "Data in response is invalid",
};

export const initialAssertErr = {
  type: AssertError.type,
  message: AssertError.message,
  status: 500,
};

export const QS_QUERY_CONFIG = verifyBuild().isDevelopment ? { encode: false } : { encode: true };

export const SIGNUP_STEPS_FORM_DATA = "signup-steps-form-data";
export const LEAD_LOGIN_KEY = "lead-login";
export const USER_COUNTRY_COOKIE_KEY = "user-country";
export const USER_LANGUAGE_COOKIE_KEY = "user-language";

export const CLIENT_HEADER_ACCEPT_LANGUAGE = "Accept-Language";

export const ERRORS_VALIDATION_KEY = "inputs:validationErrors.";
export const httpErrorMessage: NotificationMessage = {
  title: "Unavailable",
  desc: "Something went wrong. Please, try again later",
};
export const httpErrorForbiddenMessage: NotificationMessage = {
  title: "Forbidden",
  desc: "Access is denied",
};
export const httpErrorMessageNotFound: NotificationMessage = {
  title: "Error",
  desc: "Article not found",
};

export const httpErrorMessageSearch: NotificationMessage = {
  title: "Search Error",
  desc: "Something went wrong. Please, try again later",
};
export const httpErrorMessageNoData: NotificationMessage = {
  title: "No Data",
  desc: "Data for the app was not loaded successfully",
};
export const httpErrorMessageOrderError: NotificationMessage = {
  title: "Order verification failed",
  desc: "Cannot create order",
};
export const httpSuccessMessageCartProduct: NotificationMessage = {
  title: "Success",
  desc: "Product added to cart",
};

export const httpErrorMessageCartProductQuantityMax: NotificationMessage = {
  title: "Error",
  desc: "This article has already been added to your basket! You can only add up to 999 items for this product!",
};
export const httpSuccessMessageGeneric: NotificationMessage = {
  title: "Success",
  desc: "",
};
export const httpErrorMessageGeneric: NotificationMessage = {
  title: "Error",
  desc: "Something went wrong",
};
export const httpSuccessMessageUpdate: NotificationMessage = {
  title: "Success",
  desc: "You data was updated successfully!",
};
export const httpSuccessMessageCartProductRemove: NotificationMessage = {
  title: "Success",
  desc: "Product removed from cart",
};
export const httpSuccessMessageCartProductUpdate: NotificationMessage = {
  title: "Success",
  desc: "Product updated",
};
export const httpErrorMessageCartProductUpdate: NotificationMessage = {
  title: "Error",
  desc: "Article does not match the car",
};
export const httpErrorMessageNotFoundCartProduct: NotificationMessage = {
  title: "Error",
  desc: "Article not found",
};
export const httpErrorMessageSavedBasketProductUpdate: NotificationMessage = {
  title: "Error",
  desc: "Article does not match the car",
};
export const httpErrorMessageNotFoundSavedBasketProductUpdate: NotificationMessage = {
  title: "Error",
  desc: "Article not found",
};
export const httpErrorMessageInvalidRequest: NotificationMessage = {
  title: "Error",
  desc: "Invalid request",
};
export const httpSuccessMessageCustomerCreate: NotificationMessage = {
  title: "Success",
  desc: "Customer created",
};
export const httpSuccessMessageCustomerUpdate: NotificationMessage = {
  title: "Success",
  desc: "Customer updated",
};
export const httpSuccessMessageAddSavedBasketProduct: NotificationMessage = {
  title: "Success",
  desc: "New product was added",
};
export const httpSuccessMessageAddRepairEstimateProduct: NotificationMessage = {
  title: "Success",
  desc: "New product was added",
};
export const httpSuccessMessageAddRepairEstimateLabor: NotificationMessage = {
  title: "Success",
  desc: "New labor was added",
};
export const httpSuccessMessageUpdateRepairEstimateLabor: NotificationMessage = {
  title: "Success",
  desc: "The Labor was updated",
};
export const httpSuccessMessageUpdateSavedBasketTitle: NotificationMessage = {
  title: "Success",
  desc: "Title was updated",
};
export const httpSuccessMessageRemoveSavedBasketProduct: NotificationMessage = {
  title: "Success",
  desc: "Selected product was deleted",
};
export const httpErrorMessageNotFoundSavedBasket: NotificationMessage = {
  title: "Error",
  desc: "Saved basket was not found",
};
export const httpSuccessMessageCompleteSavedBasket: NotificationMessage = {
  title: "Success",
  desc: "Saved basket was checkouted",
};
export const httpSuccessMessageEmailSubscription: NotificationMessage = {
  title: "Success",
  desc: "Subscription activated",
};
export const httpErrorMessageEmailSubscription: NotificationMessage = {
  title: "Error",
  desc: "Subscription not activated",
};

export const httpSuccessMessageSavedBasketProductUpdate: NotificationMessage = {
  title: "Success",
  desc: "Product updated",
};

export const httpSuccessMessageRemoveCartPreviewVehicle: NotificationMessage = {
  title: "Success",
  desc: "Vehicle removed",
};

export const ErrorPhotoNotValidSize: NotificationMessage = {
  title: "Error",
  desc: "Image size is too large",
};

export const ErrorPhotoNotValidFile: NotificationMessage = {
  title: "Error",
  desc: "You can only upload JPG/PNG file",
};

export const httpSuccessMessageResetPasswordEmail: NotificationMessage = {
  title: "Success",
  desc: "Email has been sent",
};

export const httpSuccessMessageVehicleUpdate: NotificationMessage = {
  title: "Success",
  desc: "Vehicle updated",
};

export const httpSuccessMessageStatusUpdate: NotificationMessage = {
  title: "Success",
  desc: "Status updated",
};

export const initMetaState = {
  page: 1,
  total: 0,
  perPage: 20,
  path: "",
};

export const initMetaPagination = {
  page: 1,
  lastPage: 1,
  from: 0,
  to: 1,
  perPage: 15,
  total: 0,
};

export const initLastDocumentsSavedBasketsParams = {
  page: 1,
  perPage: 5,
};

export const contentSlickConfig = {
  infinite: true,
  pauseOnHover: true,
  autoplay: false,
  autoplaySpeed: 6000,
  speed: 800,
  swipeToSlide: false,
  swipe: false,
  arrows: false,
};

export const imageSlickConfig = {
  autoplay: true,
  infinite: true,
  speed: 800,
  swipeToSlide: false,
  swipe: false,
  arrows: false,
};

export const breakpointScreen = {
  lg: 992,
  md: 768,
};
