// Core
import { z } from "zod";
import { HttpRequestParams } from "../../models/Http";
import type { BaseDataListType } from "../../models/Base";

export enum StaticDataKeys {
  countries = "countries",
  phoneCodes = "phoneCodes",
  quantityAutolifts = "quantityAutolifts",
  businessType = "businessType",
  quantityEmployees = "quantityEmployees",
  returnStatuses = "returnStatuses",
  repairEstimateStatus = "repairEstimateStatus",
  returnCauses = "returnCauses",
  orderStatus = "orderStatus",
  returnSpentMinutes = "returnSpentMinutes",
  returnSpentHours = "returnSpentHours",
  returnOtherTypes = "returnOtherTypes",
  returnOptions = "returnOptions",
  cityOptions = "cityOptions",
  bonusOperation = "bonusOperation",
  viewDisplay = "viewDisplay",
}

export type StaticDataType = keyof typeof StaticDataKeys;

export type CitySearchType = BaseDataListType<StaticDataItemType>;

export type CountriesType = BaseDataListType<StaticDataItemType>;

export type CitySearchParamsType = { keyword?: string };

export type CitySearchQueryParamsType = HttpRequestParams<CitySearchParamsType>;

export type StaticDataInnerShape = {
  [K in StaticDataType]: StaticDataItemType[];
};

export type StaticDataFetchParams = HttpRequestParams<{ types: StaticDataType[] }>;

export const staticDataItemSchema = z.object({
  id: z.number(),
  value: z.string(),
  label: z.string(),
});

export type StaticDataItemType = z.infer<typeof staticDataItemSchema>;

export const paginationSchema = z
  .object({
    page: z.number().nullable().optional(),
    total: z.number().nullable().optional(),
    perPage: z.number().nullable().optional(),
    from: z.number().nullable().optional(),
    to: z.number().nullable().optional(),
    lastPage: z.number().nullable().optional(),
    path: z.string().optional(),
  })
  .strict();

export type PaginationType = z.infer<typeof paginationSchema>;
