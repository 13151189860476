// Core
import { z } from "zod";

export const imageSchema = z
  .object({
    id: z.number().nullable().optional(),
    src: z.string(),
    alt: z.string(),
    blurDataURL: z.string().optional(),
  })
  .strict();
export type ImageType = z.infer<typeof imageSchema>;
