// Core
import { useTranslation } from "next-i18next";

// Components
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Footer } from "components/common/Footer";
import { Logo } from "components/common/Logo";
import { TecDocLogo } from "components/common/TecDocLogo";
import { FooterCopyrite } from "components/common/FooterCopyrite";
import { FooterMenuPrivate } from "components/common/FooterMenuPrivate";
import { Button } from "components/ui/Button";

// Utils
import { rowGutters } from "utils/constants";
import st from "./Styles.module.less";

// Hooks
import { useConsentCookiesReset } from "client/core/consent-cookies/hooks/use-consent-cookies-reset";

export const LayoutFooterPrivate = () => {
  const { t } = useTranslation();
  const { resetCookie } = useConsentCookiesReset();

  return (
    <Footer testId="footer">
      <Row gutter={rowGutters} className={st["footer-top"]}>
        <Col span={6}>
          <div className={st["footer-logo"]}>
            <Logo alt={t("common:firstPartPageTitle")} testId="logo-footer" />
          </div>
          <Button btnType="navigation-on-dark" onClick={resetCookie} size="large">
            {t("cookies:consentCookies.buttons.resetCookies")}
          </Button>
        </Col>
        <Col span={18}>
          <FooterMenuPrivate />
        </Col>
      </Row>

      <Row gutter={rowGutters} align="middle" className={st["footer-middle"]}>
        <Col span={24}>
          <TecDocLogo testId="tec-doc-logo-footer" copyright={t("common:tecDoc.copyright")} />
        </Col>
      </Row>

      <Row gutter={rowGutters} align="middle" className={st["footer-bottom"]}>
        <Col span={24}>
          <FooterCopyrite />
        </Col>
      </Row>
    </Footer>
  );
};
