// Core
import { useState, useEffect, useMemo } from "react";

// Utils
import { GET_PARAMS, GET_ENUMS } from "utils/constants";
import { useGetParameter } from "hooks/useGetParameter";

let timeout: NodeJS.Timeout;

type UseGetPopupStateType = {
  isOpened: boolean;
  mountedComponent: string;
};

export const useGetPopupState = (): UseGetPopupStateType => {
  const popupName = useGetParameter(GET_PARAMS.popup);

  const popupNameDefault = Array.isArray(popupName) ? "" : popupName;
  const [mountedComponent, setMountedComponent] = useState(popupNameDefault);

  const popupIsValid = (name = ""): boolean => {
    if (name === "") return false;
    const popupList = Object.values(GET_ENUMS.popup);
    return popupList.includes(name);
  };

  useEffect(() => {
    if (popupNameDefault && popupIsValid(popupNameDefault)) {
      setMountedComponent(popupNameDefault);
      timeout && clearTimeout(timeout);
    } else {
      if (!timeout) {
        timeout = setTimeout(() => {
          setMountedComponent("");
        }, 300);
      }
    }
  }, [popupNameDefault]);

  useEffect(() => {
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, []);

  const isOpened = useMemo(() => popupIsValid(popupNameDefault), [popupNameDefault]);

  return {
    isOpened,
    mountedComponent,
  };
};
