// Core
import { Fragment, MouseEvent } from "react";
import { useTranslation } from "next-i18next";

// Components
import { Col } from "components/ui/Col";
import { Row } from "components/ui/Row";
import { Text } from "components/ui/Typography/Text";
import { ModalConfirmSignout } from "components/common/ModalViews/ConfirmSignout";
import { Button } from "components/ui/Button";
import { Modal } from "components/ui/Modal";

// Hooks
import { useModalContext } from "hooks/useModalContext";
import { useProfileContext } from "hooks/useProfileContext";

// Utils
import st from "./Styles.module.less";
import { rowGutters } from "utils/constants";

export type PopoverUserNavbarProps = {
  handleVisibleChange?: (visible: boolean) => void;
  testId?: string;
};

export const PopoverUserNavbar = (props: PopoverUserNavbarProps) => {
  const { handleVisibleChange, testId = "popover-user" } = props;
  const { t } = useTranslation();
  const { profile } = useProfileContext();

  const { onPageModals, setOnPageModal } = useModalContext();
  const modalType = "user-signout";

  const onOpenModal = () => {
    handleVisibleChange?.(false);
    setOnPageModal(modalType, true);
  };

  const onCloseModal = () => {
    setOnPageModal(modalType, false);
  };

  const onClickLayout = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className={st["popover-user"]} data-test-id={testId} onClick={onClickLayout}>
      <Row gutter={rowGutters} justify="space-between">
        <Col>
          <div className={st["user-greeting"]}>
            <Text fontWeight="600">{t("common:popovers:user:greeting")}</Text>
            <Text fontWeight="600">
              <Fragment>
                {profile?.firstName} {profile?.surname}
              </Fragment>
            </Text>
          </div>
        </Col>
        <Col>
          <Button
            className={st.button}
            type="text"
            testId="popover-user-btn-confirm"
            onClick={onOpenModal}
          >
            {t("common:popovers:user:logout")}
          </Button>
        </Col>
      </Row>
      <Modal visible={onPageModals[modalType]} zIndex={1080} onClose={onCloseModal}>
        <ModalConfirmSignout onClose={onCloseModal} />
      </Modal>
    </div>
  );
};
