// Core
import { useTranslation } from "next-i18next";

// Components
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Button } from "components/ui/Button";
import { FormTitle } from "components/ui/FormTitle";
import { Descriptions, DescriptionsVariantEnum } from "components/ui/Descriptions";
import { Text } from "components/ui/Typography/Text";

// Utils
import st from "./Styles.module.less";
import { productSearchNoticeModel } from "utils/renderModels/productSearchNoticeModel";

type ModalSearchNoticeProps = {
  onClose?: () => void;
};

export const ModalSearchNotice = ({ onClose }: ModalSearchNoticeProps) => {
  const { t } = useTranslation();
  const info = productSearchNoticeModel({ t });

  return (
    <div className={st["modal-search-notice"]}>
      <FormTitle>{t("common:modals.searchNotice.title")}</FormTitle>
      <div className={st["search-notice-subtitle"]}>
        <Text tag="p" alignmentHorizontal="center" size="14" color="gray-700">
          {t("common:modals.searchNotice.desc")}
        </Text>
        <Text tag="p" alignmentHorizontal="center" size="14" color="gray-700" fontWeight="700">
          {t("common:modals.searchNotice.subtitle")}
        </Text>
      </div>
      <div className={st["search-notice-content"]}>
        <Descriptions column={1} data={info} variant={DescriptionsVariantEnum.notice} />
      </div>
      <Row justify="center">
        <Col span={12}>
          <Button type="primary" size="large" block onClick={onClose}>
            {t("buttons:close")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
