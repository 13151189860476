// Components
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Trans } from "next-i18next";
import { Link } from "components/common/Link";
import { Text } from "components/ui/Typography/Text";
import { Container } from "components/common/Container";

// Domains
import { useProfileContext } from "hooks/useProfileContext";

// Utils
import st from "./Styles.module.less";
import { rowGutters } from "utils/constants";
import { useProfileCountdownTimer } from "bus/profile/hooks/useProfileCountdownTimer";
import { getSurveyNPSLink, surveyNPS } from "data/survey";

export const BannerSurvey = () => {
  const { profile } = useProfileContext();
  const remainingTime = useProfileCountdownTimer(surveyNPS.targetDate);

  return (
    <div className={st.banner}>
      <Container>
        <Row gutter={rowGutters}>
          <Col span={24}>
            <div className={st.center}>
              <Text font="system" fontWeight="600" transform="up" color="white-100">
                <Trans
                  i18nKey={"common:survey:header.text"}
                  components={{
                    "survey-link": (
                      <Link
                        fontWeight="600"
                        size="16"
                        type="banner"
                        decoration="underline"
                        blank
                        href={getSurveyNPSLink(profile?.customerId, profile?.countryCode)}
                      />
                    ),
                  }}
                />
              </Text>
              <Text font="system" fontWeight="600" transform="up" color="white-100">
                {remainingTime}
              </Text>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
