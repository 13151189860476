// Core
import { z } from "zod";

// Bus
import type { BaseDataListType } from "models/Base";

export enum SearchTypesEnum {
  product = 1,
  oem = 2,
  generic = 3,
  category = 4,
  brand = 5,
}

const SelectItemScheme = z
  .object({
    id: z.number(),
    value: z.string(),
    label: z.string(),
    icon: z.string().optional(),
    options: z
      .array(
        z
          .object({
            id: z.number(),
            value: z.string(),
            label: z.string(),
          })
          .strict(),
      )
      .optional(),
  })
  .strict();

const SearchVehicleRegNumScheme = z.object({
  regNumber: z.string(),
});
export type SearchVehicleRegNumType = z.infer<typeof SearchVehicleRegNumScheme>;

const SearchAutocompleteOptionScheme = z.object({
  label: z.string(),
  count: z.number().nullable(),
  keyword: z.string(),
  entity: z.string(),
  type: z.nativeEnum(SearchTypesEnum),
});
export type SearchAutocompleteOptionType = z.infer<typeof SearchAutocompleteOptionScheme>;

const SearchAutocompleteParamsScheme = z.object({
  keyword: z.string().optional(),
});

export type SearchAutocompleteParamsType = z.infer<typeof SearchAutocompleteParamsScheme>;

const SearchAutocompleteQueryParamsScheme = z.object({
  params: SearchAutocompleteParamsScheme,
});
export type SearchAutocompleteQueryParamsType = z.infer<typeof SearchAutocompleteQueryParamsScheme>;

const SearchBarScheme = z.object({
  type: z.nativeEnum(SearchTypesEnum).nullable(),
  entity: z.string(),
  search: z.string(),
  keyword: z.string(),
});
export type SearchBarType = z.infer<typeof SearchBarScheme>;

const SearchVehicleScheme = z.object({
  search: z.string().optional(),
});
export type SearchVehicleType = z.infer<typeof SearchVehicleScheme>;

const SearchVehicleAttrsScheme = z.object({
  carMaker: SelectItemScheme.nullable(),
  carModel: SelectItemScheme.nullable(),
  carEngine: SelectItemScheme.nullable(),
});
export type SearchVehicleAttrsType = z.infer<typeof SearchVehicleAttrsScheme>;

export type SearchAutocompleteType = BaseDataListType<SearchAutocompleteOptionType>;

export const paginationSchema = z
  .object({
    page: z.number().nullable().optional(),
    total: z.number().nullable().optional(),
    perPage: z.number().nullable().optional(),
    from: z.number().nullable().optional(),
    to: z.number().nullable().optional(),
    lastPage: z.number().nullable().optional(),
    path: z.string().optional(),
  })
  .strict();

export type PaginationType = z.infer<typeof paginationSchema>;

export const imageSchema = z
  .object({
    id: z.number().nullable().optional(),
    src: z.string(),
    alt: z.string(),
    blurDataURL: z.string().optional(),
  })
  .strict();
export type ImageType = z.infer<typeof imageSchema>;

export const productImageSchema = z
  .object({
    thumb: imageSchema.nullable(),
    list: imageSchema.array().optional(),
  })
  .strict();

const productBrandSchema = z
  .object({
    id: z.number().nullable(),
    name: z.string(),
    image: imageSchema.nullable(),
  })
  .strict();
export type ProductBrandType = z.infer<typeof productBrandSchema>;

const productQuantitySchema = z
  .object({
    value: z.number().nullable(),
    step: z.number().nullable(),
    default: z.number().nullable(),
    available: z.number().nullable(),
  })
  .strict();
export type ProductQuantityType = z.infer<typeof productQuantitySchema>;

export const priceSchema = z
  .object({
    value: z.number().nullable(),
    country: z.string(),
  })
  .strict()
  .nullable();

export type PriceType = z.infer<typeof priceSchema>;

const productCriteriaSchema = z
  .object({
    id: z.number().nullable(),
    value: z.string(),
    param: z.string(),
  })
  .strict();
export type ProductCriteriaType = z.infer<typeof productCriteriaSchema>;

const productCriteriesSchema = z
  .object({
    dynamic: productCriteriaSchema.array().optional(),
    static: productCriteriaSchema.array().optional(),
  })
  .strict();
export type ProductCriteriesType = z.infer<typeof productCriteriesSchema>;

const productPromoItemSchema = z
  .object({
    id: z.number().nullable(),
    value: z.enum(["empty", "default", "hot", "delivery"]),
    label: z.string(),
    image: imageSchema.nullable(),
  })
  .strict();
export type ProductPromoLabelType = z.infer<typeof productPromoItemSchema>;

export const productPromoSchema = z
  .object({
    prime: productPromoItemSchema.nullable(),
    general: productPromoItemSchema.array().optional(),
    stock: productPromoItemSchema.array().optional(),
  })
  .strict();
export type ProductPromotionsType = z.infer<typeof productPromoSchema>;

const productSchema = z
  .object({
    id: z.number().nullable(),
    genericId: z.number().nullable(),
    articleId: z.number().nullable(),
    articleNo: z.string(),
    images: productImageSchema.nullable(),
    brand: productBrandSchema.nullable(),
    countryOfOrigin: z.string().nullable(), // TODO: remove null in API!
    ean: z.string(),
    quantity: productQuantitySchema.nullable(),
    deliveryAt: z.number().nullable(),
    priceRecommended: priceSchema.nullable(),
    pledge: priceSchema.nullable(),
    margin: z.number().nullable(),
    vatPrice: priceSchema.nullable(),
    priceNonVat: priceSchema.nullable(),
    name: z.string(),
    vatPercentage: z.number().nullable(),
    weight: z.number().nullable(),
    criteria: productCriteriesSchema.nullable(),
    isVinCompatibility: z.boolean(),
    fittingScore: z.number().nullable(),
    promotions: productPromoSchema.nullable(),
    isAvailable: z.boolean(),
    oldVatPrice: priceSchema.nullable(),
    oldPriceNonVat: priceSchema.nullable(),
  })
  .strict();
export type ProductType = z.infer<typeof productSchema>;

export type ProductsListingType = {
  items: ProductType[];
  meta: PaginationType;
};

export type FilterDynamicItemVehicle = {
  label: string;
  value: number;
};

const filterDynamicStaticDataItemSchema = z.object({
  id: z.number(),
  value: z.string(),
  label: z.string(),
});
type FilterDynamicStaticDataItemType = z.infer<typeof filterDynamicStaticDataItemSchema>;

export type FilterDynamicItemOptionType = FilterDynamicStaticDataItemType & {
  count?: number;
  isSelected?: boolean;
};

export type FilterDynamicItemPriceRange = {
  minPrice?: number;
  maxPrice?: number;
  value?: [number, number];
};

export const staticDataItemSchema = z.object({
  id: z.number(),
  value: z.string(),
  label: z.string(),
});

export type StaticDataItemType = z.infer<typeof staticDataItemSchema>;

export type RadioButtonItem = StaticDataItemType & {
  isSelected?: boolean;
};

export type FilterHorizontalDynamicMountingSideType = {
  id?: number;
  label?: string;
  value?: string;
  isSelected?: boolean;
  data?: RadioButtonItem[] | null;
};

export const criteriaItemSchema = z
  .object({
    id: z.number(),
    value: z.string(),
    label: z.string(),
    isSelected: z.boolean().optional(),
  })
  .strict();
export type CriteriaItem = z.infer<typeof criteriaItemSchema>;

export type FilterHorizontalDynamicCriteriaType = {
  id?: number;
  name?: string;
  value?: number;
  data?: CriteriaItem[];
};

export type FilterDynamicItemDataType =
  | FilterDynamicItemVehicle
  | FilterDynamicItemOptionType[]
  | FilterDynamicItemPriceRange
  | FilterHorizontalDynamicMountingSideType
  | FilterHorizontalDynamicCriteriaType[];

export enum FilterDynamicTypesEnum {
  checkboxGroup = "checkbox-group",
  radioGroup = "radio-group",
  priceRange = "price-range",
  radioGroupSide = "radio-group-side",
  criteriaGroup = "criteria-group",
  vehicleFilter = "vehicle-filter",
}

export enum FilterDynamicFieldsEnum {
  priceRange = "priceRange",
  brand = "brand",
  delivery = "delivery",
  installation = "installation",
  criteria = "criteria",
  generic = "generic",
  fittingScore = "fittingScore",
  vehicleId = "vehicleId",
}

export type FilterDynamicTypesEnumType = keyof { [key in FilterDynamicTypesEnum]: string };

export type FilterDynamicItemType = {
  data: FilterDynamicItemDataType;
  id: number;
  type: FilterDynamicTypesEnumType;
  fieldName?: FilterDynamicFieldsEnum;
};

export type ProductsType = {
  products: ProductsListingType;
  filter: FilterDynamicItemType[];
};
