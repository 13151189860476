// Core
import { useCallback } from "react";

// Definitions
import type { HttpErrorType } from "models/Http";

// Domains
import { authKeys } from "bus/auth/store";
import { authService } from "bus/auth/service";

// Utils
import { useMutationAsync } from "utils/react-query/hooks/use-mutation-async";

type AuthLoginCreateMutationKeyType = [string];
type OnCreateAuthLogoutMutationCbType = () => Promise<[null | HttpErrorType]>;
type UseAuthLoginType = {
  loadingAuthLogout: boolean;
  onLogoutAuth: OnCreateAuthLogoutMutationCbType;
};

export const useAuthLogoutMutation = (): UseAuthLoginType => {
  const authLogoutProps = useMutationAsync<void, void, void, AuthLoginCreateMutationKeyType>({
    key: [authKeys.logout],
    name: "createAuthLoginMutation",
    fetcher: authService.logout,
    options: {
      onError: () => void 0,
    },
  });

  const onCreate = useCallback<OnCreateAuthLogoutMutationCbType>(async () => {
    try {
      await authLogoutProps.mutateAsync();
      return [null];
    } catch (err) {
      return [err as HttpErrorType];
    }
  }, []);

  return {
    loadingAuthLogout: authLogoutProps.isLoading,
    onLogoutAuth: onCreate,
  };
};
