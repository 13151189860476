/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// Core
import QS from "qs";
import { keys } from "ramda";

// Definitions
import {
  FilterDynamicFieldsEnum,
  FilterDynamicItemVehicle,
  FilterDynamicTypesEnum,
} from "models/Filter";
import type { CriteriaItem } from "models/FormFields";
import type {
  FilterDynamicItemOptionType,
  FilterDynamicItemPriceRange,
  FilterDynamicItemType,
  FilterHorizontalDynamicCriteriaType,
  FilterHorizontalDynamicMountingSideType,
  FilterParamsType,
  FilterQueryParamsType,
  FilterStaticQueryParamsType,
  FilterType,
  TableActionType,
} from "models/Filter";
import type { SortType } from "models/Sort";
import type { PaginationType } from "bus/staticData/models";

const TABLE_DEFAULT_PAGE = 1;

const getInitialQueries = (query?: FilterQueryParamsType | FilterStaticQueryParamsType | null) => {
  const DISABLED_VALUES = ["popup"];
  if (query) {
    const enabledQueries = Object.entries(query).filter(([key]) => !DISABLED_VALUES.includes(key));
    return Object.fromEntries(enabledQueries) as Record<string, string>;
  }

  return {};
};

export const getParamsFromQueryStr = (
  query: FilterQueryParamsType | null = null,
): FilterQueryParamsType => {
  const initialQuery = getInitialQueries(query);
  return QS.parse(initialQuery || "", { parseArrays: true });
};

export const getParamsFilterStaticFromQueryStr = <T extends Record<string, unknown>>(
  query?: FilterStaticQueryParamsType<T>,
): FilterStaticQueryParamsType<Record<string, unknown>> => {
  const initialQuery = getInitialQueries(query);
  const queryObj = QS.parse(initialQuery || "", { parseArrays: true });

  return (
    {
      ...queryObj,
    } || {}
  );
};

export const getFinalTableQuery = (
  params: FilterParamsType,
  pagination: PaginationType | null = null,
): FilterParamsType => {
  const perPage = params.perPage || pagination?.perPage;
  const page = params.page || pagination?.page;
  const sort = params?.sort && Object.keys(params?.sort).length ? params.sort : null;
  const isPerPageChanged = params.perPage && params.perPage !== pagination?.perPage;
  const lastPage = pagination?.total && perPage && Math.ceil(pagination.total / Number(perPage));
  const availablePage = page && lastPage && isPerPageChanged ? TABLE_DEFAULT_PAGE : page;

  return {
    // do not remove keys with void, it's save order of query params
    page: void 0,
    perPage: void 0,
    sort: void 0,
    ...(sort && { sort }),
    ...(perPage && { perPage: Number(perPage) }),
    ...(page && { page: Number(availablePage) }),
  };
};

export const onTableChanges = (
  pager: PaginationType,
  action: TableActionType,
  pagination: PaginationType,
  handler?: (params: FilterParamsType) => void,
  sort?: SortType,
): void => {
  if (action === "paginate") {
    if (pager.perPage !== pagination?.perPage) {
      handler?.({ perPage: Number(pager.perPage), ...(sort ? { sort } : {}) });
      return;
    }
    handler?.({ page: Number(pager.page), ...(sort ? { sort } : {}) });
  }
};

const getProductsFilterSelectedOptions = (
  data: FilterDynamicItemOptionType[],
  fieldName: string,
  accumulator: FilterType,
): FilterType => {
  const selectedItems = data
    .filter((o: FilterDynamicItemOptionType) => o.isSelected)
    .map((item: FilterDynamicItemOptionType) => item.value);
  return { ...accumulator, ...(selectedItems.length && { [fieldName]: selectedItems }) };
};
const getProductsFilterPriceRange = (
  data: FilterDynamicItemPriceRange,
  fieldName: string,
  accumulator: FilterType,
): FilterType => {
  if (fieldName === FilterDynamicFieldsEnum.priceRange) {
    return {
      ...accumulator,
      [fieldName]: Array.isArray(data.value) ? data.value : [],
    };
  }
  return accumulator;
};

const getProductsFilterRadioGroupSide = (
  data: FilterType["installation"][],
  fieldName: string,
  accumulator: FilterType,
): FilterType => {
  if (fieldName === FilterDynamicFieldsEnum.installation) {
    const radioSelected = data?.find((item) => !Array.isArray(item) && item && item.isSelected);
    return {
      ...accumulator,
      ...(radioSelected && { [fieldName]: radioSelected }),
    };
  }
  return accumulator;
};

const getProductsFilterRadioGroup = (
  data: FilterDynamicItemOptionType[],
  fieldName: string,
  accumulator: FilterType,
): FilterType => {
  if (fieldName === FilterDynamicFieldsEnum.fittingScore) {
    const radioSelected = data?.find((item) => item && item.isSelected);
    return {
      ...accumulator,
      ...(radioSelected && { [fieldName]: radioSelected.value }),
    };
  }
  return accumulator;
};

const getProductsFilterVehicle = (data: FilterDynamicItemVehicle, accumulator: FilterType) => {
  return {
    vehicleId: data.value,
    ...accumulator,
  };
};

const getProductsFiltersInitialCriteria = (
  data: FilterHorizontalDynamicCriteriaType[],
  fieldName: string,
  accumulator: FilterType,
): FilterType => {
  if (fieldName === FilterDynamicFieldsEnum.criteria) {
    const criteria = data.reduce(
      (
        result: Record<string, CriteriaItem>[],
        currentValue,
        idx,
      ): Record<string, CriteriaItem>[] => {
        const criteriaSelected = currentValue?.data?.find((cr) => cr.isSelected);
        if (criteriaSelected && currentValue.value) {
          const arrValue = [...result];
          arrValue[idx] = { [`${currentValue.value}`]: criteriaSelected };
          return arrValue;
        }
        return result;
      },
      [],
    );
    return { ...accumulator, criteria };
  }
  return accumulator;
};

export const getProductsFilterInitialValues = (filters: FilterDynamicItemType[] = []): FilterType =>
  filters.reduce((result, f): FilterType => {
    if (!f || !f.fieldName) return result;
    if (f.type === FilterDynamicTypesEnum.criteriaGroup) {
      return getProductsFiltersInitialCriteria(
        f.data as FilterHorizontalDynamicCriteriaType[],
        f.fieldName,
        result,
      );
    }
    if (f.type === FilterDynamicTypesEnum.radioGroupSide) {
      return getProductsFilterRadioGroupSide(
        f.data as FilterDynamicItemOptionType[],
        f.fieldName,
        result,
      );
    }
    if (f.type === FilterDynamicTypesEnum.radioGroup) {
      return getProductsFilterRadioGroup(
        f.data as FilterDynamicItemOptionType[],
        f.fieldName,
        result,
      );
    }
    if (f.type === FilterDynamicTypesEnum.vehicleFilter) {
      return getProductsFilterVehicle(f.data as FilterDynamicItemVehicle, result);
    }
    if (Array.isArray(f.data)) {
      const data = f.data as FilterDynamicItemOptionType[];
      return getProductsFilterSelectedOptions(data, f.fieldName, result);
    }
    if (!Array.isArray(f.data)) {
      return getProductsFilterPriceRange(
        f.data as FilterDynamicItemPriceRange,
        f.fieldName,
        result,
      );
    }

    return result;
  }, {});

export const getProductsFilterActive = (
  filters: FilterDynamicItemType[] = [],
): FilterDynamicItemType[] =>
  filters.reduce(
    (acc: FilterDynamicItemType[], f: FilterDynamicItemType): FilterDynamicItemType[] => {
      if (f.type === FilterDynamicTypesEnum.priceRange) {
        const data = f.data as FilterDynamicItemPriceRange;
        if (!(data.value?.[0] === data.minPrice && data.value?.[1] === data.maxPrice)) {
          return [...acc, f];
        }
      }
      if (f.type === FilterDynamicTypesEnum.radioGroupSide) {
        const data = f.data as FilterHorizontalDynamicMountingSideType;
        const items = data?.data?.filter((o: FilterDynamicItemOptionType) => o.isSelected);
        if (items?.length) {
          return [...acc, { ...f, data: { ...f.data, data: items } }];
        }
      }
      if (f.type === FilterDynamicTypesEnum.radioGroup) {
        const data = f.data as FilterDynamicItemOptionType[];
        const items = data?.filter((o: FilterDynamicItemOptionType) => o.isSelected);
        if (items?.length) {
          return [...acc, { ...f, data: items }];
        }
      }
      if (f.type === FilterDynamicTypesEnum.criteriaGroup) {
        const data = f.data as FilterHorizontalDynamicCriteriaType[];
        const activeItems = data?.reduce(
          (
            activeCriteria: FilterHorizontalDynamicCriteriaType[],
            fc,
            idx,
          ): FilterHorizontalDynamicCriteriaType[] => {
            const activeOption = fc.data?.filter((o: FilterDynamicItemOptionType) => o.isSelected);
            if (activeOption?.length) {
              const res = [...activeCriteria];
              res[idx] = { ...fc, data: activeOption };
              return res;
            }

            return activeCriteria;
          },
          [],
        );
        if (activeItems?.length) {
          return [...acc, { ...f, data: [...activeItems] }];
        }
      }
      if (Array.isArray(f.data)) {
        const items = (f.data as FilterDynamicItemOptionType[]).filter(
          (o: FilterDynamicItemOptionType) => o.isSelected,
        );
        if (items.length) {
          return [...acc, { ...f, data: items }];
        }
      }
      return acc;
    },
    [],
  );

export const getSearchProductsFilters = (
  filters: FilterDynamicItemType[] = [],
): FilterDynamicItemType[] =>
  filters.reduce(
    (acc: FilterDynamicItemType[], f: FilterDynamicItemType): FilterDynamicItemType[] => {
      if (f.fieldName === FilterDynamicFieldsEnum.generic) {
        const activeItems = (f.data as FilterDynamicItemOptionType[]).filter(
          (o: FilterDynamicItemOptionType) => o.isSelected,
        );
        if (activeItems.length) {
          return acc;
        }
      }
      return [...acc, f];
    },
    [],
  );

export const mapFilterRequestData = (
  f: FilterType | null = null,
  initialFilters?: FilterDynamicItemType[],
): FilterParamsType => {
  if (!f) {
    return { filter: null };
  }

  const priceFilter = initialFilters?.find((item) => {
    return item.type === FilterDynamicTypesEnum.priceRange;
  });
  const isMinPrice =
    priceFilter &&
    f?.priceRange?.[0] !== (priceFilter?.data as FilterDynamicItemPriceRange).minPrice;
  const isMaxPrice =
    priceFilter &&
    f?.priceRange?.[1] !== (priceFilter?.data as FilterDynamicItemPriceRange).maxPrice;

  const isPriceRange = isMinPrice || isMaxPrice;
  const criteria = f?.criteria?.reduce(
    (acc: Record<string, string>, el): Record<string, string> => {
      const criteriaElement = el as Record<string, CriteriaItem>;
      return {
        ...acc,
        ...keys(criteriaElement).reduce(
          (elAcc: Record<string, string>, key): Record<string, string> => {
            if (criteriaElement?.[key]?.value) {
              return {
                ...elAcc,
                // compare with initial
                [key]: criteriaElement[key].value,
              };
            }
            return elAcc;
          },
          {},
        ),
      };
    },
    {},
  );
  const filter = {
    ...(f?.vehicleId ? { vehicleId: f.vehicleId } : {}),
    ...(f?.nodeId ? { nodeId: f.nodeId } : {}),
    ...(f?.brand?.length ? { brand: f.brand } : {}),
    ...(f?.generic?.length ? { generic: f.generic } : {}),
    ...(f?.delivery ? { delivery: f.delivery } : {}),
    ...(isPriceRange ? { priceFrom: f.priceRange?.[0], priceTo: f.priceRange?.[1] } : {}),
    ...(f?.installation && "value" in f.installation ? { installation: f.installation.value } : {}),
    ...(f?.fittingScore ? { fittingScore: f.fittingScore } : {}),
    ...(criteria && Object.keys(criteria).length ? { criteria } : {}),
  };
  return { filter: Object.keys(filter).length ? filter : null };
};

export const resetCheckboxValue = (filterData: FilterParamsType, initialValues: FilterType) => {
  const filterName = FilterDynamicFieldsEnum.brand;
  const initialFieldFilter = initialValues?.[filterName];
  if (Array.isArray(initialFieldFilter)) {
    const filter = filterData?.filter || {};
    if (!(filterName in filter) && initialFieldFilter.length) {
      return true;
    }
  }
  return false;
};
