// Core
import cx from "classnames";

// Definitions
import type { PropsWithChildren } from "react";
import type { DescriptionsProps } from "./Descriptions.types";

// Components
import { Descriptions as AntDescriptions, Skeleton } from "antd";

// Utils
import st from "./Styles.module.less";

type DescriptionsItemProps = PropsWithChildren<{
  label: string;
  className?: string;
  layout?: "vertical" | "horizontal";
}>;

const DescriptionsItem = ({ label, children, className, ...props }: DescriptionsItemProps) => {
  const descriptionsItemStyle = cx(st["descriptions-item"], className);

  return (
    <AntDescriptions.Item {...props} className={descriptionsItemStyle} label={label}>
      {children}
    </AntDescriptions.Item>
  );
};

export const DescriptionsSkeleton = () => {
  return <Skeleton.Input className={st["descriptions-skeleton"]} active />;
};

export enum DescriptionsVariantEnum {
  static = "static",
  dynamic = "dynamic",
  notice = "notice",
}

export const Descriptions = (props: DescriptionsProps) => {
  const {
    data,
    className = "",
    loading = false,
    numberOfSkeletons = 10,
    variant = DescriptionsVariantEnum.static,
    layout = "horizontal",
    ...rest
  } = props;
  const descriptionsStyle = cx(st.descriptions, { [st[variant]]: variant }, className, {
    [st[layout]]: layout,
  });

  if (variant === DescriptionsVariantEnum.dynamic && loading) {
    return (
      <>
        {[...Array(numberOfSkeletons).keys()].map((i) => (
          <Skeleton.Input key={i} className={st.skeleton} size="small" active />
        ))}
      </>
    );
  }

  const renderDescriptionsContent = () => {
    switch (variant) {
      case DescriptionsVariantEnum.dynamic:
        return data.map(({ id, label, value }) => (
          <DescriptionsItem key={id} label={label}>
            {value}
          </DescriptionsItem>
        ));
      case DescriptionsVariantEnum.static:
      case DescriptionsVariantEnum.notice:
        return data.map(({ id, label, value }) => (
          <DescriptionsItem key={id} label={label}>
            {loading ? <DescriptionsSkeleton /> : value}
          </DescriptionsItem>
        ));
      default:
        return null;
    }
  };

  return (
    <AntDescriptions {...rest} layout={layout} className={descriptionsStyle}>
      {renderDescriptionsContent()}
    </AntDescriptions>
  );
};
