// Core
import { useTranslation } from "next-i18next";

// Definitions
import { PermissionAct } from "utils/permission";

// Components
import { Layout } from "antd";
import { Col } from "components/ui/Col";
import { Row } from "components/ui/Row";
import { Container } from "components/common/Container";
import { Navbar } from "components/common/Navbar";
import { Logo } from "components/common/Logo";
import { NavbarProfile } from "components/common/NavbarProfile";
import { MainSearchBar } from "components/common/MainSearchBar";
import { HeaderBanner } from "components/common/HeaderBanner";
import { Can } from "components/common/Can";

// Hooks
import { useProfileContext } from "hooks/useProfileContext";

// Utils
import { rowGutters } from "utils/constants";
import { mainNavModel, mainNavItems } from "utils/renderModels/mainNavModel";
import st from "./Styles.module.less";

type LayoutHeaderPrivateProps = {
  hasActions?: boolean;
};

export const LayoutHeaderPrivate = ({ hasActions = true }: LayoutHeaderPrivateProps) => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();

  const menuItems = mainNavModel({ items: mainNavItems(profile?.permission?.pages), t });

  return (
    <Layout.Header className={st.header} data-test-id="header-private">
      <Can I={PermissionAct.READ} this={"banner_top"}>
        {profile && <HeaderBanner country={profile.countryCode} />}
      </Can>
      <div className={st["header-top"]}>
        <Container>
          <Row gutter={rowGutters} align="middle">
            <Col span={4}>
              <Logo alt={t("common:firstPartPageTitle")} testId="logo-header" />
            </Col>
            <Col span={16}>
              <MainSearchBar />
            </Col>
            {hasActions && (
              <Col span={4} className={st.actions}>
                <NavbarProfile />
              </Col>
            )}
          </Row>
        </Container>
      </div>
      <div className={st["header-bottom"]}>
        <Container>
          <Row justify="center">
            <Col span={12}>
              <Navbar menuItems={menuItems} />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout.Header>
  );
};
