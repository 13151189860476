// Core
import { FieldErrors, FieldValues, FormState } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";

// Definition
import { BaseShapeFormType } from "models/Base";
import type {
  FieldMetaStatus,
  FieldMetaHelpText,
  FieldsMetaType,
  TouchedFields,
  ErrorsFields,
  SetErrorFnType,
} from "models/Forms";
import { OrdersCheckoutPaymentMethodSecondaryMetaNormalizedNameEnum } from "bus/orders/models";
import type {
  OrdersCheckoutType,
  OrdersCheckoutPaymentMethodSecondaryMetaType,
  OrdersCheckoutPaymentMethodSecondaryFormType,
  OrdersCheckoutPaymentMethodSecondaryUpdateType,
} from "bus/orders/models";

// Utils
import { dateFormat } from "utils/constants";

export const getFieldValidate = (
  key = "",
  touched: TouchedFields = {},
  errors: ErrorsFields = void 0,
): FieldMetaStatus => {
  const isInValid = errors?.[key];
  const isValid = touched[key] && !errors?.[key];
  return isInValid ? "error" : isValid ? "success" : void 0;
};

export const getFieldHelpText = (key = "", errors: ErrorsFields = void 0): FieldMetaHelpText => {
  const err = errors?.[key] || {};
  return { ...("message" in err ? { help: err.message as string } : {}) };
};

export function getFieldsMetaValidation<T extends FieldValues = BaseShapeFormType>(
  shape: BaseShapeFormType,
  formState: FormState<T>,
): {
  [key: string]: {
    status: FieldMetaStatus;
    helpText: FieldMetaHelpText;
  };
} {
  const { touchedFields, errors } = formState;

  return Object.keys(shape).reduce((acc, key) => {
    const status = getFieldValidate(key, touchedFields, errors);
    const helpText = getFieldHelpText(key, errors);
    const fieldMeta = {
      [key]: {
        status,
        helpText,
      },
    };
    return {
      ...acc,
      ...fieldMeta,
    };
  }, {});
}

export const getPartialFieldsMetaValidation = <Shape extends object>(
  shape: Record<keyof Shape, Shape[keyof Shape]>,
  touched: TouchedFields,
  errors: ErrorsFields,
  baseName: Array<string | number> = [],
): FieldsMetaType<Shape> => {
  return Object.keys(shape).reduce((acc, key) => {
    const status = getFieldValidate(key, touched, errors);
    const helpText = getFieldHelpText(key, errors);

    const fieldMeta = {
      [key]: {
        name: [...baseName, key].join("."),
        status,
        helpText,
      },
    };

    return {
      ...acc,
      ...fieldMeta,
    };
  }, {} as FieldsMetaType<Shape>);
};

export const fillServerErrorsToForm = <T = Record<string, string>>(
  errors: { [key: string]: string },
  setError?: SetErrorFnType<T>,
) => {
  return (
    errors &&
    Object.keys(errors).forEach((key) => {
      setError?.(key as keyof T, {
        type: "server",
        message: errors[key],
      });
    })
  );
};

export const isValidPaymentMethodsSecondaryForm = (
  dirtyFields: Partial<{ [key in keyof OrdersCheckoutPaymentMethodSecondaryFormType]: boolean }>,
  errors: FieldErrors<OrdersCheckoutPaymentMethodSecondaryFormType>,
): boolean => {
  return !Object.keys(dirtyFields).find((k) => {
    return k in errors;
  });
};

export const getPaymentMethodsSecondaryFormPayload = (
  methods: OrdersCheckoutType["paymentMethodsSecondary"],
  dirtyFields: OrdersCheckoutPaymentMethodSecondaryUpdateType<boolean>,
  data: OrdersCheckoutPaymentMethodSecondaryUpdateType<number | string | null>,
): OrdersCheckoutType["paymentMethodsSecondary"] => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const field = Object.keys(data).reduce((acc, k) => {
    return k in dirtyFields ? k : acc;
  }, "");

  if (
    field === OrdersCheckoutPaymentMethodSecondaryMetaNormalizedNameEnum.paymentMethodsSecondary
  ) {
    return methods.map((method) => {
      method = { ...method, isActive: method.value === data?.[field] };
      return method;
    });
  }

  return methods.map((method) => {
    const metaKey = field as keyof OrdersCheckoutPaymentMethodSecondaryMetaType;
    if (!method.meta || !method.meta[metaKey]) {
      method = { ...method, isActive: false };
      return method;
    }

    const metaPayload = method.meta[metaKey];

    method = {
      ...method,
      isActive: true,
      meta: {
        ...method.meta,
        [metaKey]: {
          ...metaPayload,
          ...(metaPayload &&
            "currentAmount" in metaPayload && {
              currentAmount: {
                ...metaPayload.currentAmount,
                value: data[metaKey],
              },
            }),
          ...(metaPayload &&
            "number" in metaPayload && {
              number: data[metaKey],
            }),
        },
      },
    };

    return method;
  });
};

export const disabledPastPickerDate = (current: Dayjs): boolean => {
  return current && current.valueOf() < Number(dayjs().startOf("day"));
};

export const disabledPastPickerTime = (current: Dayjs | null) => {
  return {
    disabledHours: () =>
      !current || current.format(dateFormat) !== dayjs().format(dateFormat)
        ? []
        : [...Array(24).keys()].filter((h) => h < dayjs().hour()),
    disabledMinutes: () =>
      !current ||
      current.format(dateFormat) !== dayjs().format(dateFormat) ||
      current.hour() > dayjs().hour()
        ? []
        : [...Array(60).keys()].filter((h) => h < dayjs().minute()),
  };
};

export const disabledOutLimitPickerDate =
  (minDate?: Dayjs, maxDate?: Dayjs) =>
  (current: Dayjs): boolean => {
    const isBeforeMin = minDate ? current.isBefore(minDate.startOf("day")) : false;
    const isAfterMax = maxDate ? current.isAfter(maxDate.startOf("day")) : false;

    return current && (isBeforeMin || isAfterMax);
  };

export const disabledOutLimitPickerTime =
  (minTime?: Dayjs, maxTime?: Dayjs) => (current: Dayjs | null) => {
    if (!current) {
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
      };
    }

    const minutes = [...Array(60).keys()];
    const hours = [...Array(24).keys()];
    const currentDayStart = current.clone().startOf("day");
    const minDayStart = minTime?.clone().startOf("day");
    const currentDayEnd = current.clone().endOf("day");
    const maxDayEnd = maxTime?.clone().endOf("day");

    if (
      (minTime && minDayStart && currentDayStart.isBefore(minDayStart)) ||
      (maxTime && maxDayEnd && currentDayEnd.isAfter(maxDayEnd))
    ) {
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes,
      };
    }

    const conditionDay =
      (minTime && current.isSame(minTime, "day")) || (maxTime && current.isSame(maxTime, "day"));

    return {
      disabledHours: () =>
        !conditionDay
          ? []
          : hours.filter(
              (hour) => (maxTime && hour > maxTime.hour()) || (minTime && hour < minTime.hour()),
            ),
      disabledMinutes: () =>
        !conditionDay
          ? []
          : (minTime && current.hour() !== minTime?.hour()) ||
              (maxTime && current.hour() !== maxTime?.hour())
            ? []
            : minutes.filter(
                (minute) =>
                  (maxTime && minute > maxTime.minute()) || (minTime && minute < minTime.minute()),
              ),
    };
  };

export const getFieldLabel = (key: string, isPlaceholder?: boolean): string => {
  const entity = isPlaceholder ? "placeholder" : "label";
  return `inputs:${key}.${entity}`;
};
