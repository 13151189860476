// Core
import { cloneElement } from "react";
import cx from "classnames";
import { useRouter } from "next/router";

// Definitions
import { ModalProps as AntdModalProps } from "antd";

// Components
import { Modal as AntModal } from "antd";
import { Icon } from "components/ui/Icon";

// Utils
import st from "./Styles.module.less";
import { GET_ENUMS } from "utils/constants";
import { useGetPopupState } from "hooks/useGetPopupState";

import { getModalViewType, getModalWidth, modalTypes } from "./utils";

type ModalProps = AntdModalProps & {
  children: React.ReactElement;
  hasCommon?: boolean;
  hasSearch?: boolean;
  gutter?: "none" | "small" | "middle" | "large";
  autoWidth?: boolean;
  visible?: boolean;
  layoutType?: string;
  isFullScreen?: boolean;
  isCentered?: boolean;
  onVisible?: () => void;
  onClose?: () => void;
  onHidden?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export const Modal = (props: ModalProps) => {
  const {
    children,
    visible,
    onVisible,
    onHidden,
    onClose,
    isCentered,
    hasSearch,
    layoutType,
    zIndex = 1000,
    wrapClassName = "",
    gutter = "large",
    autoWidth = false,
  } = props;

  const router = useRouter();
  const { mountedComponent } = useGetPopupState();
  const userConsent = mountedComponent === GET_ENUMS.popup.userConsent;
  const trackingOrder =
    router.query.popup === GET_ENUMS.popup.trackParcel ||
    mountedComponent === GET_ENUMS.popup.trackParcel;
  const customerCar = layoutType === GET_ENUMS.popup.customerVehicleModal;

  const handleClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e?.preventDefault();
    onClose?.();
    onHidden?.(e);
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    handleClose(e);
    onHidden?.(e);
  };

  const modalViews = {
    [modalTypes.USER_CONSENT]: {
      type: modalTypes.USER_CONSENT,
      status: userConsent,
    },
    [modalTypes.SEARCH_NOTICE]: {
      type: modalTypes.SEARCH_NOTICE,
      status: hasSearch,
    },
    [modalTypes.TRACKING_ORDER]: {
      type: modalTypes.TRACKING_ORDER,
      status: trackingOrder,
    },
    [modalTypes.CUSTOMER_CAR]: {
      type: modalTypes.CUSTOMER_CAR,
      status: customerCar,
    },
  };
  const modalViewType = getModalViewType(modalViews);
  const modalWidth = getModalWidth(modalViewType, autoWidth);

  const modalStyle = cx(st.modal, {
    [st[`modal-gutter-${gutter}`]]: Boolean(gutter),
  });

  return (
    <AntModal
      className={modalStyle}
      width={modalWidth}
      open={visible}
      centered={isCentered ?? true}
      footer={null}
      destroyOnClose
      onOk={onVisible}
      onCancel={handleClose}
      closeIcon={<Icon name="ControlCloseModal" />}
      wrapClassName={wrapClassName}
      zIndex={zIndex}
    >
      {cloneElement(children, { onClose: handleCancel })}
    </AntModal>
  );
};
