// Core
import { mergeRight } from "ramda";
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import {
  EventPayloadSelectorCarType,
  EventSelectorCarDataType,
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  SelectorCarEventActionEnum,
  SelectorCarEventObjectEnum,
  EventsDataUnionType,
} from "client/utils/gtm/gtm.types";

class SelectorCarEvent extends AbstractGTMEventSegment<GTMTypesEnum.selector_car> {
  protected readonly segmentShape = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.car_id]: 0,
      [ParametersEnum.event_action]: SelectorCarEventActionEnum.car,
      [ParametersEnum.event_object]: SelectorCarEventObjectEnum.base,
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadSelectorCarType {
    const selectorCarData = data as EventSelectorCarDataType;
    const params = mergeRight(this.segmentShape[PayloadKeysEnum.parameters], selectorCarData);
    return {
      [PayloadKeysEnum.parameters]: params,
    };
  }
}

export const selectorCarEvent: SelectorCarEvent = new SelectorCarEvent();
