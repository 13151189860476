import { workingHoursDefaultTime } from "utils/constants";

export const workingHoursDefault = {
  days: [...(Array(7) as number[])].map((_, idx) => {
    return {
      id: idx + 1,
      isWorking: true,
      start: workingHoursDefaultTime.start,
      end: workingHoursDefaultTime.end,
      lunchBreakEnabled: false,
      lunchBreakStart: workingHoursDefaultTime.lunchBreakStart,
      lunchBreakEnd: workingHoursDefaultTime.lunchBreakEnd,
    };
  }),
};

export const productsDisplaySwitcherOptions = [
  { id: 1, label: "common:view.table", value: "table" },
  { id: 2, label: "common:view.grid", value: "grid" },
  { id: 3, label: "common:view.list", value: "list" },
];
