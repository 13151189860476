// Core
import variables from "./Styles.module.less";

export const modalTypes: {
  [key: string]: string;
} = {
  USER_CONSENT: "USER_CONSENT",
  SEARCH_NOTICE: "SEARCH_NOTICE",
  CUSTOMER_CAR: "CUSTOMER_CAR",
  TRACKING_ORDER: "TRACKING_ORDER",
};

export const getModalViewType = (obj: { [key: string]: { type: string; status?: boolean } }) => {
  const filtered = Object.values(obj).find((o) => o.status);
  return filtered && filtered.type ? filtered.type : "";
};

export const getModalWidth = (type?: string, autoWidth?: boolean): string => {
  if (autoWidth) {
    return variables.modalAutoWidth;
  }
  if (type && modalTypes[type] === modalTypes.USER_CONSENT) {
    return variables.modalCookiesWidth;
  }
  if (type && modalTypes[type] === modalTypes.SEARCH_NOTICE) {
    return variables.modalSearchWidth;
  }
  if (type && modalTypes[type] === modalTypes.CUSTOMER_CAR) {
    return variables.modalVehicleWidth;
  }
  if (type && modalTypes[type] === modalTypes.TRACKING_ORDER) {
    return variables.modalTrackWidth;
  }
  return variables.modalDefaultWidth;
};
