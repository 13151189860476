// Definitions
import { HttpResponse } from "models/Http";
import type {
  PersonalManagerRatingParamsType,
  PersonalManagerType,
  PersonalManagerCallBackType,
  PersonalManagerCallBackParamsType,
} from "bus/personal-manager/models";

// Utils
import { http } from "api/network-provider";

export const personalManagerService = Object.freeze({
  fetchProfilePersonalManager: () => {
    return http.get<HttpResponse<PersonalManagerType>>("/personal-manager");
  },
  createProfilePersonalManagerRating: (payload: PersonalManagerRatingParamsType) => {
    const { data } = payload;
    return http.post<HttpResponse<void>>("/personal-manager/rating", data);
  },
  personalManagerCallBack: (values: PersonalManagerCallBackParamsType) => {
    const data = values.data;
    return http.post<HttpResponse<PersonalManagerCallBackType>>(
      "/personal-manager/call-back",
      data,
    );
  },
});
