// Core
import { z } from "zod";

// Definitions
import type { StaticDataItemType } from "../bus/staticData/models";

export type RadioButtonItem = StaticDataItemType & {
  isSelected?: boolean;
};

export const criteriaItemSchema = z
  .object({
    id: z.number(),
    value: z.string(),
    label: z.string(),
    isSelected: z.boolean().optional(),
  })
  .strict();
export type CriteriaItem = z.infer<typeof criteriaItemSchema>;
