import { CountryCodesEnum } from "bus/profile/models";

const vatCountries: Array<string> = [CountryCodesEnum.DE, CountryCodesEnum.EN];

export const checkIsVatCountry = (country?: string) => {
  if (!country) return true;
  return vatCountries.includes(country.toLocaleUpperCase());
};

export const getVatSignByCountry = (country: string = CountryCodesEnum.EN, invert = false) => {
  const isVatCountry = checkIsVatCountry(country);
  if (!invert) {
    return isVatCountry ? "common:withVat" : "common:notVat";
  }
  return !isVatCountry ? "common:withVat" : "common:notVat";
};
