// Definitions
import type { SearchAutocompleteType } from "bus/search/models";
import type { ProductsType } from "client/core/products/models";

// Utils
import { initMetaPagination } from "utils/constants";

export const searchKeys = {
  products: "search/products",
  autocomplete: "search/autocomplete",
};

export type SearchStateType = {
  products: ProductsType;
  autocomplete: SearchAutocompleteType;
};

export const initialSearchState: SearchStateType = {
  products: {
    products: {
      items: [],
      meta: initMetaPagination,
    },
    filter: [],
  },
  autocomplete: {
    items: [],
  },
};
