// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  EventPayloadAddToCartType,
  EventAddToCartDataType,
  RouteNameEnum,
  EcommerceEnum,
  EcommerceItemsEnum,
  EventsDataUnionType,
} from "client/utils/gtm/gtm.types";

class AddToCartEvent extends AbstractGTMEventSegment<GTMTypesEnum.add_to_cart> {
  protected readonly segmentShape: EventPayloadAddToCartType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.shipping_date]: 0,
      [ParametersEnum.car_id]: ParametersEnum.free_search,
      [ParametersEnum.route_name]: RouteNameEnum.catalog,
    },
    [PayloadKeysEnum.ecommerce]: {
      [EcommerceEnum.currency]: "",
      [EcommerceEnum.value]: 0,
      [EcommerceEnum.items]: [],
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadAddToCartType {
    const { cart, product, route } = data as EventAddToCartDataType;
    const { currency, priceVat } = super.getPriceConfig(String(cart.totalPrice?.country));

    const segment = this.getSegment();
    segment[PayloadKeysEnum.parameters][ParametersEnum.shipping_date] = Number(cart.deliveryDate);
    segment[PayloadKeysEnum.parameters][ParametersEnum.route_name] = route;
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.currency] = String(currency);
    (cart?.tabs || []).forEach((tab) => {
      if (product.vehicleId && tab.vehicleId !== product.vehicleId) {
        return false;
      }
      tab.products.forEach((p) => {
        if (
          p.articleId === product.articleId &&
          segment[PayloadKeysEnum.ecommerce][EcommerceEnum.items].length === 0
        ) {
          const qty = Number(product.quantity || p.quantity?.value);
          const price = Number(priceVat ? p.priceVat?.value : p.priceNonVat?.value);
          segment[PayloadKeysEnum.ecommerce][EcommerceEnum.items].push({
            [EcommerceItemsEnum.item_id]: Number(p.articleId),
            [EcommerceItemsEnum.item_name]: String(p.name),
            [EcommerceItemsEnum.item_brand]: String(p.brand?.id),
            [EcommerceItemsEnum.item_category]: p.genericId,
            [EcommerceItemsEnum.price]: price,
            [EcommerceItemsEnum.quantity]: qty,
            [EcommerceItemsEnum.item_variant]: p.isVinCompatibility,
          });
          segment[PayloadKeysEnum.ecommerce][EcommerceEnum.value] = price * qty;

          if (tab.vehicleId) {
            segment[PayloadKeysEnum.parameters][ParametersEnum.car_id] = String(tab.vehicleId);
          }
        }
      });
    });

    return segment;
  }
}

export const addToCartEvent: AddToCartEvent = new AddToCartEvent();
