// Core
import { useCallback } from "react";
import { useRouter } from "next/router";

// Hooks
import { useModalContext } from "hooks/useModalContext";
import { useAuth } from "bus/auth/hooks/useAuth";

// Utils
import { queryClient } from "init/queryClient";
import { getHttpErrorMessageWithTranslations } from "utils/notifications-message";
import { notificationService } from "utils/notifications";
import { useApollo } from "init/apollo-store";
import { book } from "init/book";
import { useAuthLogoutMutation } from "bus/auth/mutations/authLogoutMutation";

type OnLogoutCbType = (values: { type?: "modal" }) => void;
type UseLogoutType = {
  loadingLogout: boolean;
  onLogout: OnLogoutCbType;
};

export const useLogout = (): UseLogoutType => {
  const { setIsModal, setOnPageModal } = useModalContext();
  const apolloClient = useApollo();
  const { loadingAuthLogout, onLogoutAuth } = useAuthLogoutMutation();
  const router = useRouter();
  const session = useAuth();

  const onLogout = useCallback<OnLogoutCbType>((values) => {
    void (async () => {
      try {
        const [authLogoutErr] = await onLogoutAuth();
        if (authLogoutErr) {
          throw new Error("Flow auth logout has error");
        }
        if (values?.type === "modal") {
          setIsModal(false);
        }

        session.onClear();
        await router.replace({
          pathname: book.signin,
        });
        queryClient.clear();
        await apolloClient.clearStore();
      } catch (error) {
        notificationService.showError(getHttpErrorMessageWithTranslations());
      } finally {
        setOnPageModal("user-signout", false);
      }
    })();
  }, []);

  return {
    loadingLogout: loadingAuthLogout,
    onLogout,
  };
};
