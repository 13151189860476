// Core
import { useCallback, useState } from "react";
import { useRouter } from "next/router";

// Definitions
import type { SearchBarType } from "bus/search/models";
import { SearchTypesEnum } from "bus/search/models";
import { FormSubmitParams } from "models/Forms";
import { VehicleTabTypeEnum } from "bus/vehicleTabs/models";
import { GTMTypesEnum, ParametersEnum } from "client/utils/gtm/gtm.types";

// Domains
import { useTabsCreate } from "bus/vehicleTabs/hooks/useTabsCreate";
import { useTabsFetchQuery } from "bus/vehicleTabs/hooks/useTabsFetchQuery";
import { useTabsUpdate } from "bus/vehicleTabs/hooks/useTabsUpdate";

// Utils
import { book } from "init/book";
import { getExistingSearchTab } from "utils/vehicle-tabs";
import { VEHICLE_TAB_INN_PAGE_INIT } from "utils/vehicle-tabs";
import { gtmService } from "client/utils/gtm";

type OnTabSearchCreateType = (data: FormSubmitParams<SearchBarType>) => void;

type UseTabSearchCreateType = {
  loadingTabSearchCreate: boolean;
  onTabSearchCreate: OnTabSearchCreateType;
};

export const useTabSearchCreate = (): UseTabSearchCreateType => {
  const router = useRouter();
  const tabsUpdate = useTabsUpdate();
  const tabsCreate = useTabsCreate();
  const { onFetchTabs, tabs } = useTabsFetchQuery();

  const [loadingTabSearchCreate, setLoadingTabSearchCreate] = useState<boolean>(false);

  const onTabSearchCreate = useCallback<OnTabSearchCreateType>(
    (data) => {
      const { search } = data.values;
      void (async () => {
        try {
          const { type, entity } = data.values;
          setLoadingTabSearchCreate(true);
          const vehicleTabs = router.asPath !== book.catalog ? await onFetchTabs() : tabs;
          const currentTab = vehicleTabs.find((tab) => tab.isActive);
          const existSearchTab = getExistingSearchTab(vehicleTabs);
          const promisesArray = [];

          const innerPage =
            type === SearchTypesEnum.oem
              ? {
                  oem: {
                    ...VEHICLE_TAB_INN_PAGE_INIT.oem,
                    ...existSearchTab?.innerPage?.oem,
                    params: null,
                    active: true,
                    id: entity,
                  },
                }
              : type === SearchTypesEnum.product
                ? {
                    productDetails: {
                      ...VEHICLE_TAB_INN_PAGE_INIT.productDetails,
                      ...existSearchTab?.innerPage?.productDetails,
                      active: true,
                      id: entity,
                    },
                  }
                : {
                    search: {
                      ...VEHICLE_TAB_INN_PAGE_INIT.search,
                      ...existSearchTab?.innerPage?.search,
                      params: null,
                      active: true,
                    },
                  };

          if (existSearchTab && currentTab) {
            if (existSearchTab.id !== currentTab.id) {
              promisesArray.push(tabsUpdate.onUpdateTab({ id: currentTab.id, isActive: false }));
            }

            promisesArray.push(
              tabsUpdate.onUpdateTab({
                id: existSearchTab.id,
                isActive: true,
                type: VehicleTabTypeEnum.search,
                vehicleId: null,
                regNumber: "",
                search: data.values,
                innerPage,
              }),
            );
          }
          if (!existSearchTab) {
            if (currentTab) {
              promisesArray.push(tabsUpdate.onUpdateTab({ id: currentTab.id, isActive: false }));
            }
            const tabCreateOptions = {
              type: VehicleTabTypeEnum.search,
              isActive: true,
              search: data.values,
              innerPage,
            };
            promisesArray.push(tabsCreate.onCreateTab(tabCreateOptions));
          }

          await Promise.all(promisesArray);
          if (router.asPath !== book.catalog) {
            await router.push(book.catalog);
          }
          search && gtmService?.event(GTMTypesEnum.search, { [ParametersEnum.query]: search });
        } catch (err) {
          console.warn(err);
        } finally {
          setLoadingTabSearchCreate(false);
        }
      })();
    },
    [tabsUpdate, tabsCreate, router],
  );

  return {
    loadingTabSearchCreate,
    onTabSearchCreate,
  };
};
