// Definitions
import type { HttpResponse } from "models/Http";
import type { CartProductParamsType } from "bus/cart/models";
import type {
  CartPreviewType,
  CartParamsType,
  CartRepairEstimateType,
  CartPreviewRepairEstimateCreateParamsType,
} from "bus/cart/models";

// Utils
import { http } from "api/network-provider";

export const cartService = Object.freeze({
  fetchCartPreview: () => {
    return http.get<HttpResponse<CartPreviewType>>("/cart/preview");
  },
  updateCartPreview: (payload: CartParamsType) => {
    const { data } = payload;
    return http.put<HttpResponse<CartPreviewType>>("/cart/preview", data);
  },
  removeCartPreviewProduct: (payload: CartProductParamsType) => {
    const id = payload?.id;
    return http.delete<HttpResponse<void>>(`/cart-products/${String(id)}`);
  },
  removeCartProduct: (payload: CartProductParamsType) => {
    const id = payload?.id;
    return http.delete<HttpResponse<void>>(`/cart-products/${String(id)}`);
  },
  updateCartPreviewProduct: (payload: CartProductParamsType) => {
    const { data, id } = payload;
    return http.put<HttpResponse<CartPreviewType>>(`/cart-products/${String(id)}`, data);
  },
  updateCartPreviewVehicles: (payload: CartParamsType) => {
    const { data, id } = payload;
    return http.put<HttpResponse<CartPreviewType>>(`/cart-vehicles/${String(id)}`, data);
  },
  removeCartPreviewVehicle: (payload: CartParamsType) => {
    const id = payload?.id;
    return http.delete<HttpResponse<void>>(`/cart-vehicles/${String(id)}`);
  },
  createCartPreviewRepairEstimate: (payload: CartPreviewRepairEstimateCreateParamsType) => {
    const { data, id } = payload;
    return http.post<HttpResponse<CartRepairEstimateType>>(
      `/cart-vehicles/${String(id)}/repair-estimates`,
      data,
    );
  },
  updateCartPreviewProductAlternative: (payload: CartProductParamsType) => {
    const { data, id } = payload;
    return http.put<HttpResponse<CartPreviewType>>(
      `/cart-products/${String(id)}/alternative`,
      data,
    );
  },
});
