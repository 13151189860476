// Core
import { useContext } from "react";

// Definitions
import { BaseQueryKey } from "models/Base";
import { CartPreviewType } from "bus/cart/models";

// Domains
import { cartKeys, initialCartState } from "bus/cart/store";

// Utils
import { useQueryAsync } from "utils/react-query/hooks/use-query-async";
import { cartService } from "bus/cart/service";
import { CartContext } from "contexts/cart";
import { gtmService } from "client/utils/gtm";
import { GTMTypesEnum } from "client/utils/gtm/gtm.types";

type UseCartPreviewFetchType = {
  loadingCartPreviewFetch: boolean;
  cartPreview: CartPreviewType;
};

export const useCartPreviewFetch = (page?: "preview"): UseCartPreviewFetchType => {
  const { onToggleAvailableCart } = useContext(CartContext);

  const fetchCartProps = useQueryAsync<CartPreviewType, CartPreviewType, BaseQueryKey>({
    key: [cartKeys.preview],
    name: "fetchCartPreview",
    fetcher: cartService.fetchCartPreview,
    options: {
      refetchOnMount: "always",
      onSuccess: (cartPreview) => {
        const hasCartPreviewVehicles = !cartPreview?.tabs?.length;
        onToggleAvailableCart(hasCartPreviewVehicles);
        page &&
          gtmService?.event(GTMTypesEnum.begin_checkout, {
            cart: cartPreview,
          });
      },
    },
  });

  return {
    loadingCartPreviewFetch: fetchCartProps.isLoading || fetchCartProps.isRefetching,
    cartPreview: fetchCartProps.data || initialCartState.preview,
  };
};
