// Core
import { default as NextLink } from "next/link";

// Definitions
import type { LinkProps as LinkNextProps } from "next/link";
import type { LinkProps as LinkUIProps } from "components/ui/Link";

// Components
import { Link as LinkUI } from "components/ui/Link";

export type LinkProps = LinkNextProps &
  Omit<LinkUIProps, "href"> & {
    asNextLink?: boolean;
  };

export const Link = (props: LinkProps) => {
  const {
    // ui props
    children,
    type,
    alignmentVertical,
    alignmentHorizontal,
    size,
    font,
    fontWeight,
    color,
    bgColor,
    borderColor,
    icon,
    blank,
    box,
    style,
    decoration,
    noStyles,
    padding,
    disabled,
    onClick,
    // router props
    asNextLink = true,
    testId,
    shallow = false,
    passHref = true,
    scroll = true,

    href,
    className,
    ...rest
  } = props;

  const uiProps = {
    ...(type && { type }),
    ...(alignmentVertical && { alignmentVertical }),
    ...(alignmentHorizontal && { alignmentHorizontal }),
    ...(size && { size }),
    ...(box && { box }),
    ...(padding && { padding }),
    ...(font && { font }),
    ...(color && { color }),
    ...(bgColor && { bgColor }),
    ...(decoration && { decoration }),
    ...(fontWeight && { fontWeight }),
    ...(icon && { icon }),
    ...(blank && { blank }),
    ...(style && { style }),
    ...(noStyles && { noStyles }),
    ...(disabled && { disabled }),
    ...(borderColor && { borderColor }),
    ...(onClick && { onClick }),
  };

  const linkJSX = (
    <LinkUI {...uiProps} testId={testId} href={String(href)} className={className}>
      {children}
    </LinkUI>
  );

  return asNextLink ? (
    <NextLink
      data-test-id={testId}
      href={href}
      shallow={shallow}
      className={className}
      passHref={passHref}
      scroll={scroll}
      legacyBehavior
      {...rest}
    >
      {linkJSX}
    </NextLink>
  ) : (
    linkJSX
  );
};
