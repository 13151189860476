// Core
import { ReactNode } from "react";
import cx from "classnames";

// Components
import { Form, Skeleton } from "antd";
import { InputLabel } from "../InputLabel";
import { Link, LinkProps } from "../../Link";

// Definitions
import { FormItemProps, ValidateStatus } from "antd/lib/form/FormItem";
import { SkeletonInputProps } from "antd/lib/skeleton/Input";

// Utils
import st from "./Styles.module.less";

type InputFormType = FormItemProps & {
  children: ReactNode;
  id?: string;
  name?: string | Array<string>;
  label?: string;
  labelRequired?: boolean;
  loading?: boolean;
  validateStatus?: ValidateStatus;
  type?:
    | "base"
    | "no-gutter"
    | "no-last"
    | "small"
    | "middle"
    | "search-bar"
    | "header"
    | "footer"
    | "";
  skeletonType?: "base" | "phone-number" | "counter";
  skeletonSize?: SkeletonInputProps["size"];
  skeletonRows?: number;
  noStyle?: boolean;
  linkProps?: { text: string; shallow?: boolean } & LinkProps;
  linkComponent?: React.ElementType;
  testId?: string;
};

export const InputForm = (props: InputFormType) => {
  const {
    children,
    label,
    type = "base",
    skeletonType = "base",
    skeletonRows = 1,
    skeletonSize = "large",
    labelRequired = false,
    loading = false,
    hasFeedback = false,
    linkProps,
    testId,
    linkComponent: LabelLink = Link,
    ...rest
  } = props;

  const TYPE_INPUT_FORM = st[`item-type-${type}`];
  const TYPE_INPUT_SKELETON = st[`skeleton-${skeletonType}`];

  const itemStyle = cx(st.item, {
    [TYPE_INPUT_FORM]: !!TYPE_INPUT_FORM,
  });
  const skeletonStyle = cx(st.skeleton, {
    [TYPE_INPUT_SKELETON]: !!TYPE_INPUT_SKELETON,
  });

  return (
    <Form.Item
      {...rest}
      data-test-id={testId}
      className={itemStyle}
      label={
        label ? (
          <InputLabel
            required={labelRequired}
            text={label}
            linkProps={linkProps}
            linkComponent={LabelLink}
          />
        ) : null
      }
      hasFeedback={hasFeedback}
    >
      {loading ? (
        <>
          {[...Array(skeletonRows).keys()].map((i) => (
            <Skeleton.Input key={i} className={skeletonStyle} size={skeletonSize} active />
          ))}
        </>
      ) : (
        children
      )}
    </Form.Item>
  );
};
