import { CountryCodesEnum } from "bus/profile/models";
import { globalBook } from "init/book";

type SurveyNPSType = {
  link: { [key in CountryCodesEnum]: string };
  targetDate: string;
};

export const surveyNPS: SurveyNPSType = {
  link: {
    AT: globalBook.nps.at,
    DE: globalBook.nps.de,
    EN: globalBook.nps.en,
    FR: globalBook.nps.fr,
    NL: globalBook.nps.nl,
  },
  targetDate: "2024-04-02",
};

export const getSurveyNPSLink = (customerID?: number | null, countryCode?: CountryCodesEnum) => {
  if (customerID && countryCode)
    return `${surveyNPS.link[countryCode]}?usp=pp_url&entry.959627652=${customerID}`;

  return surveyNPS.link[countryCode || CountryCodesEnum.EN];
};
