// Definitions
import type { BaseDataListType, BaseEmptyType } from "models/Base";
import type { HttpResponse } from "models/Http";
import type { VehicleTab, VehicleTabParamsDataType } from "bus/vehicleTabs/models";

// Utils
import { http } from "api/network-provider";

export const vehicleTabsService = Object.freeze({
  fetchTabs: () => {
    return http.get<HttpResponse<BaseDataListType<VehicleTab>>>("/vehicle-tabs");
  },
  createTab: (values: VehicleTabParamsDataType) => {
    const { data } = values;
    return http.post<HttpResponse<VehicleTab>>("/vehicle-tabs", data);
  },
  closeTab: (values: VehicleTabParamsDataType) => {
    const tabId = values?.data?.id;
    return http.delete<HttpResponse<BaseEmptyType>>(`/vehicle-tabs/${String(tabId)}`);
  },
  updateTab: (values: VehicleTabParamsDataType) => {
    const tabId = values?.data.id;
    const { data } = values;
    return http.patch<HttpResponse<VehicleTab>>(String(`/vehicle-tabs/${String(tabId)}`), data);
  },
});
