// Core
import { useContext } from "react";
import { useTranslation } from "next-i18next";

// Components
import { BannerSurvey } from "components/common/BannerSurvey";
import { BannerSales } from "components/common//BannerSales";
import { HeaderBannerSpecial } from "components/common/HeaderBanner/HeaderBannerSpecial";

// Context
import { PermissionContext } from "contexts/usePermissionContext";

// Utils
import { PermissionAct } from "utils/permission";

type HeaderBannerProps = {
  country: string;
};

const headerBanners: Record<string, JSX.Element> = {
  FR: <BannerSurvey />,
  DE: <BannerSurvey />,
  EN: <BannerSales />,
  AT: <BannerSurvey />,
  NL: <BannerSurvey />,
};

export const HeaderBanner = (props: HeaderBannerProps) => {
  const { t } = useTranslation("common");
  const ability = useContext(PermissionContext);
  const agentOff = ability.can(PermissionAct.READ, "service_agent_holidays_banner_top");

  if (agentOff) {
    return <HeaderBannerSpecial message={t("common:serviceAgentHoliday")} />;
  }

  return headerBanners[props.country];
};
