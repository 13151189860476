// Core
import { MouseEvent } from "react";
import { useTranslation } from "next-i18next";

// Components
import { Col, Row, Skeleton } from "antd";
import { Image } from "components/ui/Image";
import { Icon } from "components/ui/Icon";
import { Text } from "components/ui/Typography/Text";
import { Title } from "components/ui/Typography/Title";

// Domains
import { useProfilePersonalManagerFetch } from "bus/personal-manager/hooks/useProfilePersonalManagerFetch";
import { useProfileContext } from "hooks/useProfileContext";

// Definitions
import { UserCountryCodesEnum } from "bus/user/models";

// Utils
import st from "./Styles.module.less";
import { rowGutters } from "utils/constants";
import { default as avatarDefault } from "assets/images/avatar-default.jpg";
import { getFormatPhoneNumber } from "utils/getFormatPhoneNumber";

export const PopoverPersonalManagerNavbar = () => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const { personalManager, loadingPersonalManagerFetch } = useProfilePersonalManagerFetch();

  const onClickContainer = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const countryCode = profile?.countryCode?.toLocaleLowerCase() as UserCountryCodesEnum;

  const fullName =
    personalManager?.firstName || personalManager?.lastName
      ? `${String(personalManager?.firstName)} ${String(personalManager?.lastName)}`
      : t("common:personalManager.noName");
  const description =
    personalManager?.firstName || personalManager?.lastName
      ? t("common:personalManager.desc")
      : t("common:personalManager.noData");
  const phone =
    personalManager?.phone && profile?.countryCode
      ? getFormatPhoneNumber(String(personalManager?.phone), countryCode)
      : t("common:personalManager.noPhone");
  const email = personalManager?.email ?? t("common:personalManager.noEmail");
  const photo = personalManager?.photo ?? avatarDefault.src;

  return (
    <div className={st["popover-support"]} onClick={onClickContainer}>
      {loadingPersonalManagerFetch ? (
        <Skeleton avatar={{ size: 64 }} paragraph={{ rows: 3 }} active />
      ) : (
        <Row gutter={rowGutters}>
          <Col span={5}>
            <div className={st.image}>
              <Image src={photo} alt={fullName} objectFit="contain" width={64} height={64} />
            </div>
          </Col>
          <Col span={19}>
            <div className={st.content}>
              <div className={st.title}>
                <Title tag="h5" size="16">
                  {fullName}
                </Title>
              </div>

              <div className={st.desc}>
                <Text color="gray-700" size="14">
                  {description}
                </Text>
              </div>

              <ul className={st.info}>
                <li className={st["info-item"]}>
                  <Icon name="Envelop" className={st.icon} />
                  <span>{email}</span>
                </li>
                <li className={st["info-item"]}>
                  <Icon name="Phone" className={st.icon} size="mini" />
                  <span>{phone}</span>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
