// Components
import { Tooltip as TooltipAntd } from "antd";
import cx from "classnames";

// Definitions
import type { TooltipProps as AntTooltipProps } from "antd";

// Utils
import st from "./Styles.module.less";

export type TooltipProps = AntTooltipProps & {
  gutter?: "small" | "middle" | "large";
  type?: "primary" | "secondary";
  color?: "default" | "primary" | "secondary";
  noArrow?: boolean;
};

export const Tooltip = (props: TooltipProps) => {
  const {
    children,
    className,
    gutter = "middle",
    type,
    color = "default",
    noArrow,
    ...rest
  } = props;

  const tooltipStyle = cx(
    st.tooltip,
    {
      [st["tooltip-no-arrow"]]: Boolean(noArrow),
      [st[`tooltip-gutter-${gutter}`]]: Boolean(gutter),
      [st[`tooltip-type-${String(type)}`]]: Boolean(type),
      [st[`tooltip-color-${color}`]]: Boolean(color),
    },
    className,
  );

  return (
    <TooltipAntd overlayClassName={tooltipStyle} {...rest}>
      {children}
    </TooltipAntd>
  );
};
