// Core
import { z } from "zod";

// Definitions
import type { HttpRequestParams } from "../../models/Http";
import type { BaseDataListType } from "../../models/Base";
import { staticDataItemSchema } from "../staticData/models";
import { imageSchema } from "../../models/Image";
import { FilterProductsParamsType } from "../../models/Filter";
import {
  OrderType,
  orderQuantitySchema,
  ordersProductImageSchema,
} from "../../client/core/orders/models";

export enum OrderProductEnum {
  id = "id",
  createdAt = "createdAt",
  articleNo = "articleNo",
  images = "images",
  name = "name",
  quantity = "quantity",
  priceNonVat = "priceNonVat",
  vatPrice = "vatPrice",
  priceRecommended = "priceRecommended",
  priceTotal = "priceTotal",
  margin = "margin",
  pledge = "pledge",
  promotions = "promotions",
  "vehicleName" = "vehicleName",
}

export enum OrderCheckoutProductEnum {
  id = "id",
  createdAt = "createdAt",
  articleNo = "articleNo",
  images = "images",
  name = "name",
  quantity = "quantity",
  priceNonVat = "priceNonVat",
  priceVat = "priceVat",
  priceRecommended = "priceRecommended",
  priceTotalVat = "priceTotalVat",
  priceTotalNonVat = "priceTotalNonVat",
  margin = "margin",
  pledge = "pledge",
  promotions = "promotions",
  "vehicleName" = "vehicleName",
}

export enum OrdersStatusEnum {
  isStatusNew = "isStatusNew",
  isStatusAssembled = "isStatusAssembled",
  isStatusSent = "isStatusSent",
  isStatusTest = "isStatusTest",
  isStatusDelivered = "isStatusDelivered",
  isStatusCanceled = "isStatusCanceled",
}

enum OrdersDeliveryStatusEnum {
  standard = "standard",
  silver = "silver",
  gold = "gold",
  platinum = "platinum",
}

export enum OrdersTabSelectEnum {
  empty = "empty",
  all = "all",
  partially = "partially",
}

export enum OrdersCheckoutMethodsPrimaryEnum {
  "sepa_xpollens" = "PaymentMethodSepa",
  "mondu" = "PaymentMethodMondu",
}

export enum OrdersCheckoutMethodsPrimaryAuthTypeEnum {
  sepaXpollens = "sepa_xpollens",
  mondu = "mondu",
}

export enum OrdersProductPromoLabelValueEnum {
  empty = "",
  default = "items-in-stock",
  hot = "items-left",
  delivery = "delivery-in-hours",
  sale = "sale",
}

export enum OrdersCheckoutPaymentMethodSecondaryMetaNormalizedNameEnum {
  empty = "empty",
  bonus = "bonus",
  coupon = "coupon",
  paymentMethodsSecondary = "paymentMethodsSecondary",
}

export enum OrdersCheckoutOrderErrorsEnum {
  "orderNotCreated" = "orderNotCreated",
  "orderNotFound" = "orderNotFound",
  "basketEmptyProducts" = "basketEmptyProducts",
  "addressValidation" = "addressValidation",
  "companyValidation" = "companyValidation",
  "mandateValidation" = "mandateValidation",
}

export const ordersPriceSchema = z
  .object({
    value: z.number().nullable(),
    country: z.string(),
  })
  .strict()
  .nullable();

export enum OrderPaymentStatusEnum {
  paid = "paid",
  waiting = "waiting_for_payment",
  received = "no_payment_received",
}

const cartProductPromoItemSchema = z
  .object({
    id: z.number().nullable(),
    value: z.enum([
      OrdersProductPromoLabelValueEnum.empty,
      OrdersProductPromoLabelValueEnum.default,
      OrdersProductPromoLabelValueEnum.hot,
      OrdersProductPromoLabelValueEnum.delivery,
      OrdersProductPromoLabelValueEnum.sale,
    ]),
    label: z.string(),
    image: imageSchema.nullable(),
  })
  .strict();

export const productPromoSchema = z
  .object({
    prime: cartProductPromoItemSchema.nullable(),
    general: cartProductPromoItemSchema.array().optional(),
    stock: cartProductPromoItemSchema.array().optional(),
  })
  .strict();

export type OrderListType = BaseDataListType<OrderType>;

export type OrderParamsType = HttpRequestParams<OrderType>;

export const orderInvoiceSchema = z.union([z.custom<Blob>(), z.string()]);
export type OrderInvoiceType = z.infer<typeof orderInvoiceSchema>;

export const ordersCheckoutDeliveryScheme = z
  .object({
    firstName: z.string(),
    companyName: z.string(),
    surname: z.string(),
    street: z.string(),
    houseNumber: z.string(),
    city: z.string(),
    index: z.string().optional(),
    country: staticDataItemSchema.nullable(),
    additionalAddress: z.string().optional(),
    phonePrefix: staticDataItemSchema.nullable(),
    phoneNumber: z.string(),
  })
  .strict();

const PaymentMethodPrimaryFormSchema = z
  .object({
    paymentMethodPrimary: z.string().optional(),
  })
  .strict();
export type PaymentMethodPrimaryFormType = z.infer<typeof PaymentMethodPrimaryFormSchema>;

export const ordersCheckoutPaymentMethodSecondaryMetaSchema = z.object({
  empty: z
    .object({ label: z.string().or(z.null()) })
    .nullable()
    .optional(),
  bonus: z
    .object({
      label: z.string().or(z.null()),
      currentAmount: ordersPriceSchema.nullable(),
      totalAmount: ordersPriceSchema.nullable(),
    })
    .nullable(),
  coupon: z
    .object({
      label: z.string().or(z.null()),
      number: z.string().or(z.null()),
    })
    .nullable(),
});

export const ordersCheckoutDeliveryInfoScheme = z
  .object({
    freeDeliveryAmount: ordersPriceSchema,
    status: z.enum([
      OrdersDeliveryStatusEnum.standard,
      OrdersDeliveryStatusEnum.silver,
      OrdersDeliveryStatusEnum.gold,
      OrdersDeliveryStatusEnum.platinum,
    ]),
  })
  .strict();

export const ordersCheckoutPaymentMethodPrimaryMetaSchema = z
  .object({
    description: z.string(),
    authType: z.enum([
      OrdersCheckoutMethodsPrimaryAuthTypeEnum.sepaXpollens,
      OrdersCheckoutMethodsPrimaryAuthTypeEnum.mondu,
    ]),
    isAuth: z.boolean(),
  })
  .strict();

export const ordersCheckoutMethodPrimarySchema = z
  .object({
    id: z.number().nullable(),
    value: z.string(),
    label: z.string(),
    isActive: z.boolean(),
    meta: ordersCheckoutPaymentMethodPrimaryMetaSchema.nullable(),
  })
  .strict();

export const ordersCheckoutPaymentMethodSecondarySchema = z
  .object({
    id: z.number().nullable(),
    value: z.string(),
    label: z.string(),
    isActive: z.boolean(),
    meta: ordersCheckoutPaymentMethodSecondaryMetaSchema.nullable(),
  })
  .strict();

export const OrdersCheckoutPaymentMethodSecondaryMetaNormalizedSchema = z
  .object({
    name: z.enum([
      OrdersCheckoutPaymentMethodSecondaryMetaNormalizedNameEnum.empty,
      OrdersCheckoutPaymentMethodSecondaryMetaNormalizedNameEnum.bonus,
      OrdersCheckoutPaymentMethodSecondaryMetaNormalizedNameEnum.coupon,
      OrdersCheckoutPaymentMethodSecondaryMetaNormalizedNameEnum.paymentMethodsSecondary,
    ]),
    userInput: z.string(),
    total: z.string(),
    label: z.string(),
  })
  .strict();

export const ordersCheckoutPaymentMethodSecondaryFormSchema = z
  .object({
    paymentMethodsSecondary: z.string().or(z.null()),
    coupon: z.string().optional(),
    bonus: z.number().optional(),
    empty: z.string().optional(),
  })
  .strict();

export const ordersTabProductScheme = z
  .object({
    id: z.number(),
    genericId: z.number(),
    articleId: z.number().nullable(),
    articleNo: z.string(),
    isActive: z.boolean(),
    isAvailable: z.boolean(),
    quantity: orderQuantitySchema.nullable(),
    images: ordersProductImageSchema.nullable(),
    vatPrice: ordersPriceSchema,
    vatPercentage: z.number().nullable(),
    deliveryAt: z.number().nullable(),
    priceRecommended: ordersPriceSchema,
    priceNonVat: ordersPriceSchema,
    priceVat: ordersPriceSchema,
    priceTotalNonVat: ordersPriceSchema,
    priceTotalVat: ordersPriceSchema,
    margin: z.number().nullable(),
    name: z.string(),
    brand: z.object({
      id: z.number().nullable(),
      name: z.string(),
      image: imageSchema,
    }),
    vehicleName: z.string(),
    pledge: ordersPriceSchema,
    promotions: productPromoSchema.nullable(),
    isVinCompatibility: z.boolean(),
  })
  .strict();

export const ordersCheckoutScheme = z
  .object({
    totalPrice: ordersPriceSchema,
    totalPriceVat: ordersPriceSchema,
    vatPrice: ordersPriceSchema,
    vatPercentage: z.number().nullable(),
    subtotalPrice: ordersPriceSchema,
    deliveryPrice: ordersPriceSchema,
    bonusPrice: ordersPriceSchema,
    couponPrice: ordersPriceSchema,
    totalPledgePrice: ordersPriceSchema,
    deliveryDate: z.number().nullable(),
    deliveryInfo: ordersCheckoutDeliveryInfoScheme.nullable(),
    products: z.array(ordersTabProductScheme),
    paymentMethodsPrimary: z.array(ordersCheckoutMethodPrimarySchema),
    paymentMethodsSecondary: z.array(ordersCheckoutPaymentMethodSecondarySchema),
    checkoutAvailable: z.boolean(),
  })
  .strict();

export const ordersCheckoutConfigScheme = z
  .object({
    freeDeliveryStatuses: z.array(z.string()),
    defaultFreeDeliveryThreshold: z.number(),
  })
  .strict();

export const ordersCheckoutDeliveryUpdateMutationScheme = z
  .object({
    data: ordersCheckoutDeliveryScheme,
  })
  .strict();

export const ordersParamsMetaScheme = z
  .object({
    kmtxSync: z.string().optional(),
  })
  .strict();

export type OrdersCheckoutPaymentMethodSecondaryMetaNormalized = z.infer<
  typeof OrdersCheckoutPaymentMethodSecondaryMetaNormalizedSchema
>;
export type OrdersCheckoutPaymentMethodSecondaryFormType = z.infer<
  typeof ordersCheckoutPaymentMethodSecondaryFormSchema
>;
export type OrdersCheckoutPaymentMethodSecondaryUpdateType<T = string> = Partial<{
  [key in keyof OrdersCheckoutPaymentMethodSecondaryFormType]: T;
}>;
export type OrdersCheckoutPaymentMethodSecondaryMetaType = z.infer<
  typeof ordersCheckoutPaymentMethodSecondaryMetaSchema
>;
export type OrdersCheckoutDeliveryInfoType = z.infer<typeof ordersCheckoutDeliveryInfoScheme>;
export type OrdersCheckoutPaymentMethodSecondaryType = z.infer<
  typeof ordersCheckoutPaymentMethodSecondarySchema
>;
export type OrdersCheckoutPaymentMethodPrimaryMetaType = z.infer<
  typeof ordersCheckoutPaymentMethodPrimaryMetaSchema
>;
export type OrdersCheckoutPaymentMethodMethodPrimaryType = z.infer<
  typeof ordersCheckoutMethodPrimarySchema
>;
export type OrdersCheckoutType = z.infer<typeof ordersCheckoutScheme>;
export type OrdersCheckoutConfigType = z.infer<typeof ordersCheckoutConfigScheme>;
export type OrdersCheckoutDeliveryType = z.infer<typeof ordersCheckoutDeliveryScheme>;
export type OrdersCheckoutDeliveryUpdateMutationExecute = z.infer<
  typeof ordersCheckoutDeliveryUpdateMutationScheme
>;
export type OrdersParamsMetaType = z.infer<typeof ordersParamsMetaScheme>;
export type OrdersTabProductsType = z.infer<typeof ordersTabProductScheme>;

export type OrdersCheckoutSecondaryPriceValueType = {
  [OrdersCheckoutPaymentMethodSecondaryMetaNormalizedNameEnum.bonus]: number;
};
export type OrdersCheckoutSecondaryMethodsUpdateType<T = string> = Partial<{
  [key in keyof OrdersCheckoutPaymentMethodSecondaryFormType]: T;
}>;

export type OrdersCheckoutOrderCreate = {
  orderId: number;
  redirectUrl: string;
};

export type OrdersCheckoutQueryParamsType = {
  entityId?: string;
  entityValue?: string;
};

export type OrdersCheckoutParamsType = {
  meta?: OrdersParamsMetaType;
  params?: OrdersCheckoutQueryParamsType;
  data?: Partial<OrdersCheckoutType>;
};

export type OrdersCheckoutOrderCreatePayload = {
  monduRedirectUrls: {
    success: string;
    cancel: string;
    decline: string;
  };
};

export type OrdersCheckoutOrderCreateParamsType = {
  meta?: OrdersParamsMetaType;
  params?: OrdersCheckoutQueryParamsType;
  data?: OrdersCheckoutOrderCreatePayload;
};

export type OrdersProductParamsType = {
  id: number | string;
  params?: FilterProductsParamsType & {
    makerId?: number;
    modelId?: number;
  };
};

const orderProductPromoItemSchema = z
  .object({
    id: z.number().nullable(),
    value: z.enum(["empty", "default", "hot", "delivery"]),
    label: z.string(),
    image: imageSchema.nullable(),
  })
  .strict();

export const orderProductPromoSchema = z
  .object({
    prime: orderProductPromoItemSchema.nullable(),
    general: orderProductPromoItemSchema.array().optional(),
    stock: orderProductPromoItemSchema.array().optional(),
  })
  .strict();

export type OrderProductPromotionsType = z.infer<typeof orderProductPromoSchema>;
