// Core
import type { BaseDataListType } from "models/Base";
import type { FilterParamsType } from "models/Filter";
import type { SearchBarType } from "bus/search/models";
import type { SelectItem } from "components/ui/FormFields/InputSelect/InputSelect.types";

export enum VehicleTabTypeEnum {
  new = 1,
  selector = 2,
  regNumber = 3,
  search = 4,
}

export type VehicleTabInnerPageParamsType = Record<string, string | number> | FilterParamsType;

export type VehicleTabStepType = {
  id?: string | number;
  active?: boolean;
  params?: VehicleTabInnerPageParamsType | null;
  hasCarSelector?: boolean;
  viewPrice?: boolean;
  viewDisplay?: { id: number; value: string; label: string } | null;
  hasChildren?: boolean;
};

export type VehicleTabInnerPageType = {
  categories?: VehicleTabStepType | null;
  categoryDetails?: VehicleTabStepType | null;
  subCategoryDetails?: VehicleTabStepType | null;
  search?: VehicleTabStepType | null;
  oem?: VehicleTabStepType | null;
  productDetails?: VehicleTabStepType | null;
};

export type VehicleTabSelectedCarType = {
  activeCar: {
    carMaker: SelectItem;
    carModel: SelectItem;
    carEngine: SelectItem;
  };
  title: string;
};

export type VehicleTabInnerPageKeysType = keyof VehicleTabInnerPageType;
export type GraphicCatalogButtonTextType =
  | "vehicle_tab.unavailable_vin"
  | "vehicle_tab.miss_reg_number";

export type VehicleTab = {
  id: number;
  type: VehicleTabTypeEnum;
  hasGraphicCatalog: boolean;
  graphicCatalogButtonText: GraphicCatalogButtonTextType | null;
  title: string;
  vehicleId: number | null;
  regNumber: string;
  isActive: boolean;
  innerPage: VehicleTabInnerPageType | null;
  search?: SearchBarType | null;
  selectedCar: VehicleTabSelectedCarType | null;
  vin?: string;
};

export type VehicleTabParamsType = Partial<VehicleTab>;

export type VehicleTabParamsDataType = {
  data: VehicleTabParamsType;
};

export type VehicleTabsType = BaseDataListType<VehicleTab>;
