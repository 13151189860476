// Definitions
import { UserCountryCodesEnum } from "bus/user/models";
import type { UserCountryCodesKeys } from "bus/user/models";

// Utils
import { book } from "init/book";
import { getFilteredMenuItems } from "utils/helpers";

export type MenuNavItemData = {
  key: string;
  href: string;
  text?: string;
  icon?: string;
  asBlankLink?: boolean;
};

export type MenuData = {
  title: string;
  items: MenuNavItemData[];
};

const menuAbout: MenuData = {
  title: "common:navigation.footer.aboutTitle",
  items: [
    {
      key: "1",
      href: book.companyInfo,
      text: "common:navigation.footer.companyInfo",
    },
    {
      key: "2",
      href: book.about,
      text: "common:navigation.footer.aboutUs",
    },
    {
      key: "3",
      href: book.benefits,
      text: "common:navigation.footer.benefits",
    },
  ],
};

const menuAboutPublic: MenuData = {
  title: "common:navigation.footer.aboutTitle",
  items: [
    {
      key: "1",
      href: book.companyInfo,
      text: "common:navigation.footer.companyInfo",
    },
    {
      key: "2",
      href: book.benefits,
      text: "common:navigation.footer.benefits",
    },
  ],
};

export const getCodeOfConductPDF = (country?: UserCountryCodesKeys) => {
  const countryCode = country || UserCountryCodesEnum.en;
  const fileList: Record<UserCountryCodesKeys, string> = {
    [UserCountryCodesEnum.en]:
      "https://scdn.autodoc.pro/code_of_conduct/en/Code%20of%20Conduct.pdf",
    [UserCountryCodesEnum.fr]:
      "https://scdn.autodoc.pro/code_of_conduct/fr/Code%20de%20conduite.pdf",
    [UserCountryCodesEnum.de]:
      "https://scdn.autodoc.pro/code_of_conduct/de/Code%20of%20Conduct.pdf",
    [UserCountryCodesEnum.at]:
      "https://scdn.autodoc.pro/code_of_conduct/de/Code%20of%20Conduct.pdf",
    [UserCountryCodesEnum.nl]:
      "https://scdn.autodoc.pro/code_of_conduct/de/Code%20of%20Conduct.pdf",
  };
  return fileList[countryCode] ? fileList[countryCode] : fileList[UserCountryCodesEnum.en];
};

const menuSupport = (country?: UserCountryCodesKeys): MenuData => ({
  title: "common:navigation.footer.supportTitle",
  items: [
    // {
    //   key: "1",
    //   href: book.helpCentre,
    //   text: "common:navigation.footer.helpCentre",
    // },
    {
      key: "1",
      href: book.termConditions,
      text: "common:navigation.footer.termConditions",
    },
    // {
    //   key: "3",
    //   href: book.returnsDraft,
    //   text: "common:navigation.footer.withdrawal",
    // },
    {
      key: "2",
      href: book.benefitsTermsConditions,
      text: "common:navigation.footer.benefitsTermConditions",
    },
    {
      key: "3",
      href: book.privacyPolicy,
      text: "common:navigation.footer.privacyPolicy",
    },
    {
      key: "4",
      href: getCodeOfConductPDF(country),
      text: "common:navigation.footer.codeOfConduct",
      asBlankLink: true,
    },
  ],
});

const menuSupportPublic = (country?: UserCountryCodesKeys): MenuData => ({
  title: "common:navigation.footer.supportTitle",
  items: [
    {
      key: "1",
      href: book.termConditions,
      text: "common:navigation.footer.termConditions",
    },
    {
      key: "2",
      href: book.benefitsTermsConditions,
      text: "common:navigation.footer.benefitsTermConditions",
    },
    {
      key: "3",
      href: book.privacyPolicy,
      text: "common:navigation.footer.privacyPolicy",
    },
    {
      key: "4",
      href: getCodeOfConductPDF(country),
      text: "common:navigation.footer.codeOfConduct",
      asBlankLink: true,
    },
  ],
});

const menuServicesPrivate = (country?: UserCountryCodesKeys) => {
  const menuServicesCommon = {
    title: "common:navigation.footer.servicesTitle",
    items: [
      {
        key: "1",
        href: book.payment,
        text: "common:navigation.footer.payment",
      },
      {
        key: "2",
        href: book.delivery,
        text: "common:navigation.footer.delivery",
      },
      {
        key: "3",
        href: book.coreExchange,
        text: "common:navigation.footer.coreExchange",
      },
    ],
  };

  const menuServicesDE = {
    title: menuServicesCommon.title,
    items: [
      ...menuServicesCommon.items,
      {
        key: "4",
        href: book.returnsAndRefunds,
        text: "common:navigation.footer.returnsRefunds",
      },
    ],
  };

  const menuServicesFR = {
    title: menuServicesCommon.title,
    items: [
      ...menuServicesCommon.items,
      {
        key: "4",
        href: book.returnsAndRefunds,
        text: "common:navigation.footer.returnsRefunds",
      },
    ],
  };

  const menuServicesMap: { [key in UserCountryCodesEnum]: MenuData } = {
    [UserCountryCodesEnum.de]: menuServicesDE,
    [UserCountryCodesEnum.at]: menuServicesDE,
    [UserCountryCodesEnum.fr]: menuServicesFR,
    [UserCountryCodesEnum.en]: menuServicesCommon,
    [UserCountryCodesEnum.nl]: menuServicesCommon,
  };

  return country ? menuServicesMap[country] : menuServicesMap.en;
};

const menuServicesPublic = {
  title: "common:navigation.footer.servicesTitle",
  items: [
    {
      key: "1",
      href: book.payment,
      text: "common:navigation.footer.payment",
    },
    {
      key: "2",
      href: book.delivery,
      text: "common:navigation.footer.delivery",
    },
    {
      key: "3",
      href: book.coreExchange,
      text: "common:navigation.footer.coreExchange",
    },
  ],
};

export const footerMenuItemsPrivate = (country?: UserCountryCodesKeys, pages?: string[]) => {
  return {
    about: getFilteredMenuItems(menuAbout, pages),
    support: getFilteredMenuItems(menuSupport(country), pages),
    services: getFilteredMenuItems(menuServicesPrivate(country), pages),
  };
};

export const footerMenuItemsPublic = (country?: UserCountryCodesKeys, pages?: string[]) => {
  return {
    about: getFilteredMenuItems(menuAboutPublic, pages),
    support: getFilteredMenuItems(menuSupportPublic(country), pages),
    services: getFilteredMenuItems(menuServicesPublic, pages),
  };
};
