export const book: Readonly<Record<string, string>> = Object.freeze({
  home: "/",
  forbidden: "/403",
  notFound: "/404",
  serverError: "/500",
  about: "/about",
  bankDetails: "/settings/bank-details",
  benefits: "/benefits",
  benefitsTermsConditions: "/benefits-terms-conditions",
  cartPreview: "/cart/preview",
  catalog: "/catalog",
  newCatalog: "/catalog/new-catalog",
  catalogGraphic: "/graphic",
  subСategories: "/sub-categories",
  categories: "/categories",
  subChildСategories: "/sub-child-categories",
  schemes: "/schemes",
  schemaOemGroups: "/oem-groups",
  products: "/products",
  productsOem: "/products-oem",
  companyAndAddress: "/settings/company-address",
  companyInfo: "/company-information",
  companyInformation: "/company-information",
  companyWorkingHours: "/settings/company-working-hours",
  confirmPromoSubscription: "confirm_promo_subscription",
  coreExchange: "/core-exchange",
  customers: "/customers",
  dashboard: "/dashboard",
  delivery: "/delivery",
  depositAccount: "/settings/deposit-account",
  estimateSettings: "/settings/estimate-settings",
  helpCentre: "/help-centre",
  language: "/settings/language",
  loginAndPasswords: "/settings/change-password",
  logout: "/logout",
  loyaltyPgm: "/settings/loyalty-program",
  mandate: "/mandate",
  notifications: "/notifications",
  orders: "/orders",
  ordersCheckout: "/orders/checkout",
  ordersDelivery: "/orders/delivery",
  payment: "/payment",
  personalManager: "/personal-manager",
  privacyPolicy: "/privacy-policy",
  profile: "/profile",
  promotions: "/promotions",
  repairEstimates: "/repair-estimates",
  representativeContact: "/settings/representative-contact",
  resetPassword: "/reset-password",
  returns: "/returns",
  returnsAndRefunds: "/returns-and-refunds",
  returnsConfirm: "/returns/new-confirm",
  returnsDraft: "/returns/new-draft",
  savedBaskets: "/saved-baskets",
  settings: "/settings",
  signin: "/signin",
  signup: "/signup",
  signupProfile: "/signup/profile",
  termConditions: "/term-conditions",
  users: "/settings/users",
  withdrawal: "/withdrawal",
});

export const globalBook: {
  VAT: Record<string, string>;
  nps: Record<string, string>;
} = Object.freeze({
  VAT: {
    fr: "https://www.auto-doc.fr/",
    de: "https://www.autodoc.de/",
    at: "https://www.auto-doc.at/",
    nl: "https://www.autodoc.nl/",
  },
  nps: {
    fr: "https://docs.google.com/forms/d/e/1FAIpQLSdIh-mjFnVNumDd3X78JxztViivZ8mtfQsdzkm5DKvyScdufg/viewform",
    en: "https://docs.google.com/forms/d/e/1FAIpQLSdD-TCBNE47KwC-ytJjFfCvjBreDrEf403LonnJxbmXDOnmYg/viewform",
    de: "https://docs.google.com/forms/d/e/1FAIpQLScIFz9855aSneUArDk1kpz8jjb0Bo6H-8mXzRMAaLb7DF_Oig/viewform",
    at: "https://docs.google.com/forms/d/e/1FAIpQLSdI9D8V89G9QjmjMiXUEROnKLOUxwaeHPfSnkw7SeyyqxuItg/viewform",
    nl: "https://docs.google.com/forms/d/e/1FAIpQLSdD-TCBNE47KwC-ytJjFfCvjBreDrEf403LonnJxbmXDOnmYg/viewform",
  },
});

export const emailsBook = Object.freeze({
  helpService: "info@autodoc.pro",
});
