// Models
import type { CartTabType } from "bus/cart/models";

export const getCartProductsTotalCount = (items: CartTabType[] = []) => {
  return items?.reduce((acc, item) => {
    const itmProductsCount = item?.products?.reduce((pAcc, p) => {
      return pAcc + Number(p?.quantity?.value);
    }, 0);
    return acc + itmProductsCount;
  }, 0);
};
