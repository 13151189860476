// Definitions
import { BaseQueryKey } from "models/Base";
import type { PersonalManagerType } from "bus/personal-manager/models";

// Domains
import { personalManagerKeys } from "bus/personal-manager/store";

// Utils
import { personalManagerService } from "bus/personal-manager/service";
import { useQueryAsync } from "utils/react-query/hooks/use-query-async";

type UseProfilePersonalManagerFetchType = {
  loadingPersonalManagerFetch: boolean;
  personalManager: PersonalManagerType | null;
};

export const useProfilePersonalManagerFetch = (): UseProfilePersonalManagerFetchType => {
  const fetchPersonalManagerProps = useQueryAsync<
    PersonalManagerType,
    PersonalManagerType,
    BaseQueryKey
  >({
    key: [personalManagerKeys.personalManager],
    name: "fetchProfilePersonalManagerQuery",
    fetcher: personalManagerService.fetchProfilePersonalManager,
  });

  return {
    loadingPersonalManagerFetch: fetchPersonalManagerProps.isLoading,
    personalManager: fetchPersonalManagerProps.data || null,
  };
};
