// Core
import { mergeRight } from "ramda";
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import {
  EventPayloadCatalogType,
  EventCatalogDataType,
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  CatalogEventActionEnum,
  CatalogEventObjectEnum,
  EventsDataUnionType,
} from "client/utils/gtm/gtm.types";

class CatalogEvent extends AbstractGTMEventSegment<GTMTypesEnum.catalog> {
  protected readonly segmentShape = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.parent_id]: 0,
      [ParametersEnum.child_id]: 0,
      [ParametersEnum.event_action]: CatalogEventActionEnum.click_open,
      [ParametersEnum.event_object]: CatalogEventObjectEnum.main,
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadCatalogType {
    const catalogData = data as EventCatalogDataType;
    const params = mergeRight(this.segmentShape[PayloadKeysEnum.parameters], catalogData);
    return {
      [PayloadKeysEnum.parameters]: params,
    };
  }
}

export const catalogEvent: CatalogEvent = new CatalogEvent();
