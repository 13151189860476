// Core
import { useCallback } from "react";
import { useIsFetching } from "react-query";

// Definitions
import { VehicleTab, VehicleTabsType } from "bus/vehicleTabs/models";

// Domains
import { initialCatalogState, vehicleTabsKeys } from "bus/vehicleTabs/store";

// Utils
import { vehicleTabsService } from "bus/vehicleTabs/service";
import { queryClient } from "init/queryClient";
import { queryFetcher } from "utils/react-query/query-fetcher";

type UseTabsFetchQueryType = {
  loadingTabsFetch: boolean;
  tabs: VehicleTab[];
  onFetchTabs: () => Promise<VehicleTab[]>;
};

export const useTabsFetchQuery = (): UseTabsFetchQueryType => {
  const queryKey = [vehicleTabsKeys.tabs];
  const isLoading = useIsFetching(queryKey);
  const tabs = queryClient.getQueryData<VehicleTabsType>(queryKey);

  const onFetchTabs = useCallback<() => Promise<VehicleTab[]>>(async () => {
    const data = await queryClient.fetchQuery(
      queryKey,
      () =>
        queryFetcher({
          name: "fetchTabsQuery",
          fetcher: vehicleTabsService.fetchTabs,
        }),
      { retry: 0 },
    );
    return data.items;
  }, queryKey);

  return {
    loadingTabsFetch: !!isLoading,
    tabs: tabs?.items || initialCatalogState.items,
    onFetchTabs,
  };
};
