// Core
import { z } from "zod";

export const carInfoSchema = z.object({
  vehicleType: z.number(),
  vehicleName: z.string(),
  vehicleId: z.number(),
  plateNumber: z.string(),
});

export type CarInfoType = z.infer<typeof carInfoSchema>;
