// Core
import { useCallback } from "react";

// Definitions
import type { BaseQueryKey } from "models/Base";
import { HttpStatusCode } from "models/Http";

import type { VehicleTab, VehicleTabParamsType, VehicleTabsType } from "bus/vehicleTabs/models";
import { VehicleTabTypeEnum } from "bus/vehicleTabs/models";

// Domains
import { vehicleTabsKeys } from "bus/vehicleTabs/store";
// Utils
import { queryClient } from "init/queryClient";
import { notificationService } from "utils/notifications";
import { getHttpErrorMessageWithTranslations } from "utils/notifications-message";
import { useMutationAsync } from "utils/react-query/hooks/use-mutation-async";
import { vehicleTabsService } from "bus/vehicleTabs/service";

type TabCreateMutationMutate = Promise<{ prevTabs: VehicleTabsType } | null>;
type UseTabsCreateType = {
  loadingCreateTab: boolean;
  errorCreateTab: boolean;
  onCreateTab: (tabData: VehicleTabParamsType) => Promise<void> | void;
};

export const useTabsCreate = (): UseTabsCreateType => {
  const queryKey: BaseQueryKey = [vehicleTabsKeys.tabs];
  const tabCreateProps = useMutationAsync<
    VehicleTab,
    VehicleTabParamsType,
    TabCreateMutationMutate,
    BaseQueryKey,
    VehicleTabParamsType
  >({
    key: queryKey,
    name: "createTabMutation",
    fetcher: vehicleTabsService.createTab,
    options: {
      onSuccess: (data) => {
        if (!data) {
          return;
        }

        queryClient.setQueryData<VehicleTabsType>(queryKey, (prevState) => {
          if (!prevState?.items.length) {
            return { items: [data] };
          }

          const tabs = prevState.items.map((item) => ({ ...item, isActive: false }));
          const newTabs =
            data.type === VehicleTabTypeEnum.search ? [data, ...tabs] : [...tabs, data];
          return { items: newTabs };
        });
      },
      onError: async (error, _, ctx) => {
        const prevData = await ctx;
        const isError400 = error.status === HttpStatusCode.badRequest;
        const isError422 = error.status === HttpStatusCode.unprocessableEntity;
        const isError500 = error.status >= HttpStatusCode.server;

        if (prevData?.prevTabs?.items.length) {
          queryClient.setQueryData<VehicleTabsType>(queryKey, prevData.prevTabs);
        }

        if (isError400 || isError422 || isError500) {
          notificationService.showError(getHttpErrorMessageWithTranslations());
        }
      },
    },
  });

  const onCreateTab = useCallback<(data: VehicleTabParamsType) => void>(
    async (data) => {
      await tabCreateProps.mutateAsync(data);
    },
    [tabCreateProps],
  );

  return {
    loadingCreateTab: tabCreateProps.isLoading,
    errorCreateTab: tabCreateProps.isError,
    onCreateTab,
  };
};
