// Definitions
import type { StaticDataInnerShape } from "./models";
import { StaticDataKeys } from "./models";

export const staticDataKeys = {
  staticData: "static-data",
};

export type StaticDataStateType = StaticDataInnerShape;

export const initialStaticDataState: StaticDataStateType = {
  [StaticDataKeys.businessType]: [],
  [StaticDataKeys.quantityAutolifts]: [],
  [StaticDataKeys.quantityEmployees]: [],
  [StaticDataKeys.countries]: [],
  [StaticDataKeys.phoneCodes]: [],
  [StaticDataKeys.returnStatuses]: [],
  [StaticDataKeys.returnCauses]: [],
  [StaticDataKeys.orderStatus]: [],
  [StaticDataKeys.repairEstimateStatus]: [],
  [StaticDataKeys.returnSpentHours]: [],
  [StaticDataKeys.returnSpentMinutes]: [],
  [StaticDataKeys.returnOtherTypes]: [],
  [StaticDataKeys.returnOptions]: [],
  [StaticDataKeys.cityOptions]: [],
  [StaticDataKeys.bonusOperation]: [],
  [StaticDataKeys.viewDisplay]: [],
};

export const selectStaticDataQueryMemoized = (data: StaticDataInnerShape): StaticDataInnerShape =>
  data;
