// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import {
  EventPayloadAuthorizationType,
  EventAuthorizationDataType,
  ParametersEnum,
  VisitorTypeEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  EventsDataUnionType,
} from "client/utils/gtm/gtm.types";

class AuthorizationEvent extends AbstractGTMEventSegment<GTMTypesEnum.authorization> {
  protected readonly segmentShape = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.user_id]: 0,
      [ParametersEnum.visitor_type]: VisitorTypeEnum.logged_user,
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadAuthorizationType {
    const authorizationData = data as EventAuthorizationDataType;
    const segment = this.getSegment();

    return {
      [PayloadKeysEnum.parameters]: {
        ...segment[PayloadKeysEnum.parameters],
        [ParametersEnum.user_id]: authorizationData[ParametersEnum.user_id],
      },
    };
  }
}

export const authorizationEvent: AuthorizationEvent = new AuthorizationEvent();
