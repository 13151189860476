// Core
import TagManager from "react-gtm-module";

// Definitions
import {
  EventsDataType,
  GTMConfigType,
  GTMSegmentBuilderInterface,
  GTMServiceInterface,
  GTMTypesEnum,
} from "./gtm.types";

// Service
import { gtmConfig } from "./config";

export class GTMClientService<T extends GTMTypesEnum = GTMTypesEnum>
  implements GTMServiceInterface
{
  private readonly tagManager?: typeof TagManager;

  private readonly eventsHandlers: Map<GTMTypesEnum, GTMSegmentBuilderInterface>;

  private readonly verifyEventConsentGranted: GTMConfigType["checkEventsConsentGranted"];

  private isActive: boolean = true;

  private readonly isSafeToInit: boolean = typeof window !== "undefined";

  constructor(config: GTMConfigType) {
    this.verifyEventConsentGranted = config.checkEventsConsentGranted;
    this.eventsHandlers = config.eventsMap;
    config.checkEventsConsentGranted &&
      (this.verifyEventConsentGranted = config.checkEventsConsentGranted);
    config.active && (this.isActive = config.active);

    if (this.isSafeToInit) {
      const tagManagerArgs = {
        gtmId: config.gtmId,
        ...config.gtmArgs,
      };
      this.tagManager = TagManager;
      this.tagManager.initialize(tagManagerArgs);
    }
  }

  public setIsActive(active: boolean) {
    this.isActive = active;
  }

  private verifyEvent(name: T) {
    if (!this.isActive || !this.tagManager) {
      throw new Error("GTM service is not active");
    }
    if (!this.eventsHandlers.has(name)) {
      throw new Error(`GTM Event "${name}" isn't observed`);
    }
    if (this.verifyEventConsentGranted && !this.verifyEventConsentGranted(name)) {
      throw new Error(`GTM Event "${name}" consent isn't granted`);
    }
  }

  private eventSegmentBuilder(name: T) {
    return this.eventsHandlers.get(name);
  }

  public event(name: T, data: EventsDataType[T]) {
    try {
      this.verifyEvent(name);
      const eventSegment = this.eventSegmentBuilder(name);
      const segment = eventSegment?.makeSegment(data);
      this.tagManager?.dataLayer?.({
        dataLayer: { event: name, ...segment },
      });
    } catch (err) {
      const error = err as Error;
      console.info(error.message);
    }
  }
}

export const gtmService = new GTMClientService(gtmConfig);
