// Core
import { useTranslation } from "next-i18next";

// Definitions
import type { PropsWithChildren } from "react";

// Components
import { Row } from "components/ui/Row";
import { Col } from "components/ui/Col";
import { Button } from "components/ui/Button";
import { FormTitle } from "components/ui/FormTitle";

// Domains
import { useLogout } from "bus/auth/hooks/useLogout";

// Utils
import { rowGutters } from "utils/constants";
import st from "./Styles.module.less";

export type ModalConfirmSignoutProps = PropsWithChildren<{
  onClose?: () => void;
  testId?: string;
}>;

export const ModalConfirmSignout = (props: ModalConfirmSignoutProps) => {
  const { testId = "modal-signout", onClose } = props;
  const { t } = useTranslation();
  const { loadingLogout, onLogout } = useLogout();

  const onLogoutHandler = (): void => {
    onLogout({ type: "modal" });
  };

  return (
    <div className={st["modal-signout-confirm"]} data-test-id={testId}>
      <FormTitle>{t("common:modals:signout:title")}</FormTitle>
      <Row justify="center" gutter={rowGutters}>
        <Col span={12} flex="auto" className={st["fit-button"]}>
          <Button
            type="primary"
            className={st["fit-button"]}
            ghost
            block
            size="middle"
            testId="modal-signout-btn-confirm"
            onClick={onLogoutHandler}
            loading={loadingLogout}
          >
            {t("common:modals:signout:confirm")}
          </Button>
        </Col>
        <Col span={12} flex="auto" className={st["fit-button"]}>
          <Button
            className={st["fit-button"]}
            testId="modal-signout-btn-cancel"
            type="primary"
            block
            size="middle"
            onClick={onClose}
            disabled={loadingLogout}
          >
            {t("common:modals:signout:cancel")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
