// Components
import { LightList } from "components/ui/LightList";

// Domains
import { useCartPreviewFetch } from "bus/cart/hooks/useCartPreviewFetch";
import { useNotificationsFetch } from "bus/notifications/hooks/useNotificationsFetch";

// Utils
import { profileActionModel } from "utils/renderModels/profileActionModel";
import { getCartProductsTotalCount } from "utils/cart";

export const NavbarProfile = () => {
  const { loadingCartPreviewFetch, cartPreview } = useCartPreviewFetch();
  const { loadingNotificationsFetch, totalUnread } = useNotificationsFetch();

  const cartProductsCount = getCartProductsTotalCount(cartPreview?.tabs);
  const menuItems = profileActionModel({
    cartProductsCount,
    loadingCartProductsCount: loadingCartPreviewFetch,
    notificationsCount: totalUnread,
    loadingNotificationsFetch,
  });

  return <LightList testId="navbar-profile" mode="horizontal" type="actions" items={menuItems} />;
};
