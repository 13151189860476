// Core
import { useCallback, useState } from "react";

// Definitions
import type {
  SearchAutocompleteType,
  SearchAutocompleteOptionType,
  SearchAutocompleteParamsType,
} from "bus/search/models";

// Domains
import { initialSearchState, searchKeys } from "bus/search/store";

// Utils
import { searchService } from "bus/search/service";
import { useQueryAsync } from "utils/react-query/hooks/use-query-async";

type SearchAutocompleteQueryKeyType = [string, SearchAutocompleteParamsType | null];
type OnSearchAutocompleteCbType = (keyword: string) => void;
type UseSearchAutocompleteType = {
  autocompleteOptions: SearchAutocompleteOptionType[];
  loadingSearchAutocomplete: boolean;
  onFetchSearchAutocomplete: OnSearchAutocompleteCbType;
  keyword?: string;
};

export const useSearchAutocomplete = (): UseSearchAutocompleteType => {
  const [autocompleteParams, setAutocompleteParams] = useState<SearchAutocompleteParamsType>({
    keyword: "",
  });

  const queryKey: SearchAutocompleteQueryKeyType = [searchKeys.autocomplete, autocompleteParams];

  const fetchSearchAutocompleteProps = useQueryAsync<
    SearchAutocompleteType,
    SearchAutocompleteType,
    SearchAutocompleteQueryKeyType,
    SearchAutocompleteParamsType
  >({
    key: queryKey,
    name: "fetchSearchAutocompleteQuery",
    fetcher: searchService.fetchSearchAutocomplete,
    fetcherPayload: {
      params: autocompleteParams,
    },
    options: {
      enabled: !!autocompleteParams?.keyword,
    },
  });

  const onFetchSearchAutocomplete = useCallback<OnSearchAutocompleteCbType>(
    (keyword) => {
      void (() => {
        const params = { keyword: keyword.trim() };
        setAutocompleteParams(params);
      })();
    },
    [searchKeys.autocomplete],
  );

  return {
    loadingSearchAutocomplete: fetchSearchAutocompleteProps.isLoading,
    autocompleteOptions:
      fetchSearchAutocompleteProps?.data?.items || initialSearchState?.autocomplete?.items,
    onFetchSearchAutocomplete,
    keyword: autocompleteParams?.keyword,
  };
};
