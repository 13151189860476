// Core
import { useCallback } from "react";

// Definitions
import { BaseQueryKey } from "models/Base";
import { VehicleTab, VehicleTabParamsType, VehicleTabsType } from "bus/vehicleTabs/models";

// Domains
import { vehicleTabsKeys } from "bus/vehicleTabs/store";

// Utils
import { queryClient } from "init/queryClient";
import { getHttpErrorMessageWithTranslations } from "utils/notifications-message";
import { notificationService } from "utils/notifications";
import { useMutationAsync } from "utils/react-query/hooks/use-mutation-async";
import { vehicleTabsService } from "bus/vehicleTabs/service";

type TabCreateMutationMutate = Promise<{ prevTabs: VehicleTabsType }>;
type UseTabsUpdateType = {
  onUpdateTab: (data: VehicleTabParamsType) => Promise<VehicleTab | void>;
  loadingUpdateTab: boolean;
};

export const useTabsUpdate = (): UseTabsUpdateType => {
  const queryKey: BaseQueryKey = [vehicleTabsKeys.tabs];
  const tabUpdateProps = useMutationAsync<
    VehicleTab,
    VehicleTabParamsType,
    TabCreateMutationMutate,
    BaseQueryKey
  >({
    key: queryKey,
    name: "updateTabMutation",
    fetcher: vehicleTabsService.updateTab,
    handlerError404: () =>
      Promise.resolve(notificationService.showError(getHttpErrorMessageWithTranslations())),
    handlerError422: () =>
      Promise.resolve(notificationService.showError(getHttpErrorMessageWithTranslations())),
    options: {
      onSuccess: (data) => {
        if (!data) {
          return;
        }
        queryClient.setQueryData<VehicleTabsType>(queryKey, (prevState) => {
          if (!prevState?.items.length) {
            return { items: [data] };
          }
          const items = prevState.items.map((item) => (item.id === data?.id ? data : item));
          return { items };
        });
      },
    },
  });

  const onUpdateTab = useCallback<(data: VehicleTabParamsType) => Promise<VehicleTab | void>>(
    async (data) => {
      try {
        return await tabUpdateProps.mutateAsync(data);
      } catch (err) {
        console.warn(err);
      }
    },
    [tabUpdateProps],
  );

  return {
    loadingUpdateTab: tabUpdateProps.isLoading,
    onUpdateTab,
  };
};
