// Core
import { z } from "zod";

export enum PermissionAct {
  CREATE = "create",
  READ = "read",
  UPDATE = "update",
  DELETE = "delete",
}

export const permissionFeatAliasSchema = z.enum([
  "banner_top",
  "service_agent_holidays_banner_top",
  "christmas_banner",
  "dashboard_nps_banner",
  "nps_survey_modal",
  "selector_reg_num",
  "selector_kba",
  "switcher_vat_price",

  "graphic_catalog_redirect",

  "cart_repair_estimate",
  "cart_total_vat_price",
  "cart_vat_price",
  "cart_actions_vat_price",

  "saved_basket_repair_estimate",
  "saved_basket_total_vat_price",
  "saved_basket_vat_price",

  "order_total_vat_price",
  "order_vat_price",

  "products_actions_vat_price",
  "product_details_vat_price",

  "repair_estimates",
  "repair_estimate_vat_price",
  "repair_estimate_total_vat_price",
  "repair_estimate_actions_vat_price",

  "client_vehicle_by_attr",
  "client_vehicle_by_reg_number",
  "client_vehicle_by_kba",

  "returns_claims_tracking",
  "returns_claims_label",
  "returns_products",
  "returns",
  "return_dont_have_vin_button",
]);

export enum CountryCodesEnum {
  FR = "FR",
  DE = "DE",
  EN = "EN",
  AT = "AT",
  NL = "NL",
}
export type CountryCodesType = keyof typeof CountryCodesEnum;

const LanguageEnum = z.enum(["FR", "DE", "EN"]);
export type LanguageType = z.infer<typeof LanguageEnum>;

export type PermissionFeatAliasType = z.infer<typeof permissionFeatAliasSchema>;
const permissionFeatRuleScheme = z
  .object({
    [PermissionAct.READ]: z.enum([PermissionAct.READ]).optional(),
    [PermissionAct.UPDATE]: z.enum([PermissionAct.UPDATE]).optional(),
    [PermissionAct.CREATE]: z.enum([PermissionAct.CREATE]).optional(),
    [PermissionAct.DELETE]: z.enum([PermissionAct.DELETE]).optional(),
  })
  .strict();

const permissionFeatScheme = z
  .object({
    alias: permissionFeatAliasSchema,
    rules: permissionFeatRuleScheme.nullable(),
    description: z.string(),
  })
  .strict();

export type PermissionFeatType = z.infer<typeof permissionFeatScheme>;

export const permissionsScheme = z
  .object({
    pages: z.array(z.string()),
    feature: z.array(permissionFeatScheme),
  })
  .strict();
export type ServerPermissionType = z.infer<typeof permissionsScheme>;

const staticDataItemSchema = z
  .object({
    id: z.number(),
    value: z.string(),
    label: z.string(),
  })
  .strict();

const profileSettingsSchema = z.object({
  viewDisplay: staticDataItemSchema.nullable(),
  showPriceVat: z.boolean(),
});

export const profileSchema = z
  .object({
    id: z.number().nullable(),
    projectId: z.number().nullable(),
    name: z.string().optional(),
    firstName: z.string(),
    image: z.string().optional(),
    email: z.string().optional(),
    surname: z.string(),
    language: LanguageEnum,
    phonePrefix: staticDataItemSchema.nullable(),
    phoneNumber: z.string(),
    customerId: z.number().nullable(),
    countryId: z.number().nullable(),
    basketProductsCount: z.number().nullable(),
    countryCode: z.nativeEnum(CountryCodesEnum),
    permission: z.object({
      pages: z.array(z.string()),
      feature: z.array(permissionFeatScheme),
    }),
    settings: profileSettingsSchema.nullable(),
  })
  .strict();

export type ProfileType = z.infer<typeof profileSchema>;
export type ProfileSettingsType = z.infer<typeof profileSettingsSchema>;

export type ProfilePermissionType = NonNullable<ProfileType["permission"]>;
export type ProfilePermissionPagesType = ProfilePermissionType["pages"];
export type ProfilePermissionFeatListType = ProfilePermissionType["feature"];
export type ProfilePermissionFeatType = ProfilePermissionFeatListType[0];
