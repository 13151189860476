// Definitions
import type { HttpResponse, HttpRequestParams } from "models/Http";
import type {
  RegistrationType,
  CheckHashDataType,
  AuthSessionType,
  ChangePasswordType,
  ResetPasswordEmailType,
  ResetPasswordType,
} from "bus/auth/models";
import type { LeadType, LeadHash } from "bus/lead/models";

// Utils
import { http, httpServer } from "api/network-provider";
import { HEADER_COUNTRY } from "utils/constants";

export const authService = Object.freeze({
  login: (payload: HttpRequestParams<{ login: string; password: string }>) => {
    const data = payload.data;
    return http.post<HttpResponse<{ token: string; profile: { customerId: number } }>>(
      "/auth/login",
      data,
    );
  },
  logout: (payload: HttpRequestParams) => {
    const { meta } = payload;
    const config = {
      headers: {
        ...(meta?.country && { [HEADER_COUNTRY]: meta?.country }),
      },
    };
    return http.post<HttpResponse>("/auth/logout", null, config);
  },
  changePassword: (payload?: HttpRequestParams<ChangePasswordType>) => {
    const data = payload?.data;
    return http.post<HttpResponse<void>>("/auth/change-password", {
      data,
    });
  },
  registration: (payload: HttpRequestParams<LeadType, LeadHash>) => {
    const { data, meta } = payload;
    const config = {
      headers: {
        ...(meta?.country && { [HEADER_COUNTRY]: meta?.country }),
      },
    };
    return http.post<HttpResponse<RegistrationType>>("/auth/registration", data, config);
  },
  session: async (payload?: HttpRequestParams) => {
    const meta = payload?.meta;
    const configBase = {
      headers: {
        ...(meta?.country && { [HEADER_COUNTRY]: meta.country }),
      },
    };
    if (meta?.isSSR) {
      const config = {
        ...configBase,
        headers: {
          ...(meta?.cookies && { cookie: meta.cookies }),
        },
      };
      return httpServer.post<HttpResponse<AuthSessionType>>("/auth/session", null, config);
    }
    return http.post<HttpResponse<AuthSessionType>>("/auth/session", null, configBase);
  },
  resetPasswordEmail: (payload: HttpRequestParams<ResetPasswordEmailType>) => {
    const { data, meta } = payload;
    const config = {
      headers: {
        ...(meta?.country && { [HEADER_COUNTRY]: meta?.country }),
      },
    };
    return http.post<HttpResponse<void>>("/auth/reset-password-email", data, config);
  },
  resetPasswordCheckHash: (payload: HttpRequestParams<void, { hash: string }>) => {
    const { params, meta } = payload;
    const hash = params?.hash;
    const config = {
      headers: {
        ...(meta?.country && { [HEADER_COUNTRY]: meta?.country }),
      },
    };
    return http.get<HttpResponse<CheckHashDataType>>(`/auth/check-hash/${String(hash)}`, config);
  },
  resetPassword: (payload: HttpRequestParams<ResetPasswordType>) => {
    const { data, meta } = payload;
    const config = {
      headers: {
        ...(meta?.country && { [HEADER_COUNTRY]: meta?.country }),
      },
    };
    return http.post<HttpResponse<void>>("/auth/reset-password", data, config);
  },
});
