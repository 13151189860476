// Definitions
import type { CartPreviewType, CartDeliveryType } from "bus/cart/models";
import { CartTabSelectEnumType } from "bus/cart/models";

export const cartKeys = {
  preview: "cart/preview",
  paymentDelivery: "cart/delivery-address",
  reapirEstimate: "cart/reapir-estimate",
};

export type CartStateType = {
  preview: CartPreviewType;
  deliveryAddress: CartDeliveryType;
};

export const initialCartState: CartStateType = {
  preview: {
    trackingId: "",
    selected: CartTabSelectEnumType.all,
    totalPrice: null,
    totalPriceVat: null,
    vatPrice: null,
    vatPercentage: null,
    deliveryDate: null,
    checkoutAvailable: false,
    tabs: [],
  },
  deliveryAddress: {
    firstName: "",
    companyName: "",
    surname: "",
    street: "",
    houseNumber: "",
    city: "",
    index: "",
    country: null,
    additionalAddress: "",
    phonePrefix: null,
    phoneNumber: "",
  },
};
