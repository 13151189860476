// Components
import { SearchBarForm } from "components/common/Forms/SearchBar";
import { ModalSearchNotice } from "components/common/ModalViews/SearchNotice";
import { MainSearchBarOption } from "components/common/MainSearchBarOption";
import { Modal } from "components/ui/Modal";

// Bus
import { useTabSearchCreate } from "bus/vehicleTabs/hooks/useTabSearchCreate";
import { useSearchAutocomplete } from "bus/search/hooks/useSearchAutocomplete";

// Definitions
import type { SearchAutocompleteOptionType } from "bus/search/models";
import type { SearchBarOptionType } from "components/common/Forms/SearchBar";

// Utils
import st from "./Styles.module.less";
import { modalTypes } from "components/ui/Modal/utils";
import { useModalContext } from "hooks/useModalContext";

export const MainSearchBar = () => {
  const { onTabSearchCreate, loadingTabSearchCreate } = useTabSearchCreate();
  const {
    loadingSearchAutocomplete,
    onFetchSearchAutocomplete,
    autocompleteOptions,
    keyword: searchKey,
  } = useSearchAutocomplete();

  const optionJSX = (
    option: SearchAutocompleteOptionType,
    searchStr: string,
  ): SearchBarOptionType => {
    const { label, keyword, entity } = option;

    return {
      id: 0,
      value: label,
      label: <MainSearchBarOption label={label} keyword={searchStr} />,
      keyword,
      entity,
      type: option.type,
    };
  };
  const options = !searchKey ? [] : autocompleteOptions.map((o) => optionJSX(o, searchKey));
  const { onPageModals, setOnPageModal } = useModalContext();

  const handleOpenModal = () => setOnPageModal(modalTypes.SEARCH_NOTICE, true);

  const handleCloseModal = () => setOnPageModal(modalTypes.SEARCH_NOTICE, false);

  return (
    <div className={st["search-bar"]} data-test-id="main-search">
      <SearchBarForm
        loadingSearch={loadingTabSearchCreate}
        loadingAutocomplete={loadingSearchAutocomplete}
        options={options}
        onSubmit={onTabSearchCreate}
        onSearch={onFetchSearchAutocomplete}
        onShowModal={handleOpenModal}
      />
      <Modal visible={onPageModals[modalTypes.SEARCH_NOTICE]} onClose={handleCloseModal} hasSearch>
        <ModalSearchNotice onClose={handleCloseModal} />
      </Modal>
    </div>
  );
};
