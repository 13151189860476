// Definitions
import type { VehicleTab } from "bus/vehicleTabs/models";
import { VehicleTabTypeEnum } from "bus/vehicleTabs/models";
import { productsDisplaySwitcherOptions } from "data/settings";

export const VEHICLE_TAB_CONFIG = {
  tabs: {
    minTabsCount: 1,
    maxTabsCount: 5,
    indexStep: 1,
    state: { active: true, inactive: false },
    hasCarSelector: { active: true, inactive: false },
  },
  productDetails: {
    viewPrice: true,
    viewDisplay: productsDisplaySwitcherOptions[0],
  },
};

export const VEHICLE_TAB_INN_PAGE_INIT = {
  categories: {
    active: VEHICLE_TAB_CONFIG.tabs.state.active,
    hasCarSelector: VEHICLE_TAB_CONFIG.tabs.hasCarSelector.active,
  },
  categoryDetails: {
    active: VEHICLE_TAB_CONFIG.tabs.state.inactive,
    hasCarSelector: VEHICLE_TAB_CONFIG.tabs.hasCarSelector.active,
  },
  subCategoryDetails: {
    active: VEHICLE_TAB_CONFIG.tabs.state.inactive,
    hasCarSelector: VEHICLE_TAB_CONFIG.tabs.hasCarSelector.active,
    viewPrice: VEHICLE_TAB_CONFIG.productDetails.viewPrice,
    viewDisplay: VEHICLE_TAB_CONFIG.productDetails.viewDisplay,
  },
  search: {
    active: VEHICLE_TAB_CONFIG.tabs.state.inactive,
    hasCarSelector: VEHICLE_TAB_CONFIG.tabs.hasCarSelector.active,
    viewPrice: VEHICLE_TAB_CONFIG.productDetails.viewPrice,
    viewDisplay: VEHICLE_TAB_CONFIG.productDetails.viewDisplay,
  },
  oem: {
    active: VEHICLE_TAB_CONFIG.tabs.state.inactive,
    viewPrice: VEHICLE_TAB_CONFIG.productDetails.viewPrice,
    viewDisplay: VEHICLE_TAB_CONFIG.productDetails.viewDisplay,
  },
  productDetails: { active: VEHICLE_TAB_CONFIG.tabs.state.inactive },
};

export const getExistingSearchTab = (tabs: VehicleTab[] = []): VehicleTab | null => {
  return tabs.find((tab) => tab?.type === VehicleTabTypeEnum.search) || null;
};

export const isVehicleTabTypeSearch = (tabs: VehicleTab[] = []) => {
  const activeTab = tabs?.find((tab) => tab.isActive);
  return activeTab?.type === VehicleTabTypeEnum.search;
};

export const checkTabsLimit = (tabs: VehicleTab[]) => {
  const vehicleTabs = tabs.filter((tab) => tab.type !== VehicleTabTypeEnum.search);
  return VEHICLE_TAB_CONFIG.tabs.maxTabsCount <= vehicleTabs.length;
};
