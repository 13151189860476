// Components
import type { TFunction } from "i18next";
import type { DescriptionsItemType } from "components/ui/Descriptions/Descriptions.types";

// Utils
import { getHeader } from "utils/helpers";

export type ProductSearchNoticeModelProps = {
  t: TFunction;
};

export const productSearchNoticeModel = (
  props: ProductSearchNoticeModelProps,
): DescriptionsItemType[] => {
  const { t } = props;

  const maskT = "common:modals.searchNotice.descriptions";

  return [
    {
      id: 1,
      label: getHeader(maskT, "0.label", t),
      value: getHeader(maskT, "0.value", t),
    },
    {
      id: 2,
      label: getHeader(maskT, "1.label", t),
      value: getHeader(maskT, "1.value", t),
    },
    {
      id: 3,
      label: getHeader(maskT, "2.label", t),
      value: getHeader(maskT, "2.value", t),
    },
    {
      id: 4,
      label: getHeader(maskT, "3.label", t),
      value: getHeader(maskT, "3.value", t),
    },
    {
      id: 5,
      label: getHeader(maskT, "4.label", t),
      value: getHeader(maskT, "4.value", t),
    },
    {
      id: 6,
      label: getHeader(maskT, "5.label", t),
      value: getHeader(maskT, "5.value", t),
    },
    {
      id: 7,
      label: getHeader(maskT, "6.label", t),
      value: getHeader(maskT, "6.value", t),
    },
    {
      id: 8,
      label: getHeader(maskT, "7.label", t),
      value: getHeader(maskT, "7.value", t),
    },
    {
      id: 9,
      label: getHeader(maskT, "8.label", t),
      value: getHeader(maskT, "8.value", t),
    },
    {
      id: 10,
      label: getHeader(maskT, "9.label", t),
      value: getHeader(maskT, "9.value", t),
    },
  ];
};
