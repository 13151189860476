// Core
import { AbstractGTMEventSegment } from "./abstract-segment";

// Definitions
import {
  ParametersEnum,
  PayloadKeysEnum,
  GTMTypesEnum,
  EventPayloadPurchaseType,
  EventPurchaseDataType,
  EcommerceEnum,
  EcommerceItemsEnum,
  EventsDataUnionType,
} from "client/utils/gtm/gtm.types";

class PurchaseEvent extends AbstractGTMEventSegment<GTMTypesEnum.purchase> {
  protected readonly segmentShape: EventPayloadPurchaseType = {
    [PayloadKeysEnum.parameters]: {
      [ParametersEnum.shipping_date]: 0,
      [ParametersEnum.basket_id]: "0",
    },
    [PayloadKeysEnum.ecommerce]: {
      [EcommerceEnum.currency]: "",
      [EcommerceEnum.value]: 0,
      [EcommerceEnum.items]: [],
      [EcommerceEnum.shipping]: 0,
      [EcommerceEnum.transaction_id]: "0",
    },
  };

  makeSegment(data: EventsDataUnionType): EventPayloadPurchaseType {
    const { cart, paymentProducts, paymentOrder } = data as EventPurchaseDataType;
    const { currency, priceVat } = super.getPriceConfig(String(cart.totalPrice?.country));

    const segment = this.getSegment();
    segment[PayloadKeysEnum.parameters][ParametersEnum.shipping_date] = Number(cart.deliveryDate);
    segment[PayloadKeysEnum.parameters][ParametersEnum.basket_id] = String(cart.trackingId);
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.currency] = String(currency);
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.value] = Number(cart.totalPrice?.value);
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.shipping] = Number(
      paymentProducts?.deliveryPrice?.value,
    );
    segment[PayloadKeysEnum.ecommerce][EcommerceEnum.transaction_id] = String(
      paymentOrder?.orderId,
    );
    (cart?.tabs || []).forEach((tab) => {
      tab.products.forEach((p) => {
        segment[PayloadKeysEnum.ecommerce][EcommerceEnum.items].push({
          [EcommerceItemsEnum.item_id]: Number(p.articleId),
          [EcommerceItemsEnum.item_name]: String(p.name),
          [EcommerceItemsEnum.item_brand]: String(p.brand?.name),
          [EcommerceItemsEnum.item_category]: p.genericId,
          [EcommerceItemsEnum.item_category2]: Number(tab.vehicleId),
          [EcommerceItemsEnum.price]: Number(priceVat ? p.priceVat?.value : p.priceNonVat?.value),
          [EcommerceItemsEnum.quantity]: Number(p.quantity?.value),
          [EcommerceItemsEnum.item_variant]: p.isVinCompatibility,
        });
      });
    });

    return segment;
  }
}

export const purchaseEvent: PurchaseEvent = new PurchaseEvent();
