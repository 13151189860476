// Core
import { toPairs, mapObjIndexed } from "ramda";

// Definitions
import { TFunction } from "i18next";
import { SortType, SortEnum } from "models/Sort";
import type { SelectItem } from "components/ui/FormFields/InputSelect/InputSelect.types";
import type { StaticDataItemType } from "bus/staticData/models";
import type { DeliveryAddressType } from "bus/lead/models";
import type { WorkingHoursType } from "bus/settings/models";
import type { UserCountryItemType } from "bus/user/models";

// Data
import { countryIcons } from "data/locales";

// Utils
import { workingHoursDefaultTime } from "utils/constants";

export const mapItemWithIcon = (item?: SelectItem, locale?: string): SelectItem => {
  const localeItem = countryIcons.find((el) => !!locale && el.value === locale);

  return item
    ? {
        ...item,
        ...(localeItem?.icon && {
          icon: localeItem.icon,
        }),
      }
    : null;
};

export const mapCountriesToIcons = (items: SelectItem[]): SelectItem[] =>
  items.map((item) => mapItemWithIcon(item, String(item?.value)));

export const getCountryItemByLocale = (items: SelectItem[], locale: string): SelectItem => {
  const countryItem = items.find((el) => el?.value === locale.toLocaleLowerCase());
  return mapItemWithIcon(countryItem, String(countryItem?.value));
};

export const mapBillingAddressesCountry = (
  countries: StaticDataItemType[],
  billingAddress: DeliveryAddressType,
  defaultLocale: string,
  cb: (items: SelectItem[], locale: string) => SelectItem,
): DeliveryAddressType => {
  const itemLocale = billingAddress?.country?.value ?? defaultLocale;
  const itemWithIcon = cb(countries, String(itemLocale));
  return {
    ...billingAddress,
    country: itemWithIcon,
  };
};

export const mapWorkingHours = (data: WorkingHoursType, t: TFunction): WorkingHoursType => {
  const days = data?.days?.map((item) => {
    const label = t(`settings:days.${item.id}`);
    const start = item.start ? item.start : workingHoursDefaultTime.start;
    const end = item.end ? item.end : workingHoursDefaultTime.end;
    const lunchBreakStart = item.lunchBreakStart ? item.lunchBreakStart : start;
    const lunchBreakEnd = item.lunchBreakEnd ? item.lunchBreakEnd : end;

    return {
      ...item,
      label,
      start,
      end,
      lunchBreakStart,
      lunchBreakEnd,
    };
  });
  return { ...data, days };
};

export const mapWorkingHoursResult = (data: WorkingHoursType): WorkingHoursType => {
  const days = data?.days?.map((item) => {
    const { label, ...fields } = item;
    const start = item.start;
    const end = item.end;
    return {
      ...fields,
      start,
      end,
    };
  });
  return { ...data, days };
};

export const convertSortParametersToSortingRules = (sortQuery?: string | number | SortType) => {
  if (sortQuery && typeof sortQuery === "object" && toPairs(sortQuery).length) {
    const getAntdSortFromShortString = (value: string) =>
      value === SortEnum.desc ? "descend" : "ascend";
    return mapObjIndexed(getAntdSortFromShortString, sortQuery);
  }
  return {};
};

export const mapUserCountryItemWithIcon = (
  item?: UserCountryItemType | SelectItem,
  locale?: string,
): UserCountryItemType => {
  const localeItem = countryIcons.find((el) => !!locale && el.value === locale);
  const countryItem = item as UserCountryItemType;
  return countryItem
    ? {
        ...countryItem,
        ...(localeItem?.icon && {
          icon: localeItem.icon,
        }),
      }
    : null;
};

export const getUserCountryItemByCountryCode = (
  items: UserCountryItemType[] = [],
  country = "",
): UserCountryItemType => {
  const countryItem = items.find((el) => el?.value === country);
  return mapUserCountryItemWithIcon(countryItem, countryItem?.value);
};

export const mapUserCountriesIcon = (
  items: UserCountryItemType[] | SelectItem[],
): UserCountryItemType[] => {
  return items.map((item) => {
    return mapUserCountryItemWithIcon(item, String(item?.value));
  });
};

export const mapUserCountriesLabel = (items: UserCountryItemType[]): UserCountryItemType[] => {
  return items.map((item) => {
    return item ? { ...item, label: item?.name || item.label } : null;
  });
};
