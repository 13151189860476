// Core
import { z } from "zod";

// Definitions
import { imageSchema } from "../../models/Image";
import type { FilterParamsType } from "../../models/Filter";
import { staticDataItemSchema } from "../staticData/models";
import { HttpRequestParams } from "models/Http";

export enum CartTabSelectEnumType {
  empty = "empty",
  all = "all",
  partially = "partially",
}

export enum CartStepsEnum {
  preview = 0,
  deliveryAddress = 1,
  mandate = 2,
}

export const serviceMethodSchema = z.object({
  src: z.string(),
  alt: z.string(),
});

export const cartProductImageSchema = z
  .object({
    thumb: imageSchema.nullable(),
    list: imageSchema.array().optional(),
  })
  .strict();

export enum CartProductPromoLabelValueType {
  empty = "",
  default = "items-in-stock",
  hot = "items-left",
  delivery = "delivery-in-hours",
  sale = "sale",
}

const cartProductPromoItemSchema = z
  .object({
    id: z.number().nullable(),
    value: z.enum([
      CartProductPromoLabelValueType.empty,
      CartProductPromoLabelValueType.default,
      CartProductPromoLabelValueType.hot,
      CartProductPromoLabelValueType.delivery,
      CartProductPromoLabelValueType.sale,
    ]),
    label: z.string(),
    image: imageSchema.nullable(),
  })
  .strict();

export const productPromoSchema = z
  .object({
    prime: cartProductPromoItemSchema.nullable(),
    general: cartProductPromoItemSchema.array().optional(),
    stock: cartProductPromoItemSchema.array().optional(),
  })
  .strict();

export const cartQuantitySchema = z.object({
  value: z.number().nullable(),
  step: z.number().nullable(),
  default: z.number().nullable(),
  available: z.number().nullable(),
});

export const cartPriceSchema = z
  .object({
    value: z.number().nullable(),
    country: z.string(),
  })
  .strict()
  .nullable();

export const cartTabProductScheme = z.object({
  id: z.number(),
  genericId: z.number(),
  articleId: z.number().nullable(),
  articleNo: z.string(),
  isActive: z.boolean(),
  isAvailable: z.boolean(),
  quantity: cartQuantitySchema.nullable(),
  images: cartProductImageSchema.nullable(),
  vatPercentage: z.number().nullable(),
  deliveryAt: z.number().nullable(),
  priceRecommended: cartPriceSchema,
  priceNonVat: cartPriceSchema,
  priceVat: cartPriceSchema,
  priceTotalVat: cartPriceSchema,
  priceTotalNonVat: cartPriceSchema,
  oldPriceNonVat: cartPriceSchema,
  oldVatPrice: cartPriceSchema,
  margin: z.number().nullable(),
  name: z.string(),
  brand: z.object({
    id: z.number().nullable(),
    name: z.string(),
    image: imageSchema,
  }),
  vehicleName: z.string(),
  pledge: cartPriceSchema,
  promotions: productPromoSchema.nullable(),
  isVinCompatibility: z.boolean(),
});

export const cartTabScheme = z.object({
  id: z.number(),
  selected: z
    .enum([CartTabSelectEnumType.empty, CartTabSelectEnumType.all, CartTabSelectEnumType.partially])
    .or(z.string()),
  isEditableTitle: z.boolean(),
  title: z.string(),
  regNumber: z.string(),
  deliveryDate: z.number().nullable(),
  products: z.array(cartTabProductScheme),
  vehicleType: z.number().nullable(),
  vehicleId: z.number().nullable(),
  isRepairEstimateAvailable: z.boolean(),
});

export const cartPreviewScheme = z.object({
  trackingId: z.string(),
  selected: z
    .enum([CartTabSelectEnumType.empty, CartTabSelectEnumType.all, CartTabSelectEnumType.partially])
    .or(z.string()),
  totalPrice: cartPriceSchema,
  vatPrice: cartPriceSchema,
  totalPriceVat: cartPriceSchema,
  vatPercentage: z.number().nullable(),
  deliveryDate: z.number().nullable(),
  checkoutAvailable: z.boolean(),
  tabs: z.array(cartTabScheme),
});

export const cartPaymentDeliveryScheme = z.object({
  firstName: z.string(),
  companyName: z.string(),
  surname: z.string(),
  street: z.string(),
  houseNumber: z.string(),
  city: z.string(),
  index: z.string().optional(),
  country: staticDataItemSchema.nullable(),
  additionalAddress: z.string().optional(),
  phonePrefix: staticDataItemSchema.nullable(),
  phoneNumber: z.string(),
});

export const cartPaymentDeliveryUpdateMutationScheme = z.object({
  data: cartPaymentDeliveryScheme,
});

export const cartParamsMetaScheme = z
  .object({
    kmtxSync: z.string().optional(),
  })
  .strict();

const cartRepairEstimateVehicleSchema = z
  .object({
    maker: z.string(),
    model: z.string(),
    engine: z.string(),
    regNumber: z.string(),
    vin: z.string(),
    mileage: z.number().nullable(),
  })
  .strict();

const cartRepairEstimateStatusSchema = z.object({
  id: z.number(),
  isActive: z.boolean(),
  disabled: z.boolean(),
  label: z.string(),
  value: z.string(),
});
const cartRepairEstimateStatusesSchema = z.object({
  canceled: cartRepairEstimateStatusSchema.nullable(),
  completed: cartRepairEstimateStatusSchema.nullable(),
  inProgress: cartRepairEstimateStatusSchema.nullable(),
  new: cartRepairEstimateStatusSchema.nullable(),
  pending: cartRepairEstimateStatusSchema.nullable(),
  waitingForApproval: cartRepairEstimateStatusSchema.nullable(),
});

const cartRepairEstimateClientSchema = z
  .object({
    name: z.string(),
    phoneNumber: z.string(),
    email: z.string(),
    notes: z.string(),
  })
  .strict();

export const cartRepairEstimateSchema = z.object({
  id: z.number(),
  vehicle: cartRepairEstimateVehicleSchema.nullable(),
  client: cartRepairEstimateClientSchema.nullable(),
  createdAt: z.number().nullable(),
  updatedAt: z.number().nullable(),
  deliveryAt: z.number().nullable(),
  orderId: z.number().nullable(),
  totalPrice: cartPriceSchema,
  totalPriceVat: cartPriceSchema,
  vatPrice: cartPriceSchema,
  vatPercentage: z.number().nullable(),
  priceParts: cartPriceSchema,
  priceLabors: cartPriceSchema,
  status: cartRepairEstimateStatusesSchema.nullable(),
});

export type CartRepairEstimateType = z.infer<typeof cartRepairEstimateSchema>;
export type ServiceMethodType = z.infer<typeof serviceMethodSchema>;
export type CartQuantityType = z.infer<typeof cartQuantitySchema>;
export type CartTabProductType = z.infer<typeof cartTabProductScheme>;
export type CartTabType = z.infer<typeof cartTabScheme>;
export type CartPreviewType = z.infer<typeof cartPreviewScheme>;
export type CartDeliveryType = z.infer<typeof cartPaymentDeliveryScheme>;
export type CartPaymentDeliveryUpdateMutationExecute = z.infer<
  typeof cartPaymentDeliveryUpdateMutationScheme
>;
export type CartParamsMetaType = z.infer<typeof cartParamsMetaScheme>;

export type FilterCartParamsType = FilterParamsType & {
  articleId?: number;
  productId?: number;
  vehicleId?: number;
};

export type CartParamsType = {
  id?: number;
  params?: FilterCartParamsType;
  data?: CartPreviewType | CartParamsType;
  meta?: CartParamsMetaType;
};

export type CartPreviewRepairEstimateCreateType = {
  basketTabId: number;
};

export type CartPreviewRepairEstimateCreateParamsType = HttpRequestParams<{
  basketTabId: number;
}>;

export type CartProductPromotionsType = z.infer<typeof productPromoSchema>;

const productQuantitySchema = z
  .object({
    value: z.number().nullable(),
    step: z.number().nullable(),
    default: z.number().nullable(),
    available: z.number().nullable(),
  })
  .strict();

export type CartProductQuantityType = z.infer<typeof productQuantitySchema>;

export type CartProductQuantitiesType = {
  quantity: CartProductQuantityType | null;
  fittingScore: number | null;
};

export type FilterCartProductsParamsType = FilterParamsType & {
  nodeId?: string | number;
  keyword?: string;
  oem?: string;
  vin?: string;
  articleId?: string;
};

export type CartProductUpdateType = {
  id?: number;
  quantity?: number | null;
  isActive?: boolean;
  articleId?: number;
  productId?: number;
};

export type CartProductParamsType = {
  id: number | string;
  params?: FilterCartProductsParamsType & {
    makerId?: number;
    modelId?: number;
  };
  data?: CartProductUpdateType;
};
