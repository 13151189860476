// Core
import React, { PropsWithChildren, ReactElement } from "react";

type ContentType = {
  isModal: boolean;
  onPageModals: Record<string, boolean>;
  setOnPageModal: (type: string, visible: boolean) => void;
  setIsModal: (value: boolean) => void;
};

type ModalContextProviderPropsType = {
  children: ReactElement;
};

const Context = React.createContext<ContentType>({
  isModal: false,
  onPageModals: {},
  setOnPageModal: () => {},
  setIsModal: () => {},
});

export const ModalContextProvider = ({
  children,
}: PropsWithChildren<ModalContextProviderPropsType>) => {
  const [isModal, setIsModal] = React.useState<boolean>(false);
  const [onPageModals, setOnPageModal] = React.useState<ContentType["onPageModals"]>({});
  const mounted = React.useRef<boolean>(true);

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <Context.Provider
      value={{
        isModal,
        onPageModals,
        setIsModal: (newIsModal) => {
          mounted.current && setIsModal(newIsModal);
        },
        setOnPageModal: (modalType, visible) => {
          mounted.current &&
            setOnPageModal((prev) => ({
              ...prev,
              [modalType]: visible,
            }));
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};
export const ModalContextConsumer = Context.Consumer;

export const useModalContext = () => React.useContext(Context);
