// Core
import { TFunction } from "next-i18next";

// Definitions
import type { LightListItemType } from "components/ui/LightList";
import type { LinkProps } from "components/common/Link";

// Component
import { Link } from "components/common/Link";

// Utils
import type { MenuNavItemData } from "data/footer";

type FooterNavModelProps = {
  items: MenuNavItemData[];
  t?: TFunction;
};

export const footerNavModelNew = (props: FooterNavModelProps): LightListItemType[] => {
  const { items, t } = props;
  return items.map((item) => {
    const textValue = t?.(String(item.text)) || "";
    const CellLink = (linkProps: LinkProps) => Link({ ...linkProps, size: "16", type: "default" });

    return {
      key: item.key,
      link: {
        href: item.href,
        component: CellLink,
        blank: item.asBlankLink,
      },
      text: textValue,
    };
  });
};
