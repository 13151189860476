// Core
import { z } from "zod";

// Utils
import { getFieldLabel } from "utils/forms";

export enum SearchBarFormEnum {
  type = "type",
  entity = "entity",
  search = "search",
  keyword = "keyword",
}

const regExps = {
  [SearchBarFormEnum.search]: new RegExp("^(?=.*\\p{L})|(?=.*[0-9])", "u"),
};

export const inputConfig = {
  [SearchBarFormEnum.search]: {
    placeholder: getFieldLabel("searchBar", true),
    pattern: regExps[SearchBarFormEnum.search],
  },
};

const initialChangeEmailFormShape = {
  type: null,
  entity: "",
  search: "",
  keyword: "",
};

export const searchBarForm = {
  shape: initialChangeEmailFormShape,
  schema: () =>
    z.object({
      [SearchBarFormEnum.type]: z.number().nullable(),
      [SearchBarFormEnum.entity]: z.string(),
      [SearchBarFormEnum.search]: z
        .string()
        .trim()
        .regex(inputConfig[SearchBarFormEnum.search].pattern),
      [SearchBarFormEnum.keyword]: z.string(),
    }),
};
