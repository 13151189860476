// Core
import { forwardRef, BaseSyntheticEvent } from "react";
import cx from "classnames";

// Components
import { Input } from "antd";
import InputMask from "react-input-mask";

// Definitions
import type { ReactNode } from "react";
import type { InputProps } from "antd";
import type { InputRefBaseType } from "models/Base";

// Utils
import st from "./Styles.module.less";

const FORMAT_CHARS = { "1": "." };
const PHONE_INPUT_MASK = "11 11 11 11 11 11 1";

type InputMaskedType = {
  hasPhoneMask?: boolean;
  maskPlaceholder?: string;
  unmaskedValue?: boolean;
};

export type InputBaseType = InputProps &
  InputMaskedType & {
    placeholder?: string;
    maxLength?: number;
    autoComplete?: "on" | "off";
    hasAutoComplete?: boolean;
    hasDisabled?: boolean;
    size?: "middle" | "large" | "small";
    shape?: "reg-num";
    isTextCentered?: boolean;
    hintText?: ReactNode;
    onChange?: (event: BaseSyntheticEvent) => void;
  };

export const InputBase = forwardRef<InputRefBaseType, InputBaseType>((props, ref) => {
  const {
    className,
    hasAutoComplete = false,
    hasDisabled = false,
    size = "large",
    type = "text",
    shape,
    maxLength = 100,
    placeholder = "Input",
    isTextCentered = false,
    hasPhoneMask = false,
    maskPlaceholder = null,
    unmaskedValue = false,
    onChange,
    value = "",
    hintText = null,
    onBlur,
    ...rest
  } = props;
  const autoCompleteValue = hasAutoComplete ? "on" : "off";

  const inputStyle = cx(
    st.input,
    {
      [st.disabled]: hasDisabled,
      [st.number]: type === "number",
      [st.centered]: isTextCentered,
    },
    shape && [st[`shape-${shape}`]],
    className,
  );

  const onChangeMaskInput = (event: BaseSyntheticEvent) => {
    const maskValue = event.target?.value;
    event.target.value = unmaskedValue ? maskValue.replace(/[^\d|+]/g, "") : maskValue;
    onChange && onChange(event);
  };

  return !hasPhoneMask ? (
    <>
      <Input
        {...rest}
        type={type}
        className={inputStyle}
        size={size}
        autoComplete={autoCompleteValue}
        disabled={hasDisabled}
        ref={ref}
        maxLength={maxLength}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {hintText}
    </>
  ) : (
    <>
      <InputMask
        mask={PHONE_INPUT_MASK}
        maskChar={maskPlaceholder}
        formatChars={FORMAT_CHARS}
        value={value}
        onChange={onChangeMaskInput}
        onBlur={onBlur}
      >
        {
          // @ts-ignore
          (inputProps: InputBaseType) => (
            <Input
              {...inputProps}
              {...rest}
              type={type}
              className={inputStyle}
              size={size}
              autoComplete={autoCompleteValue}
              ref={ref}
              maxLength={maxLength}
              placeholder={placeholder}
            />
          )
        }
      </InputMask>
      {hintText}
    </>
  );
});
InputBase.displayName = "InputBase";
