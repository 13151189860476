// Core
import { i18n } from "next-i18next";

// Definition
import { NotificationMessage } from "components/ui/Notification/Notification.types";

// Utils
import {
  httpErrorMessage,
  httpErrorMessageNotFound,
  httpErrorMessageSearch,
  httpErrorMessageNoData,
  httpSuccessMessageGeneric,
  httpErrorMessageGeneric,
  httpSuccessMessageCartProduct,
  httpSuccessMessageUpdate,
  httpErrorMessageNotFoundSavedBasket,
  httpSuccessMessageCartProductUpdate,
  httpSuccessMessageCustomerCreate,
  httpSuccessMessageCustomerUpdate,
  httpSuccessMessageAddSavedBasketProduct,
  httpSuccessMessageUpdateSavedBasketTitle,
  httpSuccessMessageRemoveSavedBasketProduct,
  httpSuccessMessageCompleteSavedBasket,
  httpSuccessMessageSavedBasketProductUpdate,
  httpErrorMessageSavedBasketProductUpdate,
  httpErrorMessageNotFoundSavedBasketProductUpdate,
  httpErrorMessageNotFoundCartProduct,
  httpErrorMessageCartProductUpdate,
  httpSuccessMessageEmailSubscription,
  httpErrorMessageEmailSubscription,
  httpErrorMessageOrderError,
  httpSuccessMessageRemoveCartPreviewVehicle,
  httpSuccessMessageResetPasswordEmail,
  httpErrorMessageInvalidRequest,
  httpErrorMessageCartProductQuantityMax,
  httpSuccessMessageVehicleUpdate,
  httpSuccessMessageStatusUpdate,
  ErrorPhotoNotValidSize,
  ErrorPhotoNotValidFile,
  httpErrorForbiddenMessage,
  httpSuccessMessageAddRepairEstimateProduct,
  httpSuccessMessageAddRepairEstimateLabor,
  httpSuccessMessageUpdateRepairEstimateLabor,
} from "utils/constants";

export const getHttpErrorMessageWithTranslations = (): NotificationMessage => ({
  title: i18n?.t("common:errors.title") || httpErrorMessage.title,
  desc: i18n?.t("common:errors.desc") || httpErrorMessage.desc,
});
export const getHttpErrorForbiddenMessageWithTranslations = (): NotificationMessage => ({
  title: i18n?.t("common:errorsForbidden.title") || httpErrorForbiddenMessage.title,
  desc: i18n?.t("common:errorsForbidden.desc") || httpErrorForbiddenMessage.desc,
});
export const getHttpErrorMessageNotFoundUserWithTranslations = (): NotificationMessage => ({
  title: i18n?.t("common:errorsNotFoundUser.title") || httpErrorMessageNotFound.title,
  desc: i18n?.t("common:errorsNotFoundUser.desc") || httpErrorMessageNotFound.desc,
});
export const getHttpErrorMessageNotFoundProductWithTranslations = (): NotificationMessage => ({
  title: i18n?.t("common:errorsNotFoundProduct.title") || httpErrorMessageNotFound.title,
  desc: i18n?.t("common:errorsNotFoundProduct.desc") || httpErrorMessageNotFound.desc,
});
export const getHttpErrorMessageSearchWithTranslations = (): NotificationMessage => ({
  title: i18n?.t("common:errorsSearch.title") || httpErrorMessageSearch.title,
  desc: i18n?.t("common:errorsSearch.desc") || httpErrorMessageSearch.desc,
});
export const getHttpErrorMessageNoDataWithTranslations = (): NotificationMessage => ({
  title: i18n?.t("common:errorsNoData.title") || httpErrorMessageNoData.title,
  desc: i18n?.t("common:errorsNoData.desc") || httpErrorMessageNoData.desc,
});
export const getHttpErrorMessageOrderWithTranslations = (
  errorCode: string,
): NotificationMessage => ({
  title:
    i18n?.t(`cart:notifications.custom422.${errorCode}.title`) || httpErrorMessageOrderError.title,
  desc:
    i18n?.t(`cart:notifications.custom422.${errorCode}.desc`) || httpErrorMessageOrderError.desc,
});
export const getHttpErrorMessageNoPersonalManagerWithTranslations = (): NotificationMessage => ({
  title:
    i18n?.t("personal-manager:operatorAssesment.noManagerError.title") ||
    httpErrorMessageNoData.title,
  desc:
    i18n?.t("personal-manager:operatorAssesment.noManagerError.desc") ||
    httpErrorMessageNoData.desc,
});

export const getHttpErrorMessageHashInvalidWithTranslations = (): NotificationMessage => ({
  title:
    i18n?.t("signin:resetPassword.error.hashInvalid.title") || httpErrorMessageInvalidRequest.title,
  desc:
    i18n?.t("signin:resetPassword.error.hashInvalid.desc") || httpErrorMessageInvalidRequest.desc,
});
export const getHttpErrorMessageHashExpiredWithTranslations = (): NotificationMessage => ({
  title:
    i18n?.t("signin:resetPassword.error.hashExpired.title") || httpErrorMessageInvalidRequest.title,
  desc:
    i18n?.t("signin:resetPassword.error.hashExpired.desc") || httpErrorMessageInvalidRequest.desc,
});
export const getHttpSuccessMessageCartProductWithTranslations = (): NotificationMessage => ({
  title: i18n?.t("common:notifications.createCart.title") || httpSuccessMessageCartProduct.title,
  desc: i18n?.t("common:notifications.createCart.desc") || httpSuccessMessageCartProduct.desc,
});
export const getHttpErrorMessageProductQuantityMaxWithTranslations = (): NotificationMessage => ({
  title:
    i18n?.t("common:notifications.errorMaxQuantity.title") ||
    httpErrorMessageCartProductQuantityMax.title,
  desc:
    i18n?.t("common:notifications.errorMaxQuantity.desc") ||
    httpErrorMessageCartProductQuantityMax.desc,
});
export const getHttpSuccessMessageWithTranslations = (): NotificationMessage => ({
  title: i18n?.t("common:notifications.update.title") || httpSuccessMessageUpdate.title,
  desc: i18n?.t("common:notifications.update.desc") || httpSuccessMessageUpdate.desc,
});
export const getHttpSuccessMessageCallBackWithTranslations = (): NotificationMessage => ({
  title: i18n?.t("intro:callBack.success.title") || httpSuccessMessageGeneric.title,
  desc: i18n?.t("intro:callBack.success.desc") || httpSuccessMessageGeneric.desc,
});
export const getHttpSuccessMessageCartProductUpdateWithTranslations = (): NotificationMessage => ({
  title:
    i18n?.t("cart:notifications.updateProductCart.title") ||
    httpSuccessMessageCartProductUpdate.title,
  desc:
    i18n?.t("cart:notifications.updateProductCart.desc") ||
    httpSuccessMessageCartProductUpdate.desc,
});
export const getHttpSuccessMessageResetPasswordEmailWithTranslations = (): NotificationMessage => ({
  title:
    i18n?.t("signin:modals.resetPasswordEmail.title") || httpSuccessMessageResetPasswordEmail.title,
  desc:
    i18n?.t("signin:modals.resetPasswordEmail.desc") || httpSuccessMessageResetPasswordEmail.desc,
});
export const getHttpErrorMessageCartProductUpdateWithTranslations = (): NotificationMessage => ({
  title:
    i18n?.t("cart:notifications.updateProductCartError.title") ||
    httpErrorMessageCartProductUpdate.title,
  desc:
    i18n?.t("cart:notifications.updateProductCartError.desc") ||
    httpErrorMessageCartProductUpdate.desc,
});
export const getHttpErrorMessageNotFoundCartProductUpdateWithTranslations =
  (): NotificationMessage => ({
    title:
      i18n?.t("cart:notifications.notFoundProduct.title") ||
      httpErrorMessageNotFoundCartProduct.title,
    desc:
      i18n?.t("cart:notifications.notFoundProduct.desc") ||
      httpErrorMessageNotFoundCartProduct.desc,
  });

export const getHttpSuccessMessageCustomerCreateWithTranslations = (): NotificationMessage => ({
  title:
    i18n?.t("customers:notifications.createCustomer.title") ||
    httpSuccessMessageCustomerCreate.title,
  desc:
    i18n?.t("customers:notifications.createCustomer.desc") || httpSuccessMessageCustomerCreate.desc,
});

export const getHttpSuccessMessageCustomerUpdateWithTranslations = (): NotificationMessage => ({
  title:
    i18n?.t("customers:notifications.updateCustomer.title") ||
    httpSuccessMessageCustomerUpdate.title,
  desc:
    i18n?.t("customers:notifications.updateCustomer.desc") || httpSuccessMessageCustomerUpdate.desc,
});

export const getHttpSuccessMessageAddSavedBasketProductWithTranslations =
  (): NotificationMessage => ({
    title:
      i18n?.t("saved-baskets:notifications.addProduct.title") ||
      httpSuccessMessageAddSavedBasketProduct.title,
    desc:
      i18n?.t("saved-baskets:notifications.addProduct.desc") ||
      httpSuccessMessageAddSavedBasketProduct.desc,
  });

export const getHttpSuccessMessageUpdateSavedBasketTitletWithTranslations =
  (): NotificationMessage => ({
    title:
      i18n?.t("saved-baskets:notifications.updateTitle.title") ||
      httpSuccessMessageUpdateSavedBasketTitle.title,
    desc:
      i18n?.t("saved-baskets:notifications.updateTitle.desc") ||
      httpSuccessMessageUpdateSavedBasketTitle.desc,
  });

export const getHttpSuccessMessageRemoveSavedBasketProductWithTranslations =
  (): NotificationMessage => ({
    title:
      i18n?.t("saved-baskets:notifications.removeProduct.title") ||
      httpSuccessMessageRemoveSavedBasketProduct.title,
    desc:
      i18n?.t("saved-baskets:notifications.removeProduct.desc") ||
      httpSuccessMessageRemoveSavedBasketProduct.desc,
  });

export const getHttpErrorMessageNotFoundSavedBasketWithTranslations = (): NotificationMessage => ({
  title:
    i18n?.t("saved-baskets:notifications.notFound.title") ||
    httpErrorMessageNotFoundSavedBasket.title,
  desc:
    i18n?.t("saved-baskets:notifications.notFound.desc") ||
    httpErrorMessageNotFoundSavedBasket.desc,
});

export const getHttpSuccessMessageCompleteSavedBasketWithTranslations =
  (): NotificationMessage => ({
    title:
      i18n?.t("saved-baskets:notifications.complete.title") ||
      httpSuccessMessageCompleteSavedBasket.title,
    desc:
      i18n?.t("saved-baskets:notifications.complete.desc") ||
      httpSuccessMessageCompleteSavedBasket.desc,
  });

export const getHttpSuccessMessageSavedBasketProductUpdateWithTranslations =
  (): NotificationMessage => ({
    title:
      i18n?.t("saved-baskets:notifications.updateProduct.title") ||
      httpSuccessMessageSavedBasketProductUpdate.title,
    desc:
      i18n?.t("saved-baskets:notifications.updateProduct.desc") ||
      httpSuccessMessageSavedBasketProductUpdate.desc,
  });

export const getHttpSuccessMessageRemoveCartPreviewVehicleWithTranslations =
  (): NotificationMessage => ({
    title:
      i18n?.t("cart:notifications.removeVehicle.title") ||
      httpSuccessMessageRemoveCartPreviewVehicle.title,
    desc:
      i18n?.t("cart:notifications.removeVehicle.desc") ||
      httpSuccessMessageRemoveCartPreviewVehicle.desc,
  });

export const getHttpErrorMessageRemoveCartPreviewVehicleWithTranslations =
  (): NotificationMessage => ({
    title:
      i18n?.t("cart:notifications.removeVehicleError.title") ||
      httpSuccessMessageRemoveCartPreviewVehicle.title,
    desc:
      i18n?.t("cart:notifications.removeVehicleError.desc") ||
      httpSuccessMessageRemoveCartPreviewVehicle.desc,
  });

export const getHttpSuccessMessageSubscriptionEmailWithTranslations = (): NotificationMessage => ({
  title:
    i18n?.t("common:notifications.successEmailSubscription.title") ||
    httpSuccessMessageEmailSubscription.title,
  desc:
    i18n?.t("common:notifications.successEmailSubscription.desc") ||
    httpSuccessMessageEmailSubscription.desc,
});

export const getHttpErrorMessageSubscriptionEmailWithTranslations = (): NotificationMessage => ({
  title:
    i18n?.t("common:notifications.errorEmailSubscription.title") ||
    httpErrorMessageEmailSubscription.title,
  desc:
    i18n?.t("common:notifications.errorEmailSubscription.desc") ||
    httpErrorMessageEmailSubscription.desc,
});

export const getHttpErrorMessageSavedBasketProductUpdateWithTranslations =
  (): NotificationMessage => ({
    title:
      i18n?.t("saved-baskets:notifications.updateProductError.title") ||
      httpErrorMessageSavedBasketProductUpdate.title,
    desc:
      i18n?.t("saved-baskets:notifications.updateProductError.desc") ||
      httpErrorMessageSavedBasketProductUpdate.desc,
  });

export const getHttpErrorMessageNotFoundSavedBasketProductUpdateWithTranslations =
  (): NotificationMessage => ({
    title:
      i18n?.t("saved-baskets:notifications.notFoundProduct.title") ||
      httpErrorMessageNotFoundSavedBasketProductUpdate.title,
    desc:
      i18n?.t("saved-baskets:notifications.notFoundProduct.desc") ||
      httpErrorMessageNotFoundSavedBasketProductUpdate.desc,
  });

export const getHttpErrorMessageProductQuantityWithTranslations = (): NotificationMessage => ({
  title:
    i18n?.t("saved-baskets:notifications.notFoundProduct.title") ||
    httpErrorMessageNotFoundSavedBasketProductUpdate.title,
  desc:
    i18n?.t("saved-baskets:notifications.notFoundProduct.desc") ||
    httpErrorMessageNotFoundSavedBasketProductUpdate.desc,
});

export const getHttpErrorMessageNotFoundRepairEstimateWithTranslations =
  (): NotificationMessage => ({
    title:
      i18n?.t("repair-estimates:notifications.notFound.title") || httpErrorMessageNotFound.title,
    desc: i18n?.t("repair-estimates:notifications.notFound.desc") || httpErrorMessageNotFound.desc,
  });

export const getHttpSuccessMessageRepairEstimateVehicleUpdateWithTranslations =
  (): NotificationMessage => ({
    title:
      i18n?.t("repair-estimates:notifications.updateVehicle.title") ||
      httpSuccessMessageVehicleUpdate.title,
    desc:
      i18n?.t("repair-estimates:notifications.updateVehicle.desc") ||
      httpSuccessMessageVehicleUpdate.desc,
  });

export const getHttpSuccessMessageRepairEstimateStatusUpdateWithTranslations =
  (): NotificationMessage => ({
    title:
      i18n?.t("repair-estimates:notifications.updateStatus.title") ||
      httpSuccessMessageStatusUpdate.title,
    desc:
      i18n?.t("repair-estimates:notifications.updateStatus.desc") ||
      httpSuccessMessageStatusUpdate.desc,
  });

export const getHttpSuccessMessageAddRepairEstimateProductWithTranslations =
  (): NotificationMessage => ({
    title:
      i18n?.t("repair-estimates:notifications.addProduct.title") ||
      httpSuccessMessageAddRepairEstimateProduct.title,
    desc:
      i18n?.t("repair-estimates:notifications.addProduct.desc") ||
      httpSuccessMessageAddRepairEstimateProduct.desc,
  });

export const getHttpSuccessMessageRepairEstimateLaborCreateWithTranslations =
  (): NotificationMessage => ({
    title:
      i18n?.t("repair-estimates:notifications.createLabor.title") ||
      httpSuccessMessageAddRepairEstimateLabor.title,
    desc:
      i18n?.t("repair-estimates:notifications.createLabor.desc") ||
      httpSuccessMessageAddRepairEstimateLabor.desc,
  });

export const getHttpSuccessMessageRepairEstimateLaborUpdateWithTranslations =
  (): NotificationMessage => ({
    title:
      i18n?.t("repair-estimates:notifications.updateLabor.title") ||
      httpSuccessMessageUpdateRepairEstimateLabor.title,
    desc:
      i18n?.t("repair-estimates:notifications.updateLabor.desc") ||
      httpSuccessMessageUpdateRepairEstimateLabor.desc,
  });

export const getHttpErrorMessageNotFoundRepairEstimateLaborWithTranslations =
  (): NotificationMessage => ({
    title:
      i18n?.t("repair-estimates:notifications.notFoundLabor.title") ||
      httpErrorMessageNotFound.title,
    desc:
      i18n?.t("repair-estimates:notifications.notFoundLabor.desc") || httpErrorMessageNotFound.desc,
  });

export const getHttpSuccessMessageRepairEstimateLaborRemoveWithTranslations =
  (): NotificationMessage => ({
    title:
      i18n?.t("repair-estimates:notifications.removeLabor.title") || httpErrorMessageNotFound.title,
    desc:
      i18n?.t("repair-estimates:notifications.removeLabor.desc") || httpErrorMessageNotFound.desc,
  });

export const getErrorMessagePhotoNotValidSize = (): NotificationMessage => ({
  title: i18n?.t("common:notifications.errorPhotoSize.title") || ErrorPhotoNotValidSize.title,
  desc: i18n?.t("common:notifications.errorPhotoSize.desc") || ErrorPhotoNotValidSize.desc,
});

export const getErrorMessagePhotoNotValidFile = (): NotificationMessage => ({
  title: i18n?.t("common:notifications.errorPhotoFile.title") || ErrorPhotoNotValidFile.title,
  desc: i18n?.t("common:notifications.errorPhotoFile.desc") || ErrorPhotoNotValidFile.desc,
});

export const getHttpSuccessMessageResetPasswordWithTranslations = (): NotificationMessage => ({
  title:
    i18n?.t("common:notifications.resetPasswordUpdate.title") || httpSuccessMessageUpdate.title,
  desc: i18n?.t("common:notifications.resetPasswordUpdate.desc") || httpSuccessMessageUpdate.desc,
});

export const getHttpSuccessMessageRepairEstimateDiscountUpdateWithTranslations =
  (): NotificationMessage => ({
    title:
      i18n?.t("repair-estimates:notifications.updatedDiscount.title") ||
      httpSuccessMessageUpdate.title,
    desc:
      i18n?.t("repair-estimates:notifications.updatedDiscount.desc") ||
      httpSuccessMessageUpdate.desc,
  });

export const getHttpErrorMessageNotFoundWithTranslations = (): NotificationMessage => ({
  title: i18n?.t("common:notifications.notFound.title") || httpErrorMessageNotFound.title,
  desc: i18n?.t("common:notifications.notFound.desc") || httpErrorMessageNotFound.desc,
});
export const getHttpSuccessMessageAuthMonduWithTranslations = (): NotificationMessage => ({
  title: i18n?.t("orders:notifications.authMondu.success.title") || httpSuccessMessageGeneric.title,
  desc: i18n?.t("orders:notifications.authMondu.success.desc") || httpSuccessMessageGeneric.desc,
});
export const getHttpMessageAuthMonduCancelWithTranslations = (): NotificationMessage => ({
  title: i18n?.t("orders:notifications.authMondu.cancel.title") || httpErrorMessageGeneric.title,
  desc: i18n?.t("orders:notifications.authMondu.cancel.desc") || httpErrorMessageGeneric.desc,
});
export const getHttpMessageAuthMonduDeclineWithTranslations = (): NotificationMessage => ({
  title: i18n?.t("orders:notifications.authMondu.decline.title") || httpErrorMessageGeneric.title,
  desc: i18n?.t("orders:notifications.authMondu.decline.desc") || httpErrorMessageGeneric.desc,
});
