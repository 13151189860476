import { CountryCodesEnum } from "bus/profile/models";

export type CurrencyConfigType = {
  symbol: string;
  currencyIso: string;
  decimal: string;
  separator: string;
  precision: number;
  pattern: string;
  negativePattern: string;
};

export const currencyConfig: Record<string, CurrencyConfigType> = {
  [CountryCodesEnum.EN]: {
    symbol: "£",
    currencyIso: "GBP",
    decimal: ",",
    separator: "",
    precision: 2,
    pattern: "!#",
    negativePattern: "-!#",
  },
  [CountryCodesEnum.FR]: {
    symbol: "€",
    currencyIso: "EUR",
    decimal: ",",
    separator: " ",
    precision: 2,
    pattern: "# !",
    negativePattern: "-# !",
  },
  [CountryCodesEnum.DE]: {
    symbol: "€",
    currencyIso: "EUR",
    decimal: ",",
    separator: ".",
    precision: 2,
    pattern: "# !",
    negativePattern: "-# !",
  },
  [CountryCodesEnum.AT]: {
    symbol: "€",
    currencyIso: "EUR",
    decimal: ",",
    separator: ".",
    precision: 2,
    pattern: "# !",
    negativePattern: "-# !",
  },
  [CountryCodesEnum.NL]: {
    symbol: "€",
    currencyIso: "EUR",
    decimal: ",",
    separator: ".",
    precision: 2,
    pattern: "# !",
    negativePattern: "-# !",
  },
};
