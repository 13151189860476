// Core
import { PropsWithChildren, ReactElement, createContext, useContext } from "react";

// Definitions
import type { ProfileType } from "bus/profile/models";

export type ProfileContextType = ProfileType | null;

type ContentType = {
  profile: ProfileContextType;
};

type ProfileProviderPropsType = {
  profile: ProfileContextType;
  children: ReactElement;
};

const Context = createContext<ContentType>({
  profile: null,
});

export const ProfileContextProvider = ({
  children,
  profile,
}: PropsWithChildren<ProfileProviderPropsType>) => {
  return <Context.Provider value={{ profile }}>{children}</Context.Provider>;
};

export const useProfileContext = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useProfileContext should only be called inside ProfileContextProvider");
  }

  return context;
};
