// Definitions
import type { CartPreviewType } from "bus/cart/models";
import type { OrdersCheckoutType, OrdersCheckoutOrderCreate } from "bus/orders/models";

export enum GTMTypesEnum {
  registration = "registration",
  authorization = "authorization",
  search = "search",
  selector_car = "selector_car",
  catalog = "catalog",
  show_price = "show_price",
  add_to_cart = "add_to_cart",
  begin_checkout = "begin_checkout",
  purchase = "purchase",
  add_tab = "add_tab",
  close_tab = "close_tab",
  tab_click = "tab_click",
  consent_mode_option = "consent_mode_option",
  consent_mode_def = "consent_mode_def",
}

export enum PayloadKeysEnum {
  parameters = "parameters",
  ecommerce = "ecommerce",
}

export enum ParametersEnum {
  user_id = "user_id",
  visitor_type = "visitor_type",
  registration_step = "registration_step",
  query = "query",
  car_id = "car_id",
  basket_id = "basket_id",
  event_action = "event_action",
  event_object = "event_object",
  parent_id = "parent_id",
  child_id = "child_id",
  shipping_date = "shipping_date",
  route_name = "route_name",
  free_search = "free_search",
  necessary = "necessary",
  statistics = "statistics",
  ad_storage = "ad_storage",
  analytics_storage = "analytics_storage",
  ad_storage_def = "ad_storage_def",
  analytics_storage_def = "analytics_storage_def",
}

export enum EcommerceEnum {
  currency = "currency",
  value = "value",
  items = "items",
  transaction_id = "transaction_id",
  shipping = "shipping",
}

export enum EcommerceItemsEnum {
  item_id = "item_id",
  item_name = "item_name",
  item_brand = "item_brand",
  item_category = "item_category",
  item_category2 = "item_category2",
  item_variant = "item_variant",
  price = "price",
  quantity = "quantity",
}

export enum VisitorTypeEnum {
  logged_user = "logged_user",
  registered_user = "registered_user",
  unknown_user = "unknown_user",
}

export enum SelectorCarEventActionEnum {
  car = "car",
  kba = "kba",
}

export enum SelectorCarEventObjectEnum {
  popup = "popup",
  base = "base",
}

export enum CatalogEventActionEnum {
  click_open = "click_open",
  click_close = "click_close",
}

export enum CatalogEventObjectEnum {
  sidebar = "sidebar",
  main = "main",
}

export enum ShowPriceEventActionEnum {
  show_price = "show_price",
  hide_price = "hide_price",
}

export enum CatalogTabCloseEventActionEnum {
  icon = "icon",
  limit = "limit",
}

export enum RouteNameEnum {
  product = "product",
  catalog = "catalog",
  cart = "cart",
}

export enum ConsentEnum {
  granted = "granted",
  denied = "denied",
}

export type EventPayloadAuthorizationType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.user_id]: number;
    [ParametersEnum.visitor_type]: VisitorTypeEnum;
  };
};
export type EventAuthorizationDataType = {
  [ParametersEnum.user_id]: number;
};

export type EventPayloadRegistrationType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.user_id]: number;
    [ParametersEnum.visitor_type]: VisitorTypeEnum;
    [ParametersEnum.registration_step]: number;
  };
};
export type EventRegistrationDataType = {
  [ParametersEnum.user_id]?: number;
  [ParametersEnum.visitor_type]?: VisitorTypeEnum;
  [ParametersEnum.registration_step]: number;
};

export type EventPayloadSearchType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.query]: string;
  };
};
export type EventSearchDataType = {
  [ParametersEnum.query]: string;
};

export type EventPayloadSelectorCarType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.car_id]: number;
    [ParametersEnum.event_action]: SelectorCarEventActionEnum;
    [ParametersEnum.event_object]: SelectorCarEventObjectEnum;
  };
};
export type EventSelectorCarDataType = {
  [ParametersEnum.car_id]: number;
  [ParametersEnum.event_action]?: SelectorCarEventActionEnum;
  [ParametersEnum.event_object]?: SelectorCarEventObjectEnum;
};

export type EventPayloadCatalogType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.parent_id]: number;
    [ParametersEnum.child_id]: number;
    [ParametersEnum.event_action]: CatalogEventActionEnum;
    [ParametersEnum.event_object]: CatalogEventObjectEnum;
  };
};
export type EventCatalogDataType = {
  [ParametersEnum.parent_id]?: number;
  [ParametersEnum.child_id]?: number;
  [ParametersEnum.event_action]?: CatalogEventActionEnum;
  [ParametersEnum.event_object]?: CatalogEventObjectEnum;
};

export type EventPayloadShowPriceType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.event_action]: ShowPriceEventActionEnum;
  };
};
export type EventShowPriceDataType = {
  [ParametersEnum.event_action]: ShowPriceEventActionEnum;
};

export type EventCatalogTabDataType = {
  [ParametersEnum.car_id]: number | string;
};
export type EventCatalogTabPayloadType = {
  [PayloadKeysEnum.parameters]: EventCatalogTabDataType;
};

export type EventCatalogCloseTabDataType = EventCatalogTabDataType & {
  [ParametersEnum.event_action]?: CatalogTabCloseEventActionEnum;
};
export type EventCatalogCloseTabPayloadType = {
  [PayloadKeysEnum.parameters]: EventCatalogCloseTabDataType;
};

export type EventPayloadPurchaseItemsType = {
  [EcommerceItemsEnum.item_id]: number;
  [EcommerceItemsEnum.item_name]: string;
  [EcommerceItemsEnum.item_brand]: string;
  [EcommerceItemsEnum.item_category]: number;
  [EcommerceItemsEnum.price]: number;
  [EcommerceItemsEnum.quantity]: number;
  [EcommerceItemsEnum.item_variant]: boolean;
  [EcommerceItemsEnum.item_category2]?: number;
};
export type EventPayloadAddToCartType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.shipping_date]: number;
    [ParametersEnum.route_name]?: RouteNameEnum;
    [ParametersEnum.car_id]: string;
  };
  [PayloadKeysEnum.ecommerce]: {
    [EcommerceEnum.currency]: string;
    [EcommerceEnum.value]: number;
    [EcommerceEnum.items]: EventPayloadPurchaseItemsType[];
  };
};
export type EventPayloadPurchaseType = {
  [PayloadKeysEnum.parameters]: {
    [ParametersEnum.shipping_date]: number;
    [ParametersEnum.route_name]?: RouteNameEnum;
    [ParametersEnum.basket_id]: string;
  };
  [PayloadKeysEnum.ecommerce]: {
    [EcommerceEnum.currency]: string;
    [EcommerceEnum.value]: number;
    [EcommerceEnum.items]: EventPayloadPurchaseItemsType[];
    [EcommerceEnum.shipping]?: number;
    [EcommerceEnum.transaction_id]?: string;
  };
};
export type EventConsentOptionPayloadType = {
  [ParametersEnum.ad_storage]: ConsentEnum;
  [ParametersEnum.analytics_storage]: ConsentEnum;
};
export type EventConsentDefPayloadType = {
  [ParametersEnum.ad_storage_def]: ConsentEnum;
  [ParametersEnum.analytics_storage_def]: ConsentEnum;
};

export type EventAddToCartDataType = {
  cart: CartPreviewType;
  product: {
    vehicleId?: number | null;
    plateNumber?: string;
    tabType?: number | null;
    articleId: number | null;
    quantity?: number | null;
    fittingScore?: number | null;
  };
  route: RouteNameEnum;
};
export type EventBeginCheckoutDataType = {
  cart: CartPreviewType;
};
export type EventPurchaseDataType = {
  cart: CartPreviewType;
  paymentProducts?: OrdersCheckoutType;
  paymentOrder?: OrdersCheckoutOrderCreate;
};
export type EventConsentOptionDataType = {
  [ParametersEnum.necessary]: boolean;
  [ParametersEnum.statistics]: boolean;
};
export type EventConsentDefDataType = {
  [ParametersEnum.necessary]: boolean;
  [ParametersEnum.statistics]: boolean;
};

export type EventPayloadType = {
  [GTMTypesEnum.authorization]: EventPayloadAuthorizationType;
  [GTMTypesEnum.registration]: EventPayloadRegistrationType;
  [GTMTypesEnum.search]: EventPayloadSearchType;
  [GTMTypesEnum.selector_car]: EventPayloadSelectorCarType;
  [GTMTypesEnum.catalog]: EventPayloadCatalogType;
  [GTMTypesEnum.show_price]: EventPayloadShowPriceType;
  [GTMTypesEnum.add_to_cart]: EventPayloadAddToCartType;
  [GTMTypesEnum.begin_checkout]: EventPayloadPurchaseType;
  [GTMTypesEnum.purchase]: EventPayloadPurchaseType;
  [GTMTypesEnum.add_tab]: EventCatalogTabPayloadType;
  [GTMTypesEnum.close_tab]: EventCatalogCloseTabPayloadType;
  [GTMTypesEnum.tab_click]: EventCatalogTabPayloadType;
  [GTMTypesEnum.consent_mode_option]: EventConsentOptionPayloadType;
  [GTMTypesEnum.consent_mode_def]: EventConsentDefPayloadType;
};

export type EventsDataType = {
  [GTMTypesEnum.authorization]: EventAuthorizationDataType;
  [GTMTypesEnum.registration]: EventRegistrationDataType;
  [GTMTypesEnum.search]: EventSearchDataType;
  [GTMTypesEnum.selector_car]: EventSelectorCarDataType;
  [GTMTypesEnum.catalog]: EventCatalogDataType;
  [GTMTypesEnum.show_price]: EventShowPriceDataType;
  [GTMTypesEnum.add_to_cart]: EventAddToCartDataType;
  [GTMTypesEnum.begin_checkout]: EventBeginCheckoutDataType;
  [GTMTypesEnum.purchase]: EventPurchaseDataType;
  [GTMTypesEnum.add_tab]: EventCatalogTabDataType;
  [GTMTypesEnum.close_tab]: EventCatalogCloseTabDataType;
  [GTMTypesEnum.tab_click]: EventCatalogTabDataType;
  [GTMTypesEnum.consent_mode_option]: EventConsentOptionDataType;
  [GTMTypesEnum.consent_mode_def]: EventConsentDefDataType;
};

export type EventsDataUnionType =
  | EventAuthorizationDataType
  | EventRegistrationDataType
  | EventSearchDataType
  | EventSelectorCarDataType
  | EventCatalogDataType
  | EventShowPriceDataType
  | EventAddToCartDataType
  | EventPurchaseDataType
  | EventCatalogTabDataType
  | EventCatalogCloseTabDataType
  | EventConsentOptionDataType
  | EventConsentDefDataType;

export interface GTMServiceInterface {
  event(name: GTMTypesEnum, data: EventsDataType[GTMTypesEnum]): void;
}

export interface GTMSegmentBuilderInterface<T extends GTMTypesEnum = GTMTypesEnum> {
  makeSegment(data: EventsDataUnionType): EventPayloadType[T];
}

export type GTMConfigType = {
  gtmId: string;
  eventsMap: Map<GTMTypesEnum, GTMSegmentBuilderInterface>;
  gtmArgs?: {
    dataLayer?: object;
    dataLayerName?: string;
  };
  checkEventsConsentGranted?: (name: GTMTypesEnum) => boolean;
  active?: boolean;
};
