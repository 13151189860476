// Core
import { createContext } from "react";

// Definitions
import type { ReactElement, PropsWithChildren } from "react";

// Utils
import { useToggle } from "hooks/useToggle";

type CartCompleteType = {
  availableCart: boolean;
  onToggleAvailableCart: (value: boolean) => void;
};

export const CartContext = createContext<CartCompleteType>({
  availableCart: false,
  onToggleAvailableCart: (value: boolean) => value,
});

type CartCompleteProviderType = PropsWithChildren<{ children: ReactElement }>;

export const CartContextProvider = (props: CartCompleteProviderType) => {
  const { isToggle: availableCart, onToggle: onToggleAvailableCart } = useToggle(false);

  return (
    <CartContext.Provider value={{ availableCart, onToggleAvailableCart }}>
      {props.children}
    </CartContext.Provider>
  );
};

export const CartCompleteContextConsumer = CartContext.Consumer;
