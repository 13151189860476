// Core
import { AnyAbility, defineAbility, PureAbility } from "@casl/ability";

// Definitions
import { ProfilePermissionFeatType } from "bus/profile/models";

export enum PermissionAct {
  CREATE = "create",
  READ = "read",
  UPDATE = "update",
  DELETE = "delete",
}

export class Permission {
  createAbility(permissions?: ProfilePermissionFeatType[]): PureAbility | AnyAbility {
    if (!permissions) {
      return new PureAbility();
    }
    return defineAbility((can, cannot) => {
      permissions.forEach((permission) => {
        const { alias, rules } = permission;
        const list = rules ? Object.entries(rules) : [];
        list.forEach(([rule, status]) => {
          (status ? can : cannot)(rule, alias);
        });
      });
    });
  }
}
